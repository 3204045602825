import { styled } from '@mui/material/styles';
import { Add, FormatListBulleted, GridViewRounded, History, Update } from "@mui/icons-material";
import { Button, Grid, Pagination, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert } from "../../../components/notify";
import ScrollBox from "../../../components/ScrollBox";
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import route from '../../../Routes';
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import { capFirstLetterOnly } from '../../../utils/format';
import SingleCustReqTable from '../../../components/SingleCustReqTable';
import SingleCustReqCard from '../../../components/SingleCustReqCard';
import { getCustReqsByAgent } from '../../../services/agent-service';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function SingleAgCustReqListPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  let { agentId, agName, time, reqStatus } = Object.fromEntries([...searchParams]);
  time = time == 'all' ? null : time;

  const [pageNumber, setPageNumber] = useState(1);
  const [view, setView] = useState('list');

  const [order, setOrder] = useState('desc');
  const navigate = useNavigate();
  const limit = 6

  const { isLoading, isError, error, data: custReqRow } = useQuery(
    [QueryKey.custReqByAg, limit, pageNumber, agentId, order, time, reqStatus],
    () => getCustReqsByAgent({ limit, pageNumber, agentId, order, time, reqStatus }), {
    keepPreviousData: true
  });

  let title = t("bo.cusReqLstPg.reqLstScrn");

  if (time) {
    if (time == "current") {
      title = t("bo.custInfoPg.currReq");
    } else {
      title = t("bo.cusReqLstPg.reqHis");
    }
  } else if (reqStatus) {
    if (reqStatus == "pending") {
      title = t("bo.custInfoPg.penReqList");
    } else if (reqStatus == "rejected") {
      title = t("bo.custInfoPg.rjcReqList");
    }
  }

  function onViewChange(e, v) {
    if (v == null)
      return

    setView(v);
  }

  async function onOrderChange(e, or) {
    if (or == null)
      return

    setOrder(or);
    setPageNumber(1);
  }


  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };


  async function handleAddReq() {
    navigate(route.boAddCustReq + "?custId=" + agentId);
  }

  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

  // if (time !== "current" && time !== "history") {
  //   return <MissingPage />
  // }

  return (
    <Box height={"100%"} width={"100%"}>
      <Stack direction={'row'} justifyContent='space-between' mb={1.5} >


        <Stack direction={'row'} alignItems={"center"} gap={0.5} mr={4}>
          <Typography variant='h5' sx={{ fontWeight: "bold" }}>{title} </Typography>
          <Typography sx={{ fontWeight: "bold", fontSize: 18, color: "rgba(0, 0, 0, 0.5)" }}>({capFirstLetterOnly(agName)})</Typography>
        </Stack>

        <Button variant='outlined' startIcon={time == "current" ? <History /> : <Update />}
          sx={{ fontSize: 15, fontWeight: "w900", mr: 4 }} onClick={() => navigate(route.boAgCustReqs + "?agentId=" + agentId + "&agName=" + agName + "&time=" + (time == "current" ? "history" : "current") + "&reqStatus=approved")}
        >{time == "current" ? t("bo.filterMdl.history") : t("bo.filterMdl.current")}</Button>
      </Stack>

      <BgBox height={"95%"} px={4} py={1}>
        <Stack direction={'row'} justifyContent="space-between" alignItems={"center"} mb={1.2} mt={0.4} px={2}>
          <StyledToggleButtonGroup color="primary" size="small" value={view} exclusive onChange={onViewChange} sx={{ mb: 0.5, height: 36 }}>
            <ToggleButton value='list'>
              <FormatListBulleted /> <Typography sx={{ ml: 0.3, fontSize: 15 }}>{t("bo.cusReqLstPg.list")}</Typography>
            </ToggleButton>
            <ToggleButton value="center">
              <GridViewRounded /> <Typography sx={{ ml: 0.3, fontSize: 15 }}>{t("bo.cusReqLstPg.grid")}</Typography>
            </ToggleButton>
          </StyledToggleButtonGroup>

          <Stack direction={'row'} justifyContent="space-between" alignItems={"center"}>


            <StyledToggleButtonGroup color="primary" size="small" value={order} exclusive onChange={onOrderChange} sx={{ mb: 0.5, height: 36, mx: 3 }}>
              <ToggleButton value='desc'>
                <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDown /></Stack>
              </ToggleButton>
              <ToggleButton value="asc">
                <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDownAlt /></Stack>
              </ToggleButton>
            </StyledToggleButtonGroup>

            {/* <Button variant='contained' sx={{ height: 36, mb: 0.5 }} startIcon={<Add />} color='primary' onClick={handleAddReq}>{t("bo.cusReqLstPg.addReq")}</Button> */}
          </Stack>
        </Stack>

        {view === 'list' ? <SingleCustReqTable custReqRow={custReqRow?.data} time={time} reqStatusType={reqStatus} /> :
          <ScrollBox height={"84%"}>
            <Grid container spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {custReqRow?.data.map((i) => <Grid item xs="auto" key={i.id}> <SingleCustReqCard i={i} reqStatusType={reqStatus} /> </Grid>)}
            </Grid>
          </ScrollBox>
        }

        <Stack sx={{ alignItems: "center", mt: 1 }}>
          <Pagination count={Math.ceil(custReqRow?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
        </Stack>

      </BgBox>
    </Box>
  )
}

export default SingleAgCustReqListPage;
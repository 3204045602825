import { Box, ButtonBase, Modal, Stack, Typography, useMediaQuery } from '@mui/material'
import React from 'react';
import { useTranslation } from "react-i18next";
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import { datePickerformat, formatDateWithJs, getLocalStrg, makeTxtOverFLow } from '../../../utils/format';
import { useNavigate } from 'react-router-dom';
import route from '../../../Routes';
import { useAuthContext } from '../../../context/AuthContext';


function PymtInfo({ modalOpen, handleModalClose, data }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const txtFieldProp = { readOnly: true, fontSize: 14, width: 420, height: 37 }
    const isLarge = useMediaQuery("(min-width: 800px)");
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isLarge ? 1100 : 600,
        bgcolor: 'background.paper',
        border: '2px solid #ffff',
        boxShadow: 24,
        pl: 3, pr: 0,
        py: 3,
        borderRadius: 2,
        height: 600
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {data ?
                <Box sx={modalStyle}>
                    <Stack direction={'row'} alignItems={"center"} mb={2.5}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 17, color: "rgba(0, 0, 0, 0.6)", mr: 1 }}>{t("bo.editCustReqPg.payDet")}</Typography>
                    </Stack>
                    <Stack direction={isLarge ? 'row' : "column"} alignItems={"start"} >
                        <Stack gap={2} alignItems="center" width={isLarge ? "43%" : "100%"}>
                            <ButtonBase component="div" onClick={() => navigate(route.boAgentInfo + data?.agentId)}>
                                <FillLabelTxtField defaultValue={`${data?.agName} - ${data?.agentCode}`} title={t("bo.agent.agent")} {...txtFieldProp} />
                            </ButtonBase>
                            <Stack direction={'row'} gap={2}>
                                <FillLabelTxtField defaultValue={`${data?.agComPer}%`} title={t("bo.agent.commPer")} {...txtFieldProp} width={200} />
                                <FillLabelTxtField defaultValue={getLocalStrg(data?.paidAmt)} title={t("bo.addTrkOpPymt.paidAmt")} {...txtFieldProp} width={200} />
                            </Stack>
                            <FillLabelTxtField defaultValue={datePickerformat(data?.issuedDate)} type={'date'} title={t("bo.addTrkOpPymt.issuDate")}  {...txtFieldProp} />
                            <FillLabelTxtField title={t("bo.agent.remarks")}  {...txtFieldProp} multiline={true} height={130} />
                        </Stack>
                        <Box height={500} width={2} bgcolor={'grey'} />
                        <Box
                            bgcolor="otherColors.white"
                            height={500} width={isLarge ? "58%" : "100%"}
                            sx={{
                                borderRight: 1, borderColor: '#E3E3E3', overflow: "hidden",
                                overflowY: "scroll",
                                '&::-webkit-scrollbar': {
                                    width: 4,
                                }
                            }}
                        >
                            {(data?.shipmts).map(
                                (i) => (
                                    <SimShipmtCard data={i} key={i?.shipmtId} />
                                )
                            )}
                        </Box>
                    </Stack>
                    <Typography style={{ fontWeight: "600", fontSize: 15, position: "absolute", top: isLarge ? 45 : "100%", right: 500 }}  >{t("bo.agent.shipmtList")} </Typography>
                </Box>
                : <Typography component={'h1'}>Something went wrong</Typography>}
        </Modal>
    )
}


export default PymtInfo;




function SimShipmtCard({ data }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { ctxtUser } = useAuthContext();

    return <ButtonBase component="div" sx={{ borderRadius: 1.4, mt: 1.3, mx: 2 }} onClick={() => ctxtUser?.type === 'staff' ? navigate(route.boShipmtInfo + data?.shipmtId) : navigate(route.agShipmtInfo + data?.shipmtId)}>
        <Stack alignItems={"center"} py={1} px={2} sx={{ backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, height: 80, border: 1, borderColor: 'rgba(223, 218, 248, 0.9)', width: 580 }}>
            <Stack direction={"row"}>
                <Typography sx={{ fontSize: 14, fontWeight: "600", color: "primary.main", mr: 6 }}>{data?.shipmtId}</Typography>

                <Stack direction={"row"} >
                    <Typography sx={{ fontSize: 12, }}>{data?.picDate ? t("bo.combReqCard.pick") : "Est Pickup:"}&nbsp; {formatDateWithJs(data?.picDate ? data?.picDate : data?.estPicDate)}</Typography>

                    <Typography sx={{ fontSize: 12, ml: 2 }}>{data?.delDate ? t("bo.combReqCard.del") : "Est Delivery:"}&nbsp; {formatDateWithJs(data?.delDate ? data?.delDate : data?.estDelDate)}</Typography>

                </Stack>
            </Stack>
            <Stack direction={"row"} alignItems={"center"}>
                <Typography sx={{ fontWeight: "600", fontSize: 14 }} >{makeTxtOverFLow(data?.shipmtPicLoc, 31)}</Typography>
                <Typography component={'span'} sx={{ fontWeight: "600", fontSize: 30, mx: 2, mb: 0.5 }} > &#8594;</Typography>
                <Typography component={'span'} sx={{ fontWeight: "600", fontSize: 14 }}> {makeTxtOverFLow(data?.shipmtDelLoc, 31)}</Typography>
            </Stack>
        </Stack>
    </ButtonBase>
}
import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ScrollBox from '../../../components/ScrollBox';
import complShipmtCount from '../../../assets/svg/complShipmtCount.svg';
import CountCard from '../../../components/CountCard';
import currShipmtCount from '../../../assets/svg/currShipmtCount.svg';
import { ArrowForward } from '@mui/icons-material';
import CustReqCard from '../../../components/CustReqCard';
import Slider from "react-slick";
import { useAuthContext } from '../../../context/AuthContext';
import { customAlert } from '../../../components/notify';
import { useNavigate } from 'react-router-dom';
import route from '../../../Routes';
import { capFirstLetter } from '../../../utils/format';
import currCustReqImg from '../../../assets/svg/currCustReq.svg';
import notVerifiedImg from '../../../assets/svg/notVerified.svg';
import totalReqImg from '../../../assets/svg/totalReq.svg';
import verifiedImg from '../../../assets/svg/verified.svg';
import ShipmtCard from '../../../components/ShipmtCard';
import { useTranslation } from "react-i18next";
import AddClick from '../../../components/AddClick';
import SingleCustReqCard from '../../../components/SingleCustReqCard';
import { getSingleAgent } from '../../../services/agent-service';
import { useQuery } from 'react-query';
import QueryKey from '../../../QueryKey';
import LoadingScreen from '../../../components/loadingScreen';


function AgDashboardPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { ctxtUser } = useAuthContext();
  const { isLoading, isError, error, data: agentData } = useQuery([QueryKey.singleAg, ctxtUser.userId], () => getSingleAgent(ctxtUser.userId))

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };


  function verified() {
    if (agentData.agPanVerified === 1)
      return <img src={verifiedImg} alt="verifiedImg" />
    else
      return <Stack direction={'row'} alignItems={"center"}>
        <img src={notVerifiedImg} alt="notVerifiedImg" />
        <Typography sx={{ color: "#EB9A55", fontSize: 14, fontWeight: 500, ml: 0.3 }}>Need Verification</Typography>
      </Stack>
  }

  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

  return (
    <>
      {agentData ? <ScrollBox height={"100%"}>
        <Stack direction={'row'} justifyContent='space-between' mt={1.6} mb={1.3} >
          <Stack direction={'row'} alignItems={"center"}>
            <Typography variant='h5' sx={{ fontWeight: "bold", mr: 1 }}>{capFirstLetter(agentData?.agName)} ({t("bo.agent.agent")})</Typography>
            {verified()}
          </Stack>

        </Stack>

        <Stack direction={'row'} justifyContent={"space-between"} mb={2}>
          <AddClick path={route.agCustReqList + "current"}>
            <CountCard imgPath={currCustReqImg} title={t("bo.custInfoPg.currReqCount")} count={agentData?.currReq?.length || 0} />
          </AddClick>
          <AddClick path={route.agCustReqList + "history"}>
            <CountCard imgPath={totalReqImg} title={t("bo.custInfoPg.reqHisCount")} count={agentData?.totalReqs} />
          </AddClick>
          <AddClick path={route.agShipmtList + "current"}>
            <CountCard imgPath={currShipmtCount} title={t("bo.custInfoPg.currShpCount")} count={agentData?.currShipmt?.length || 0} />
          </AddClick>
          <AddClick path={route.agShipmtList + "history"}>
            <CountCard imgPath={complShipmtCount} title={t("bo.custInfoPg.complShpCount")} count={agentData.completedShipmts} />
          </AddClick>
        </Stack>


        <Stack direction={'row'} justifyContent='space-between' mb={1}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.penReqList")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.agCustReqList + "current")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.penReqRes?.length > 0 ? <Slider {...settings}>
          {agentData?.penReqRes?.map((i) => <SingleCustReqCard key={i.id} i={i} reqStatusType={"pending"} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}


        <Stack direction={'row'} justifyContent='space-between' mb={1} mt={2}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.currReq")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.agCustReqList + "current")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.currReq?.length > 0 ? <Slider {...settings}>
          {agentData?.currReq?.map((i) => <CustReqCard key={i.id} i={i} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}

        <Stack direction={'row'} justifyContent='space-between' mt={2} mb={1}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.currShp")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.agShipmtList + "current")}>{t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.currShipmt?.length > 0 ? <Slider {...settings}>
          {agentData?.currShipmt?.map((i) => <ShipmtCard key={i.id} i={i} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}

        <Stack direction={'row'} justifyContent='space-between' mb={1} mt={2}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.rjcReqList")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.agCustReqList + "history")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.rjcReqRes?.length > 0 ? <Slider {...settings}>
          {agentData?.rjcReqRes?.map((i) => <SingleCustReqCard key={i.id} i={i} reqStatusType={"rejected"} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}

      </ScrollBox> : <Typography component={'h1'}>Something went wrong</Typography>
      }

    </>
  )
}



export default AgDashboardPage
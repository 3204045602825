import React from 'react'
import { TextField, colors, Typography, Stack } from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Height } from '@mui/icons-material';

function CtrlOtpTxtField({ title, errMsg, value, onChange, width, height }) {
    const labelStyle = {
        color: errMsg ? colors.red[500] : "var(--primary-color)",
        fontWeight: "500",
        fontSize: "0.8rem",
    }
    return (
        <Stack>
            <Typography htmlFor={title} style={labelStyle} component={'label'} >{title}</Typography>
            <MuiOtpInput id={title} value={value} onChange={onChange} width={width} height={height} />
        </Stack>

    )
}

export default CtrlOtpTxtField
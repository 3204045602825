import { styled } from '@mui/material/styles';
import { FormatListBulleted, GridViewRounded } from "@mui/icons-material";
import { Grid, Pagination, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert } from "../../../components/notify";
import ScrollBox from "../../../components/ScrollBox";
import { getAllTrkOpByVfyStatus } from "../../../services/req_service";
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useQuery } from 'react-query';
import QueryKey from '../../../QueryKey';
import LoadingScreen from '../../../components/loadingScreen';
import SingleTrkOpReqCard from '../../../components/SingleTrkOpReqCard';
import SingleTrkOpReqTable from '../../../components/SingleTrkOpReqTable';


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function PenRjcTrkOpReqListPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  let { reqStatus } = Object.fromEntries([...searchParams]);

  const [pageNumber, setPageNumber] = useState(1);
  const [view, setView] = useState('list');

  const [order, setOrder] = useState('desc');
  const limit = 6;

  const { isLoading, isError, error, data: trkOpReqRow } = useQuery(
    [QueryKey.penAllTrkOpReqs, limit, pageNumber, order, reqStatus],
    () => getAllTrkOpByVfyStatus({ limit, pageNumber, order, reqStatus }), {
    keepPreviousData: true
  })

  let title = t("bo.custInfoPg.penTrkOpReq");

  if (reqStatus) {
    if (reqStatus == "pending") {
      title = t("bo.custInfoPg.penTrkOpReq");
    } else if (reqStatus == "rejected") {
      title = t("bo.custInfoPg.rjcTrkOpReq");
    }
  }



  function onViewChange(e, v) {
    if (v == null)
      return

    setView(v);
  }

  async function onOrderChange(e, or) {
    if (or == null)
      return

    setOrder(or);
    setPageNumber(1);
  }


  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };

  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

  // if (time !== "current" && time !== "history") {
  //   return <MissingPage />
  // }


  return (
    <Box height={"100%"} width={"100%"}>
      <Stack direction={'row'} justifyContent='space-between' mb={1.5} >
        <Stack direction={'row'} alignItems={"center"} gap={0.5} mr={4}>
          <Typography variant='h5' sx={{ fontWeight: "bold" }}>{title} </Typography>
        </Stack>

      </Stack>

      <BgBox height={"95%"} px={4} py={1}>

        <Stack direction={'row'} justifyContent="space-between" alignItems={"center"} mb={1.2} mt={0.4} px={2}>
          <StyledToggleButtonGroup color="primary" size="small" value={view} exclusive onChange={onViewChange} sx={{ mb: 0.5, height: 36 }}>
            <ToggleButton value='list'>
              <FormatListBulleted /> <Typography sx={{ ml: 0.3, fontSize: 15 }}>{t("bo.tpReqLstPg.list")}</Typography>
            </ToggleButton>
            <ToggleButton value="center">
              <GridViewRounded /> <Typography sx={{ ml: 0.3, fontSize: 15 }}>{t("bo.tpReqLstPg.grid")}</Typography>
            </ToggleButton>
          </StyledToggleButtonGroup>

          <Stack direction={'row'} justifyContent="space-between" alignItems={"center"}>

            <StyledToggleButtonGroup color="primary" size="small" value={order} exclusive onChange={onOrderChange} sx={{ mb: 0.5, height: 36, mx: 3 }}>
              <ToggleButton value='desc'>
                <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDown /></Stack>
              </ToggleButton>
              <ToggleButton value="asc">
                <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDownAlt /></Stack>
              </ToggleButton>
            </StyledToggleButtonGroup>

          </Stack>
        </Stack>


        {view === 'list' ? <SingleTrkOpReqTable trkOpReqRow={trkOpReqRow?.data} time={"current"} reqStatusType={reqStatus} /> :
          <ScrollBox height={"84%"}>
            <Grid container spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {trkOpReqRow?.data.map((i) => <Grid item xs="auto" key={i.id}> <SingleTrkOpReqCard key={i.id} i={i} reqStatusType={reqStatus} /> </Grid>)}
            </Grid>
          </ScrollBox>
        }

        <Stack sx={{ alignItems: "center", mt: 1 }}>
          <Pagination count={Math.ceil(trkOpReqRow?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
        </Stack>

      </BgBox>
    </Box>
  )
}

export default PenRjcTrkOpReqListPage;
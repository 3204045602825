import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import React from 'react'
import { useQuery, useQueryClient } from 'react-query';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { customAlert, notify } from '../../../components/notify';
import LoadingScreen from '../../../components/loadingScreen';
import { makeTxtOverFLow } from '../../../utils/format';
import { addZoneState, getZoneStates } from '../../../services/cust-service';
import { useAuthContext } from '../../../context/AuthContext';


function AddZoneState({ modalOpen, handleModalClose, zoneId, zone }) {
  const { isLoading, isError, error, data } = useQuery([QueryKey.unZoneState], () => getZoneStates({ zoneId: null }),
    {
      enabled: Boolean(zoneId)
    })
  const [pageSize, setPageSize] = useState(8);
  const [selRows, setSelRows] = useState([]);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setLoadingScreen } = useAuthContext();


  const columns = [
    {
      field: 'id',
      headerName: 'Not Assigned States',
      width: 600,
      sortable: true,
      filterable: false,
      renderCell: (params) => <Stack key={params.row?.id} alignItems={"start"} my={0.5} py={1} pl={1}
        sx={{
          backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, height: 40, border: 1,
          borderColor: 'rgba(223, 218, 248, 0.9)', width: 580,
        }}>
        <Typography sx={{ fontSize: 14, ml: 3 }}>
          {params.row?.stateName}
        </Typography>
      </Stack>
    }
  ]

  function handleSelChange(rows) {
    setSelRows((prev) => [...rows])
  }

  async function handleSubmit() {
    if (selRows.length <= 0)
      return;

    setLoadingScreen(true);
    try {
      await addZoneState({ zoneId: zoneId, dataArr: [...selRows] });
      queryClient.invalidateQueries();
      handleModalClose()
      notify("success", "Zone state updated successfully")
    } catch (error) {
      customAlert(error);
    }
    setLoadingScreen(false);
  }

  if (isError) {
    console.log(error)
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

  return (

    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={modalStyle}>
        <Stack direction={"row"} justifyContent={"space-between"} mb={1} alignItems={"center"}>
          <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("sa.rateCalc.addState")} ({makeTxtOverFLow(zone, 25)})</Typography>
          <Stack direction={"row"} gap={2}>
            <Button variant='outlined' color='primary' sx={{ height: 35 }} onClick={handleModalClose}>{t("bo.addShipGrp.cancel")}</Button>
            <Button variant='contained' color='primary' sx={{ height: 35 }} onClick={handleSubmit}>{t("bo.addShipGrp.submit")}</Button>
          </Stack>
        </Stack>
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            rows={data}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={handleSelChange}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[8, 20, 40]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sx={{ fontSize: 14, height: "100%" }}
            getRowHeight={() => 'auto'}
          />

        </Box>
      </Box>

    </Modal>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 730,
  bgcolor: 'background.paper',
  border: '2px solid #ffff',
  boxShadow: 24,
  px: 4,
  py: 3,
  borderRadius: 2,
};

export default AddZoneState
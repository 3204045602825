import { getData, postData, putData } from "./rest-api-helper";

export async function getAllTrkOp() {
    const details = {
        urlPath: "/truckOp"
    }

    const res = await getData(details);
    console.log(res, "---------------getAllTrkOp");
    return res;
}

export async function putTrkOpStatus(truckOpId, status) {
    const details = {
        urlPath: "/truckOp",
        queryParams: { truckopId: truckOpId, status }
    }
    const res = await putData(details);
    console.log(res + "---------------putTrkOpStatus");
    return res;
}

export async function getSingleTrkOp(truckOpId) {
    const details = {
        urlPath: `/truckOp/getSingle/${truckOpId}`
    }

    const res = await getData(details);
    console.log(res, "---------------getSingleTrkOp");
    return res;
}

export async function getSingleTrk(trkId) {
    const details = {
        urlPath: `/truckOp/truck/${trkId}`
    }

    const res = await getData(details);
    console.log(res, "---------------getSingleTrk");
    return res;
}

export async function getAllTrkOpWPgSch({ limit, pageNumber, searchKeyWord }) {
    const details = {
        urlPath: "/truckOp",
        queryParams: {
            limit,
            offset: (pageNumber * limit) - limit,
            ...(searchKeyWord && { searchKeyWord })
        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/truckOp",
        queryParams: {
            limit: 100000000000000,
            offset: 0,
            ...(searchKeyWord && { searchKeyWord })
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getAllTrkOpWPgSch");

    return {
        totalLength: res2?.length,
        data: res ? res : []
    }
}

export async function getAllTrksByTpId(trkOpId) {
    if (!trkOpId)
        return []

    const details = {
        urlPath: `/truckOp/getAllTrucks`,
        queryParams: { trkOpId }
    }

    const res = await getData(details);
    console.log(res, "---------------getAllTrksByTpId");
    return res || [];
}

export async function addtruck(data) {
    const details = {
        urlPath: "/truckOp/truck",
        body: {
            ...data
        }
    }

    const res = await postData(details);
    console.log(res, "---------------addtruck");
    return res;
}

export async function putTruck(trkId, data) {
    const details = {
        urlPath: "/truckOp/truck/" + trkId,
        body: {
            ...data
        }
    }
    const res = await putData(details);
    console.log(res + "---------------putTruck");
    return res;
}

export async function createTrkOp(data) {
    const details = {
        urlPath: "/truckOp/create",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------createTrkOp");
    return res;
}

export async function putTrkOp(trkOpId, data) {
    const details = {
        urlPath: "/truckOp/edit/" + trkOpId,
        body: {
            ...data
        }
    }
    const res = await putData(details);
    console.log(res + "---------------putTrkOp");
    return res;
}

export async function putTrkStatus(trkId, status) {
    const details = {
        urlPath: status === 1 ? "/truckOp/enableTrk" : "/truckOp/disableTrk",
        queryParams: { trkId: trkId }
    }
    const res = await putData(details);
    console.log(res + "---------------putTrkStatus");
    return res;
}

export async function getAllTrkOpRepts({ trkOp, trkRegNo, order, limit, pageNumber }) {

    let queryParamsObj = {
        trkOp, trkRegNo, order
    }

    console.log(queryParamsObj)

    const details = {
        urlPath: "/truckOp/report",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,

        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/truckOp/report",
        queryParams: {
            ...queryParamsObj,
            limit: 100000000000000,
            offset: 0,
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getAllTrkOpRepts");
    return {
        totalLength: res2 ? res2?.length : 0,
        data: res ? res : []
    }
}

export async function getTpReqPymtRept({ trkOp, order, limit, pageNumber }) {

    let queryParamsObj = {
        trkOp, order
    }

    console.log(queryParamsObj)

    const details = {
        urlPath: "/truckOp/reqPymtRept",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,

        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/truckOp/reqPymtRept",
        queryParams: {
            ...queryParamsObj,
            limit: 100000000000000,
            offset: 0,
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getTpReqPymtRept");
    return {
        totalLength: res2 ? res2?.length : 0,
        data: res ? res : []
    }
}

export async function putTrkOpBankDetails(trkOpId, data) {
    const details = {
        urlPath: `/truckOp/bankDetails/${trkOpId}`,
        body: {
            accName: data.accName, accNo: data.accNo, ifsc: data.ifsc
        }

    }

    const res = await putData(details);
    console.log(res + "---------------putTrkOpBankDetails");
    return res;
}

export async function getAllPenTrks({ vfyStatus, order, limit, pageNumber }) {
    let queryParamsObj = {
        vfyStatus,
        order
    }

    console.log(queryParamsObj)

    const details = {
        urlPath: "/truckOp/allPendingTrks",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,

        }
    }
    let res = await getData(details);
    const details2 = {
        urlPath: "/truckOp/allPendingTrks",
        queryParams: {
            ...queryParamsObj
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getAllPenTrks");
    return {
        totalLength: res2 ? res2?.length : 0,
        data: res ? res : []
    }
}

export async function putTrkVfyStatus({ trkId, vfyStatus }) {
    const details = {
        urlPath: `/truckOp/truckVfyStatus/${trkId}`,
        body: {
            vfyStatus
        }

    }
    const res = await putData(details);
    console.log(res + "---------------putTrkVfyStatus");
    return res;
}
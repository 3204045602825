import { Box, Button, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import { getLocalStrg, shipmtStClrs, getTruckName } from '../utils/format';
import StyledTableContainer from './StyledTableContainer';
import { useNavigate } from 'react-router-dom';
import route from '../Routes';


function TpReqPymtReptTable({ tpReqData, columns }) {
    const navigate = useNavigate();
    async function handleReqIdClick(reqRow) {
        navigate(route.boTrkOpReqInfo + reqRow.truckOpReqId);
    }

    return (
        <StyledTableContainer height={"83%"}>
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                            key={column.id}
                        >
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {(tpReqData || []).map((dataRow, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            {columns.map((column) => {
                                const value = dataRow[column.id];
                                return (
                                    (column.id === "finalPricing" || column.id === "pendingAmount") ?
                                        <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                            {"₹ " + getLocalStrg(value)}
                                        </TableCell> : (column.id === "trkOp") ?
                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                <Button variant="text" sx={{ color: "#000000", textAlign: "left" }} onClick={() => { navigate(route.boTrkOpInfo + dataRow.truckopId) }}>
                                                    {value}
                                                </Button>

                                            </TableCell> : (column.id === 'truckOpReqId') ?
                                                <TableCell key={column.id} align={column.align}>
                                                    <Button variant="text" sx={{ color: "#000000", textAlign: "left" }} onClick={() => { handleReqIdClick(dataRow); }}>
                                                        {value}
                                                    </Button>
                                                </TableCell> :
                                                <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }} >
                                                    {value}
                                                </TableCell>);
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </StyledTableContainer>
    )
}

export default TpReqPymtReptTable
import { Button, Rating, Stack, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ScrollBox from '../../../components/ScrollBox';
import { useState } from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import { customAlert, notify } from '../../../components/notify';
import { useEffect } from 'react';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import { useNavigate } from 'react-router-dom';
import route from '../../../Routes';
import ChangePwdPopUp from '../../../components/ChangePwdPopUp';
import { useTranslation } from "react-i18next";
import FileViewLabel from '../../../components/FileViewLabel';
import { getSingleAgent } from '../../../services/agent-service';
import AgFillLabelTxtFieldVfy from '../../../components/AgFillLabelTxtFieldVfy';
import PanVerify from '../../../components/ServiceVfy/PanVerify';


function AgProfilePage() {
  const { setLoadingScreen, ctxtUser, setAsyncLoading, ctxtlogout } = useAuthContext();
  const isLarge = useMediaQuery("(min-width: 600px)");
  const [agentData, setAgentData] = useState(null);
  const navigate = useNavigate();
  const [modalOpen, setmodalOpen] = useState(false);
  const handleModalClose = () => setmodalOpen(false);
  const handleModalOpen = () => setmodalOpen(true);
  const { t } = useTranslation();
  let txtFielProps = { fontSize: 14, height: 38 };
  const [panVfyMdl, setPanVfyMdl] = useState(false);
  const handlePanVfyMdlMdlClose = () => {
    setPanVfyMdl(false);
    window.location.reload();
  };
  const handlePanVfyMdlMdlOpen = () => setPanVfyMdl(true);


  async function getData() {
    setLoadingScreen(true);
    try {
      const agentData = await getSingleAgent(ctxtUser.userId);
      setAgentData(agentData);

    } catch (err) {
      console.log(err);
      customAlert(err);
    }
    setLoadingScreen(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  async function handleEdit() {
    navigate(route.agEdit , { state: { agentData: agentData } });
  }

  function handleLogout() {
    setAsyncLoading(true);
    ctxtlogout();
    setAsyncLoading(false);
    notify("info", "Logout successfully");
  }

  function handleAbout() {
    navigate(route.agAbout);
  }

  return (
    <>
      {agentData ? <ScrollBox height={"100%"}>
        <Stack direction={'row'} justifyContent='space-between' mt={1.6} mb={1.3} >
          <Stack direction={'row'} alignItems={"center"}>
            <Typography variant='h5' sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22, mb: 1, mt: 1 }}>{t("bo.boUserInfoPg.profile")}</Typography>
          </Stack>
          <Stack direction={'row'} mr={5}>
            <Button variant='outlined' color='primary' sx={{ border: 2, height: 35, '&:hover': { border: 2 } }} onClick={handleEdit}> {t("bo.custInfoPg.editInfo")}</Button>
            <Box width={8} />
            <Button variant='contained' color='primary' sx={{ height: 35 }} onClick={handleModalOpen}>{t("bo.custInfoPg.changePw")}</Button>
          </Stack>
        </Stack>


        <Stack direction={isLarge ? 'row' : "column"} alignItems={''} sx={{ mb: 2, height: 1000 }} >
          <Box py={2} pl={5} mr={2} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: isLarge ? "49%" : "52%" }}>
            <Stack gap={3} mt={1}>
              <FillLabelTxtField title={t("bo.agent.agName")} defaultValue={agentData?.agName} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.agCode")} defaultValue={agentData?.agentCode} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.mobNo")} defaultValue={agentData?.agMobile} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.email")} defaultValue={agentData?.agMail} readOnly={true} {...txtFielProps} />


              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <AgFillLabelTxtFieldVfy onVerifyClick={handlePanVfyMdlMdlOpen} status={agentData?.agPanVerified} title={t("bo.agent.panNo")} defaultValue={agentData?.agPanNo} readOnly={true}  {...txtFielProps} width={300} />
                <FileViewLabel label={t("bo.addTrkOpPg.panFile")} fileKey={agentData?.agPanFile} value={agentData?.agPanFile ? "Pan" : "No Pan"} minWidth={240} maxWidth={240} />
              </Stack>
              <Box height={10} />
            </Stack>
          </Box>

          <Box py={2} pl={10} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: "49%" }}>
            <Stack gap={3} mt={1}>
              <FillLabelTxtField title={t("bo.agent.commPer") + " (%)"} defaultValue={agentData?.agComPer} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.loc")} defaultValue={agentData?.agLoc} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.addr")} defaultValue={agentData?.agAddress} readOnly={true} {...txtFielProps} multiline={true} height={103} />
              <Box height={10} />
              <Stack direction={"row"} mt={3}>
                <Button variant="text" sx={{ height: 35, color: "red", mr: 2 }} onClick={handleLogout}>{t("bo.boUserInfoPg.logout")}</Button>
                <Button variant="text" sx={{ height: 35 }} onClick={handleAbout}>{t("bo.boUserInfoPg.about")}</Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>

        <Stack direction={isLarge ? 'row' : "column"} alignItems={''} sx={{ height: agentData?.type === 'company' ? "120%" : "100%", mb: 2 }} >
          {agentData && <Box py={2} pl={5} mb={1.5} mr={1.5} mt={0.5} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: isLarge ? "49%" : "52%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.agent.bankDet")}</Typography>
            </Stack>
            <Stack direction={"row"} mt={2} gap={20} mb={2} >
              <Stack gap={3}>
                {agentData?.agAccName && <FillLabelTxtField name="agAccName" title={t("bo.agent.accName")} defaultValue={agentData?.agAccName} readOnly={true}  {...txtFielProps} />}
                {agentData?.agAccNo && <FillLabelTxtField name="agAccNo" title={t("bo.agent.accNo")} defaultValue={agentData?.agAccNo} readOnly={true}  {...txtFielProps} />}
                {agentData?.agIfsc && <FillLabelTxtField name="agIfsc" title={"IFSC"} defaultValue={agentData?.agIfsc} readOnly={true}  {...txtFielProps} />}
              </Stack>
              <Stack>
              </Stack>
            </Stack>
          </Box>}
          {agentData && <Box py={2} pl={5} mb={1.5} mr={1.5} mt={0.5} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: "49%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.agent.agRatingByBo")}</Typography>
            </Stack>
            <Stack direction={"row"} mt={2} gap={20} mb={2} >
              <Stack gap={3}>
                <Stack sx={{ width: 500 }}>
                  <Typography style={{
                    fontWeight: "600",
                    fontSize: 15
                  }} component={'label'} sx={{ mb: 1 }} >{t("bo.custInfoPg.rating")}</Typography>
                  {agentData && <Rating value={agentData?.boAgRating} readOnly precision={0.1} />}
                </Stack>
                {agentData && <FillLabelTxtField name="boAgFeedback" title={t("bo.custInfoPg.feedback")} defaultValue={agentData?.boAgFeedback} readOnly={true}  {...txtFielProps} multiline={true} height={103} />}
              </Stack>
              <Stack>
              </Stack>
            </Stack>
          </Box>}
        </Stack>

        <ChangePwdPopUp modalOpen={modalOpen} handleModalClose={handleModalClose} userId={agentData.agentId} />

      </ScrollBox> : <Typography component={'h1'}>Something went wrong</Typography>

      }
      {agentData && <PanVerify modalOpen={panVfyMdl} handleModalClose={handlePanVfyMdlMdlClose} userId={ctxtUser.userId} />}
    </>
  )
}



export default AgProfilePage
import { getData, postData, putData, deleteData } from "./rest-api-helper"

export async function trkOpReptExcel({ filterObj, columns }) {
    const details = {
        urlPath: "/excel/trkOpRept",
        body: {
            filterObj: { ...filterObj }, columns: [...columns]
        }
    }
    console.log(details)
    const res = await postData(details);
    return res
}

export async function shipmtReptExcel({ filterObj, columns }) {
    const details = {
        urlPath: "/excel/shipmtRept",
        body: {
            filterObj: { ...filterObj }, columns: [...columns]
        }
    }
    console.log(details)
    const res = await postData(details);
    return res
}

export async function tpReqPymtReptExcel({ filterObj, columns }) {
    const details = {
        urlPath: "/excel/tpReqPymtRept",
        body: {
            filterObj: { ...filterObj }, columns: [...columns]
        }
    }
    console.log(details)
    const res = await postData(details);
    return res
}

export async function adminAgentExcel({ filterObj, columns }) {
    const details = {
        urlPath: "/excel/adminAgentRept",
        body: {
            filterObj: { ...filterObj }, columns: [...columns]
        }
    }
    console.log(details)
    const res = await postData(details);
    return res
}
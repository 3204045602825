import { styled } from '@mui/material/styles';
import { Pagination, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert } from "../../../components/notify";
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import { getAgentPymts } from '../../../services/agent-service';
import { useParams } from 'react-router-dom';
import AgPymtTable from '../AgPymtsPage/AgPymtTable';


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function SingleAgPymtsPage() {
  const { t } = useTranslation();
  const { agentId } = useParams()
  const [pageNumber, setPageNumber] = useState(1);
  const [order, setOrder] = useState('desc');
  const limit = 8
  const { isLoading, isError, error, data: pymtData } = useQuery(
    [QueryKey.agPymts, limit, pageNumber, order, agentId],
    () => getAgentPymts({ order, limit, pageNumber, agentId }), {
    keepPreviousData: true
  });


  async function onOrderChange(e, or) {
    if (or == null)
      return

    setOrder(or);
    setPageNumber(1);
  }

  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };


  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>


  return (
    <Box height={"100%"} width={"100%"}>
      <Stack direction={'row'} justifyContent='space-between' mb={1.5} >
        <Stack direction={'row'} alignItems={"center"} gap={0.5} mr={4}>
          <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("bo.agent.agPayList")} </Typography>
          <StyledToggleButtonGroup color="primary" size="small" value={order} exclusive onChange={onOrderChange} sx={{ mb: 0.5, height: 36, mx: 3 }}>
            <ToggleButton value='desc'>
              <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDown /></Stack>
            </ToggleButton>
            <ToggleButton value="asc">
              <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDownAlt /></Stack>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Stack>

      </Stack>


      <BgBox height={"93%"} px={4} py={1}>
        <AgPymtTable pymtRows={pymtData?.data?.data ?? []} />

        <Stack sx={{ alignItems: "center", mt: 1 }}>
          <Pagination count={Math.ceil(pymtData?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
        </Stack>

      </BgBox>

    </Box>
  )
}

export default SingleAgPymtsPage;
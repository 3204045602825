import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material'
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Delete } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { getZoneStates, rmvZoneState } from '../../../services/cust-service';
import { customAlert, notify } from '../../../components/notify';
import ScrollBox from '../../../components/ScrollBox';
import { makeTxtOverFLow } from '../../../utils/format';
import LoadingScreen from '../../../components/loadingScreen';


function RmvZoneState({ modalOpen, handleModalClose, zoneId, zone }) {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { isLoading, isError, error, data } = useQuery([QueryKey.stateByZone, zoneId], () => getZoneStates({ zoneId }), {
        enabled: Boolean(zoneId)
    })
    const { isLoading: isMuLoad, isError: isMuErr, error: muErr, mutate: rmvItem } = useMutation(rmvZoneState, {
        onSuccess: () => {
            queryClient.invalidateQueries();
            notify("success", "Removed Successfully");
        }
    });



    function handleDelete(stateId) {
        rmvItem({ stateId })
    }

    if (isError || isMuErr) {
        customAlert(error || muErr);
        console.log(error)
        console.log(muErr)
        return <h2>Something went wrong</h2>
    }

    if (isLoading || isMuLoad)
        return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={modalStyle}>
                <Stack direction={"row"} justifyContent={"space-between"} mb={1} alignItems={"center"}>
                    <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("sa.rateCalc.rmvState")} ({makeTxtOverFLow(zone, 25)})</Typography>
                    <Button variant='outlined' color='primary' sx={{ height: 35 }} onClick={handleModalClose}>{t("bo.editGrp.back")}</Button>
                </Stack>
                <ScrollBox height={400}>
                    {data?.map((i) => <Stack key={i?.itemId} direction={"row"} alignItems={"center"}>
                        <Stack alignItems={"start"} mb={1} py={1} pl={1} sx={{ backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, height: 40, border: 1, borderColor: 'rgba(223, 218, 248, 0.9)', width: 580 }}>
                            <Typography sx={{ fontSize: 14, fontWeight: "bold", ml: 3 }} >
                                {i?.stateName}
                            </Typography>
                        </Stack>
                        <IconButton sx={{ color: "red" }} onClick={() => handleDelete(i?.id)}>
                            <Delete />
                        </IconButton>
                    </Stack>

                    )}
                </ScrollBox>
            </Box>

        </Modal>
    )
}

export default RmvZoneState;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 695,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 4,
    py: 3,
    borderRadius: 2,
};


import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import { getLocalStrg, shipmtStClrs, getTruckName } from '../utils/format';
import StyledTableContainer from './StyledTableContainer';


function TrkOpReportsTable({ trkOpData, columns }) {

    return (
        <StyledTableContainer height={"83%"}>
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                            key={column.id}
                        >
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {(trkOpData || []).map((dataRow, index) => {
                    return (
                        <TableRow hover tabIndex={-1} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            {columns.map((column) => {
                                const value = dataRow[column.id];
                                return (
                                    (column.id === "totalAmount" || column.id === "pendingAmount") ?
                                        <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                            {"₹ " + getLocalStrg(value)}
                                        </TableCell> : (column.id === "truckType") ?
                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }}>
                                                {getTruckName(JSON.parse(dataRow?.type || "{}"))}
                                            </TableCell> :
                                            <TableCell key={column.id} align={column.align} sx={{ textAlign: "left" }} >
                                                {value}
                                            </TableCell>);
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </StyledTableContainer>
    )
}

export default TrkOpReportsTable
import { styled } from '@mui/material/styles';
import { Button, ButtonBase, ListItemIcon, MenuItem, Pagination, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert } from "../../../components/notify";
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import { getAgentPymts } from '../../../services/agent-service';
import AgPymtTable from './AgPymtTable';
import { getLast30Days, getLocalStrg } from '../../../utils/format';
import CtrlOutTxtField from '../../../components/CtrlOutTxtField';
import { Search } from '@mui/icons-material';
import SelectAgent from '../../../components/SelectAgent';
import { useAuthContext } from '../../../context/AuthContext';
import { Description, PictureAsPdf, ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { adminAgentPdf } from '../../../services/pdf-service';
import OptionMenu from '../../../components/OptionMenu';
import { adminAgentExcel } from '../../../services/excel-service';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function AgPymtsPage() {
  const { setLoadingScreen } = useAuthContext();
  const { t } = useTranslation();
  const defaultDates = getLast30Days()
  const [filterObj, setFilterObj] = useState({
    agentId: "",
    fromDate: defaultDates.priorDate,
    toDate: defaultDates.today,
    agentName: "",
    agentCode: ""
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [order, setOrder] = useState('desc');
  const limit = 8
  const { isLoading, isError, error, data: pymtData, refetch } = useQuery(
    [QueryKey.allAgPymts, limit, pageNumber, order],
    () => getAgentPymts({ order, limit, pageNumber, ...filterObj }), {
    keepPreviousData: true
  });

  const [selectAgOpen, setSelectAgOpen] = useState(false);
  const handleSelectAgClose = (event, reason) => {
    setSelectAgOpen(false);
  }
  const handleSelectAgOpen = (event, reason) => {
    setSelectAgOpen(true);
  }

  function handleTxtFieldChange(e) {
    const { name, value } = e.target;
    console.log({ name, value })
    setFilterObj(prevSel => { return { ...prevSel, [name]: value } })
  }

  function handleSerarch() {
    refetch();
  }

  function handleSetAgent(agentObj) {
    setFilterObj((e) => {
      return {
        ...e,
        agentId: agentObj.agentId,
        agName: agentObj.agName,
        agentCode: agentObj.agentCode
      }
    });
  }

  async function onOrderChange(e, or) {
    if (or == null)
      return

    setOrder(or);
    setPageNumber(1);
  }


  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };

  function AgCountCard({ title, amount, value }) {
    return <Box sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: 60, border: 1, borderColor: '#E3E3E3', width: 310, px: 3, pt: 0.5 }}>
      {amount && <Typography sx={{ fontWeight: "600", fontSize: 20 }}><span name="Rs">&#8377;</span> {getLocalStrg(amount)}</Typography>}
      {value && <Typography sx={{ fontWeight: "600", fontSize: 20 }}>{value}</Typography>}
      <Typography sx={{ color: "secondary.main", fontSize: 13 }}>{title}</Typography>
    </Box>
  }

  const columns = [
    {
      id: 'agent',
      label: "Agent",
      align: 'left',
      minWidth: 50
    },
    {
      id: 'revenue',
      label: "Revenue",
      align: 'left',
      minWidth: 50
    },
    {
      id: 'agComPer', 
      label: "Commission percentage",
      align: 'left',
      minWidth: 50
    },
    {
      id: 'paidAmt',
      label: "Paid Amount",
      align: 'left',
      minWidth: 50
    },
    {
      id: 'issuedDate',
      label: "Issued Date",
      align: 'left',
      minWidth: 50
    },
    {
      id: 'shipmts',
      label: "Shipments",
      align: 'left',
      minWidth: 30
    },
    {
      id: 'c_at',
      label: "Created at",
      align: 'left',
      minWidth: 50
    },

  ];

  const optionItems = [
    {
      title: t("bo.reptListPg.pdf"),
      icon: <PictureAsPdf fontSize="small" />,
      fn: async () => {
        setLoadingScreen(true);
        try {
          const res = await adminAgentPdf({ filterObj, columns });
          window.open(res.fileUrl, "_blank")
        } catch (err) {
          console.log(err);
          customAlert(err)
        }
        setLoadingScreen(false);
      }
    },
    {
      title: t("bo.reptListPg.excel"),
      icon: <Description fontSize="small" />,
      fn: async () => {
        try {
          const res = await adminAgentExcel({ filterObj, columns });
          window.open(res.fileUrl, "_blank")
        } catch (err) {
          console.log(err);
          customAlert(err)
        }
      }
    }
  ]
  const [anchorExEl, setAnchorExEl] = useState(null);
  const exportOpen = Boolean(anchorExEl);
  const handleExportClose = () => {
    setAnchorExEl(null);
  };

  const handleExportClick = (event) => {
    setAnchorExEl(event.currentTarget);
  };

  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>



  return (
    <Box height={"100%"} width={"100%"}>
      <Stack direction={'row'} justifyContent='space-between' mb={1.5} >
        <Stack direction={'row'} alignItems={"center"} gap={0.5} mr={4}>
          <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("bo.agent.agPayList")} </Typography>
          <StyledToggleButtonGroup color="primary" size="small" value={order} exclusive onChange={onOrderChange} sx={{ mb: 0.5, height: 36, mx: 3 }}>
            <ToggleButton value='desc'>
              <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDown /></Stack>
            </ToggleButton>
            <ToggleButton value="asc">
              <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDownAlt /></Stack>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Stack>
        <Stack direction={'row'} alignItems={"center"} gap={1} >
          <ButtonBase component="div" onClick={handleSelectAgOpen}>
            <CtrlOutTxtField value={filterObj.agentId ? `${filterObj.agName} - ${filterObj.agentCode}` : "None"} title={t("bo.agent.agent")} placeholder={t("bo.agent.srchAg")} fontSize={13} height={35} width={200} />
          </ButtonBase>
          <CtrlOutTxtField value={filterObj.fromDate} name={"fromDate"} onChange={handleTxtFieldChange} title={t("bo.filterMdl.fromDate")} fontSize={13} height={35} width={140} type={"date"} />
          <CtrlOutTxtField value={filterObj.toDate} name={"toDate"} onChange={handleTxtFieldChange} title={t("bo.filterMdl.toDate")} fontSize={13} height={35} width={140} type={"date"} />
          <Button variant='contained' sx={{ height: 35, fontSize: 14 }} startIcon={<Search />} color='primary' onClick={handleSerarch}>{t("bo.cusReqLstPg.search")}</Button>
        </Stack>
      </Stack>
      <Stack direction={'row'} alignItems={"end"} justifyContent={"space-between"} gap={1} mb={1}>
        <Stack direction={'row'} alignItems={"end"} gap={1} mb={1}>
          <AgCountCard title={t("bo.agent.totalRevenue")} amount={(pymtData?.data?.totals?.totalRevenue).toString()} />
          <AgCountCard title={t("bo.agent.totalPaidAmt")} amount={(pymtData?.data?.totals?.totalPaidAmt).toString()} />
        </Stack>
        <Button variant='contained' sx={{ ml: 2, mb: 0.4 }} endIcon={exportOpen ? <ArrowDropUp /> : <ArrowDropDown />} color='primary' onClick={handleExportClick} >{t("bo.reptListPg.export")}</Button>
      </Stack>

      <BgBox height={"82%"} px={4} py={1}>
        <Stack direction={'row'} justifyContent="space-between" alignItems={"center"} mb={1.2} mt={0.4} px={2}>


          <Stack direction={'row'} justifyContent="space-between" alignItems={"center"}>

          </Stack>
        </Stack>
        <AgPymtTable pymtRows={pymtData?.data?.data ?? []} columns={columns} />

        <Stack sx={{ alignItems: "center", mt: 1 }}>
          <Pagination count={Math.ceil(pymtData?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
        </Stack>

      </BgBox>
      <SelectAgent handleClose={handleSelectAgClose} open={selectAgOpen} handleSetAgent={handleSetAgent} />
      <OptionMenu open={exportOpen} handleClose={handleExportClose} anchorEl={anchorExEl} >
        {optionItems.map((i) => <MenuItem sx={{ pl: 2, pr: 5 }} key={i.title} onClick={i.fn}>
          <ListItemIcon > {i.icon}</ListItemIcon>
          {i.title}
        </MenuItem>)}
      </OptionMenu>
    </Box>
  )
}

export default AgPymtsPage;
import React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { customAlert, notify } from '../../../components/notify';
import { useAuthContext } from '../../../context/AuthContext';
import BgBox from '../../../components/BgBox';
import ScrollBox from '../../../components/ScrollBox';
import { Box, Stack } from '@mui/system';
import { Button, Typography } from '@mui/material';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import validate from './validate';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { getSingleAgent, putAgent } from '../../../services/agent-service';
import PlaceAutofill from '../../../components/PlaceAutofill';


function EditAgentPage() {
  const [formErrors, setFormErrors] = useState({});
  const { setLoadingScreen } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { agentId } = useParams();
  const [agentData, setAgentData] = useState(location.state?.agentData || null);
  const { t } = useTranslation();
  let txtFielProps = { fontSize: 14, height: 38, width: 500 };


  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object

    let errorsObj = validate(inputObject);
    setFormErrors(errorsObj);
    console.log(errorsObj);

    if (Object.keys(errorsObj).length > 0)
      return;

    setLoadingScreen(true);

    try {
      await putAgent(agentId, inputObject);
      notify("success", "Agent Edited Successfully");
      navigate(-1, { replace: true });
      setLoadingScreen(false);
    } catch (err) {
      console.log(err);
      customAlert(err);
    }
    setLoadingScreen(false);
  };

  async function handleCancel() {
    navigate(-1, { replace: true });
  }



  async function getData() {
    try {
      const data = await getSingleAgent(agentId);
      console.log("aa-------------------------", data);
      setAgentData(data);
    } catch (err) {
      console.log(err);
      customAlert(err);
    }
  }

  useEffect(() => {
    console.log(agentData, agentId);
    if (!agentData) {
      setLoadingScreen(true);
      getData().then((e) => {
        setLoadingScreen(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BgBox>
      {agentData ? <form onSubmit={handleSubmit} noValidate id='addBo' style={{ padding: 0, height: "100%" }}>

        <ScrollBox>
          <Stack direction={'row'} justifyContent='space-between' my={2} >
            <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("bo.agent.editAgInfo")}</Typography>
          </Stack>

          <Stack direction={"row"} >
            <FillLabelTxtField name="agName" defaultValue={agentData?.agName} title={t("bo.agent.name")} errMsg={formErrors.agName} {...txtFielProps} />
            <Box width={100} />
            <FillLabelTxtField name="agMobile" defaultValue={agentData?.agMobile} title={t("bo.agent.mobNo")} errMsg={formErrors.agMobile} {...txtFielProps} />
          </Stack>

          <Stack direction={"row"} marginTop={2}>
            <FillLabelTxtField name="agMail" defaultValue={agentData?.agMail} title={t("bo.agent.email")} errMsg={formErrors.agMail} {...txtFielProps} />
            <Box width={100} />
            {/* <FillLabelTxtField name="agPanNo" defaultValue={agentData?.agPanNo} title={t("bo.agent.panNo")} errMsg={formErrors.agPanNo} {...txtFielProps} /> */}
          </Stack>

          <Stack direction={"row"} marginTop={2}>
            <FillLabelTxtField name="agAddress" defaultValue={agentData?.agAddress} title={t("bo.agent.addr")} errMsg={formErrors.agAddress} {...txtFielProps} multiline={true} height={103} />
            <Box width={100} />
            <PlaceAutofill name="agLoc" defaultValue={agentData?.agLoc} title={t("bo.agent.loc")} errMsg={formErrors.agLoc} {...txtFielProps} />
          </Stack>
          <Stack direction={"row"} marginTop={2} >
            <FillLabelTxtField name="agComPer" defaultValue={agentData?.agComPer} title={t("bo.agent.commPer") + " (%)"} errMsg={formErrors.agComPer} type="number" step={0.01}  {...txtFielProps} />
          </Stack>

          <Typography sx={{ fontWeight: "bold", fontSize: 18, color: "rgba(0, 0, 0, 0.3)", mt: 6 }} >{t("bo.agent.bankDet")}</Typography>

          <Stack direction={"row"} marginTop={2}>
            <FillLabelTxtField name="agAccName" defaultValue={agentData?.agAccName} title={t("bo.agent.accName")} errMsg={formErrors.agAccName} {...txtFielProps} />
            <Box width={100} />
            <FillLabelTxtField name="agIfsc" defaultValue={agentData?.agIfsc} title={t("IFSC")} errMsg={formErrors.agIfsc} {...txtFielProps} />
          </Stack>
          <Stack direction={"row"} marginTop={2}>
            <FillLabelTxtField name="agAccNo" defaultValue={agentData?.agAccNo} title={t("bo.agent.accNo")} errMsg={formErrors.agAccNo} {...txtFielProps} />
            <Box width={100} />

          </Stack>
        </ScrollBox>

        <Stack justifyContent={"flex-end"} direction={"row"} sx={{ mt: 1.5 }}>
          <Button variant="text" sx={{ height: 40, width: 120, mr: 1 }} onClick={handleCancel}>{t("bo.editCustInfoPg.cancel")}</Button>
          <Button variant="contained" type='submit' sx={{ height: 40, width: 120, mr: 15 }}>{t("bo.editCustInfoPg.sChanges")}</Button>
        </Stack>

      </form> : <Typography component={'h1'}>Something went wrong</Typography>}


    </BgBox>
  )
}

export default EditAgentPage
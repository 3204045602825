import React from 'react'
import logo from "../../assets/svg/logo.svg";
import { Stack, Button, Typography, Box } from "@mui/material"
import { useState } from 'react';
import PwdTxtField from '../../components/PwdTxtField';
import { customAlert, notify } from '../../components/notify';
import {  verifyOtpPwdService } from '../../services/login-service';
import { useAuthContext } from '../../context/AuthContext'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import route from '../../Routes';
import CtrlLabelTxtField from '../../components/CtrlLabelTxtField';
import CtrlOtpTxtField from '../../components/CtrlOtpTxtField';



function OtpPwdChangePage() {
    const [formErrors, setFormErrors] = useState({});
    const { setLoadingScreen } = useAuthContext();
    const { ctxtUser } = useAuthContext();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { email } = Object.fromEntries([...searchParams]);
    const [selection, setSelection] = useState({
        email: "",
        otp: ""
    });

    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);

        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object

        let errorsObj = validate({ values: inputObject, email: selection.email, otp: selection.otp });
        setFormErrors(errorsObj);

        if (Object.keys(errorsObj).length > 0)
            return;

        setLoadingScreen(true);

        try {
            await verifyOtpPwdService({ username: selection.email, otp: selection.otp, newPassword: inputObject.password });

            notify("success", "Password Changed Successfully");

            navigate(route.login, { replace: true });
            setLoadingScreen(false);
        } catch (err) {
            console.log(err);
            if (err === 409)
                notify("error", "Invalid OTP");
            else if (err === 410)
                notify("error", "OTP Expired");
            else
                customAlert(err);

        }
        setLoadingScreen(false);
    };


    useEffect(() => {
        setSelection((e) => {
            return {
                ...e,
                email: email
            }
        });
        setLoadingScreen(true);
        console.log(ctxtUser?.token);
        if (ctxtUser?.token && ctxtUser?.type === 'sa')
            navigate(route.saBoLists, { replace: true });
        else if (ctxtUser?.token && ctxtUser?.type === 'staff')
            navigate(route.boDashboard, { replace: true });
        else if (ctxtUser?.token && ctxtUser?.type === 'agent') {
            navigate(route.agDashboard, { replace: true });
        }

        setLoadingScreen(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handlSelChange(e) {
        const { name, value } = e.target;
        setSelection(prevSel => { return { ...prevSel, [name]: value } })
    }

    function handlOtpChange(newValue) {
        setSelection(prevSel => { return { ...prevSel, otp: newValue } })
    }

    return (
        <form onSubmit={handleSubmit} noValidate style={{ width: "100%", height: "100%", position: "absolute" }}>
            <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>

                <img src={logo} alt="logo" className='logo' />
                <Stack alignItems={"center"} width={360}>
                    <Typography sx={{ mb: 2, fontWeight: "700", fontSize: 26 }}>Forgot Password</Typography>
                    <Typography className='subTxt' sx={{ mb: 2, fontWeight: "500", fontSize: 14 }}>We sent a 4-digit password recovery code to your email. Enter the code to proceed.</Typography>
                    <CtrlLabelTxtField defaultValue={email} value={selection.email} onChange={handlSelChange} disabled={true} type='email' name='email' placeholder="Enter your Registered Email Address" title="Email Address" errMsg={formErrors.email} />
                    <Box height={20} />
                    <CtrlOtpTxtField errMsg={formErrors.otp} width={300} value={selection?.otp} onChange={handlOtpChange} title={"One Time Password (OTP)"} />
                    <Box height={20} />
                    <PwdTxtField name={"password"} title={"Password"} errMsg={formErrors.password} width={290} />
                    <Box height={20} />
                    <PwdTxtField name={"c_password"} title={"Confirm Password"} errMsg={formErrors.c_password} width={290} />
                    <Button variant="contained" type='submit' style={{ marginTop: "1rem", height: "2.5rem" }} sx={{ width: "85%" }}>Reset Password</Button>
                    <Button variant="text" onClick={() => navigate(route.login, { replace: true })} style={{ marginTop: "1rem", height: "2.5rem" }} sx={{ width: "85%" }}>Cancel</Button>
                </Stack>
            </Stack>
        </form>
    )
}

function validate({ values, email, otp }) {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!otp) {
        errors.otp = "OTP is required!";
    }
    if (!email) {
        errors.email = "Email is required!";
    } else if (!regex.test(email)) {
        // errors.email = "This is not a valid email format!";
    }

    if (!values.password)
        errors.password = "Password is required";
    else if (values.password.length < 4)
        errors.password = "Password must be more than 4 characters";
    else if (values.password.length > 10)
        errors.password = "Password cannot exceed more than 10 characters";

    if (!values.c_password)
        errors.c_password = "Password is required";
    else if (values.c_password.length < 4)
        errors.c_password = "Password must be more than 4 characters";
    else if (values.c_password.length > 10)
        errors.c_password = "Password cannot exceed more than 10 characters";
    else if (values.c_password !== values.password)
        errors.c_password = 'Passwords do not match';
    return errors;
};

export default OtpPwdChangePage;
import { Button, Rating, Stack, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ScrollBox from '../../../components/ScrollBox';
import complShipmtCount from '../../../assets/svg/complShipmtCount.svg';
import CountCard from '../../../components/CountCard';
import currShipmtCount from '../../../assets/svg/currShipmtCount.svg';
import { ArrowForward, Edit } from '@mui/icons-material';
import CustReqCard from '../../../components/CustReqCard';
import Slider from "react-slick";
import { useState } from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import { customAlert } from '../../../components/notify';
import { useEffect } from 'react';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import { useNavigate, useParams } from 'react-router-dom';
import route from '../../../Routes';
import ChangePwdPopUp from '../../../components/ChangePwdPopUp';
import { capFirstLetter, formatDate, formatDateTime, formatDateWithJs, getLocalStrg } from '../../../utils/format';
import currCustReqImg from '../../../assets/svg/currCustReq.svg';
import notVerifiedImg from '../../../assets/svg/notVerified.svg';
import totalReqImg from '../../../assets/svg/totalReq.svg';
import verifiedImg from '../../../assets/svg/verified.svg';
import ShipmtCard from '../../../components/ShipmtCard';
import { useTranslation } from "react-i18next";
import FillLabelTxtFieldVfy from '../../../components/FillLabelTxtFieldVfy';
import FileViewLabel from '../../../components/FileViewLabel';
import AddClick from '../../../components/AddClick';
import SingleCustReqCard from '../../../components/SingleCustReqCard';
import BoAgentRating from './BoAgentRating';
import { getSingleAgent } from '../../../services/agent-service';
import BgBox from '../../../components/BgBox';
import payCoinSVG from '../../../assets/svg/payCoin.svg';


function AgentInfoPage() {
  const { setLoadingScreen } = useAuthContext();
  const isLarge = useMediaQuery("(min-width: 600px)");
  const { agentId } = useParams();
  const [agentData, setAgentData] = useState(null);
  const navigate = useNavigate();
  const [modalOpen, setmodalOpen] = useState(false);
  const handleModalClose = () => setmodalOpen(false);
  const handleModalOpen = () => setmodalOpen(true);
  const { t } = useTranslation();
  let txtFielProps = { fontSize: 14, height: 38 };


  const [ratingMdl, setRatingMdl] = useState(false);
  const handleRatingMdlClose = () => {
    setRatingMdl(false);
    window.location.reload();
  };
  const handleRatingMdlMdlOpen = () => setRatingMdl(true);


  async function getData() {
    setLoadingScreen(true);
    try {
      const agentData = await getSingleAgent(agentId);
      setAgentData(agentData);

    } catch (err) {
      console.log(err);
      customAlert(err);
    }
    setLoadingScreen(false);
  }

  useEffect(() => {

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };



  async function handleEdit() {
    navigate(route.boEditAgent + agentData.agentId, { state: { agentData: agentData } });
  }

  function verified() {
    if (agentData.agPanVerified === 1)
      return <img src={verifiedImg} alt="verifiedImg" />
    else
      return <Stack direction={'row'} alignItems={"center"}>
        <img src={notVerifiedImg} alt="notVerifiedImg" />
        <Typography sx={{ color: "#EB9A55", fontSize: 14, fontWeight: 500, ml: 0.3 }}>Need Verification</Typography>
      </Stack>
  }



  return (
    <>
      {agentData ? <ScrollBox height={"100%"}>
        <Stack direction={'row'} justifyContent='space-between' mt={1.6} mb={1.3} >
          <Stack direction={'row'} alignItems={"center"}>
            <Typography variant='h5' sx={{ fontWeight: "bold", mr: 1 }}>{capFirstLetter(agentData?.agName)} ({t("bo.agent.agent")})</Typography>
            {verified()}
          </Stack>
          <Stack direction={'row'} mr={5}>
            <Button variant='outlined' color='primary' sx={{ border: 2, height: 35, '&:hover': { border: 2 } }} onClick={handleEdit}> {t("bo.custInfoPg.editInfo")}</Button>
            <Box width={8} />
            <Button variant='contained' color='primary' sx={{ height: 35 }} onClick={handleModalOpen}>{t("bo.custInfoPg.changePw")}</Button>
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent={"space-between"} mb={2}>
          <AddClick path={route.boAgCustReqs + "?agentId=" + agentData?.agentId + "&agName=" + agentData?.agName + "&time=current" + "&reqStatus=approved"}>
            <CountCard imgPath={currCustReqImg} title={t("bo.custInfoPg.currReqCount")} count={agentData?.currReq?.length || 0} />
          </AddClick>
          <AddClick path={route.boAgCustReqs + "?agentId=" + agentData?.agentId + "&agName=" + agentData?.agName + "&time=history" + "&reqStatus=approved"}>
            <CountCard imgPath={totalReqImg} title={t("bo.custInfoPg.reqHisCount")} count={agentData?.totalReqs} />
          </AddClick>
          <AddClick path={route.boAgCustShipmts + "?agentId=" + agentData?.agentId + "&agName=" + agentData?.agName + "&time=current"}>
            <CountCard imgPath={currShipmtCount} title={t("bo.custInfoPg.currShpCount")} count={agentData?.currShipmt?.length || 0} />
          </AddClick>
          <AddClick path={route.boAgCustShipmts + "?agentId=" + agentData?.agentId + "&agName=" + agentData?.agName + "&time=history"}>
            <CountCard imgPath={complShipmtCount} title={t("bo.custInfoPg.complShpCount")} count={agentData.completedShipmts} />
          </AddClick>
        </Stack>

        <Typography variant='h5' sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22, mb: 1, mt: 1 }}>{t("bo.agent.agInfo")}</Typography>
        <Stack direction={isLarge ? 'row' : "column"} alignItems={''} sx={{ mb: 2, height: 1000 }} >
          <Box py={2} pl={5} mr={2} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: isLarge ? "49%" : "52%" }}>
            <Stack gap={3} mt={1}>
              <FillLabelTxtField title={t("bo.agent.agName")} defaultValue={agentData?.agName} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.agCode")} defaultValue={agentData?.agentCode} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.mobNo")} defaultValue={agentData?.agMobile} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.email")} defaultValue={agentData?.agMail} readOnly={true} {...txtFielProps} />


              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <FillLabelTxtFieldVfy status={agentData?.agPanVerified} title={t("bo.agent.panNo")} defaultValue={agentData?.agPanNo} readOnly={true}  {...txtFielProps} width={300} />
                <FileViewLabel label={t("bo.addTrkOpPg.panFile")} fileKey={agentData?.agPanFile} value={agentData?.agPanFile ? "Pan" : "No Pan"} minWidth={240} maxWidth={240} />
              </Stack>
              <Box height={10} />
            </Stack>
          </Box>

          <Box py={2} pl={10} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: "49%" }}>
            <Stack gap={3} mt={1}>
              <FillLabelTxtField title={t("bo.agent.commPer") + " (%)"} defaultValue={agentData?.agComPer} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.loc")} defaultValue={agentData?.agLoc} readOnly={true} {...txtFielProps} />
              <FillLabelTxtField title={t("bo.agent.addr")} defaultValue={agentData?.agAddress} readOnly={true} {...txtFielProps} multiline={true} height={103} />
              <Box height={10} />
            </Stack>
          </Box>
        </Stack>

        <Stack direction={isLarge ? 'row' : "column"} alignItems={''} sx={{ height: agentData?.type === 'company' ? "120%" : "100%", mb: 2 }} >
          {agentData && <Box py={2} pl={5} mb={1.5} mr={1.5} mt={0.5} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: isLarge ? "49%" : "52%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.agent.bankDet")}</Typography>
            </Stack>
            <Stack direction={"row"} mt={2} gap={20} mb={2} >
              <Stack gap={3}>
                {agentData?.agAccName && <FillLabelTxtField name="agAccName" title={t("bo.agent.accName")} defaultValue={agentData?.agAccName} readOnly={true}  {...txtFielProps} />}
                {agentData?.agAccNo && <FillLabelTxtField name="agAccNo" title={t("bo.agent.accNo")} defaultValue={agentData?.agAccNo} readOnly={true}  {...txtFielProps} />}
                {agentData?.agIfsc && <FillLabelTxtField name="agIfsc" title={"IFSC"} defaultValue={agentData?.agIfsc} readOnly={true}  {...txtFielProps} />}
              </Stack>
              <Stack>
              </Stack>
            </Stack>
          </Box>}
          {agentData && <Box py={2} pl={5} mb={1.5} mr={1.5} mt={0.5} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "100%", border: 1, borderColor: '#E3E3E3', width: "49%" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.agent.agRatingByBo")}</Typography>
              <Button sx={{ mr: 3 }} variant="outlined" startIcon={<Edit />} onClick={handleRatingMdlMdlOpen}>{t("bo.custInfoPg.updateRating")}</Button>
            </Stack>
            <Stack direction={"row"} mt={2} gap={20} mb={2} >
              <Stack gap={3}>
                <Stack sx={{ width: 500 }}>
                  <Typography style={{
                    fontWeight: "600",
                    fontSize: 15
                  }} component={'label'} sx={{ mb: 1 }} >{t("bo.custInfoPg.rating")}</Typography>
                  {agentData && <Rating value={agentData?.boAgRating} readOnly precision={0.1} />}
                </Stack>
                {agentData && <FillLabelTxtField name="boAgFeedback" title={t("bo.custInfoPg.feedback")} defaultValue={agentData?.boAgFeedback} readOnly={true}  {...txtFielProps} multiline={true} height={103} />}
              </Stack>
              <Stack>
              </Stack>
            </Stack>
          </Box>}
        </Stack>

        <Stack direction={'row'} justifyContent='space-between' mb={1}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.agent.agPayList")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.boAgSinglePymt + agentData?.agentId)}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.payments?.length > 0 ? <Slider {...settings}>
          {agentData?.payments?.map((i) => <PayCard key={i.id} data={i} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}


        <Stack direction={'row'} justifyContent='space-between' mb={1} mt={2}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.penReqList")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.boAgCustReqs + "?agentId=" + agentData?.agentId + "&agName=" + agentData?.agName + "&time=all" + "&reqStatus=pending")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.penReqRes?.length > 0 ? <Slider {...settings}>
          {agentData?.penReqRes?.map((i) => <SingleCustReqCard key={i.id} i={i} reqStatusType={"pending"} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}


        <Stack direction={'row'} justifyContent='space-between' mb={1} mt={2}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.currReq")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.boAgCustReqs + "?agentId=" + agentData?.agentId + "&agName=" + agentData?.agName + "&time=current" + "&reqStatus=approved")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.currReq?.length > 0 ? <Slider {...settings}>
          {agentData?.currReq?.map((i) => <CustReqCard key={i.id} i={i} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}

        <Stack direction={'row'} justifyContent='space-between' mt={2} mb={1}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.currShp")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.boAgCustShipmts + "?agentId=" + agentData?.agentId + "&agName=" + agentData?.agName + "&time=current")}>{t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.currShipmt?.length > 0 ? <Slider {...settings}>
          {agentData?.currShipmt?.map((i) => <ShipmtCard key={i.id} i={i} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}

        <Stack direction={'row'} justifyContent='space-between' mb={1} mt={2}>
          <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 22 }}>{t("bo.custInfoPg.rjcReqList")}</Typography>
          <Button color="secondary" endIcon={<ArrowForward />} sx={{ mr: 2 }} onClick={() => navigate(route.boAgCustReqs + "?agentId=" + agentData?.agentId + "&agName=" + agentData?.agName + "&time=all" + "&reqStatus=rejected")}> {t("bo.custInfoPg.viewAll")}</Button>
        </Stack>
        {agentData?.rjcReqRes?.length > 0 ? <Slider {...settings}>
          {agentData?.rjcReqRes?.map((i) => <SingleCustReqCard key={i.id} i={i} reqStatusType={"rejected"} />)}
        </Slider> :
          <Typography sx={{ textAlign: "center" }}>{t("bo.custInfoPg.empList")}</Typography>}


        <ChangePwdPopUp modalOpen={modalOpen} handleModalClose={handleModalClose} userId={agentData.agentId} />

      </ScrollBox> : <Typography component={'h1'}>Something went wrong</Typography>

      }
      {agentData && <BoAgentRating modalOpen={ratingMdl} handleModalClose={handleRatingMdlClose} agentData={agentData} />}
    </>
  )
}



export default AgentInfoPage

function PayCard({ data }) {
  const { t } = useTranslation();

  return <Box mr={2}>
    <BgBox height={90} width={330} px={2.5} py={1}>
      <Stack direction={'row'} alignItems={"flex-start"} >
        <Stack direction={'row'} alignItems={"center"} >
          <img src={payCoinSVG} alt="shipmt" />
          <Box width={20} />

          <Box width={225}>
            <Typography sx={{ fontWeight: "600", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}><span name="Rs">&#8377;</span>{getLocalStrg(data?.paidAmt)}</Typography>
            <Typography sx={{ color: "secondary.main", fontSize: 13, mt: 0.5 }}>{t("bo.addTrkOpPymt.issuDate")}: {formatDate(data?.issuedDate)}</Typography>
            <Typography sx={{ color: "secondary.main", fontSize: 13 }}>{t("bo.shpCard.crtOn")}: {formatDateTime(data?.c_at)}</Typography>
          </Box>

        </Stack>

      </Stack>
    </BgBox>
  </Box>
}
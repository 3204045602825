function getShipmtFormattedData(obj) {
    return {
        shipment: {
            custId: obj.custId,
            truckOpId: obj.truckOpId,
            custReqId: obj.custReqId,
            truckOpReqId: obj.truckOpReqId,
            trkOpDataFilledBy: obj.trkOpDataFilledBy,
            custDataFilledBy: obj.custDataFilledBy,
        },
        shipmtPicDel: {
            cnorName: obj.cnorName,
            cneeName: obj.cneeName,
            cnorGST: obj.cnorGST,
            cneeGST: obj.cneeGST,
            picAddress: obj.picAddress,
            delAddress: obj.delAddress,
            picLocation: obj.picLocation,
            delLocation: obj.delLocation,
            estPicDate: obj.estPicDate,
            estDelDate: obj.estDelDate,
            estPicSession: obj.estPicSession,
            estDelSession: obj.estDelSession,
            picDate: obj.picDate,
            delDate: obj.delDate,
            picSession: obj.picSession,
            delSession: obj.delSession,
            cnorMobile: obj.cnorMobile,
            cnorEmail: obj.cnorEmail,
            cneeMobile: obj.cneeMobile,
            cneeEmail: obj.cneeEmail
        },
        shipmtLoad: {
            matType: obj.matType,
            matLength: obj.matLength,
            matWidth: obj.matWidth,
            matHeight: obj.matHeight,
            matDimsUnit: obj.matDimsUnit,
            matNature: obj.matNature,
            loadDesc: obj.loadDesc,
            quantity: obj.quantity,
            weight: obj.weight,
            weightUnit: obj.weightUnit,
            loadType: obj.loadType,
            matCategory: obj.matCategory,
            matTypeFreeTxt: obj.matTypeFreeTxt
        },
        shipmtInch: {
            picIncName: obj.picIncName,
            picInchMobile1: obj.picInchMobile1,
            picInchMobile2: obj.picInchMobile2,
            delInchName: obj.delInchName,
            delInchMobile1: obj.delInchMobile1,
            delInchMobile2: obj.delInchMobile2,
        },
        shipmtTruck: {
            truckId: obj.truckId,
            truckRegNo: obj.truckRegNo,
            trkPermitType: obj.trkPermitType,
            trkIns_expDate: obj.trkIns_expDate,
            trkFc_expDate: obj.trkFc_expDate,
            trkMaxCap: obj.trkMaxCap,
            trkMaxCapUnit: obj.trkMaxCapUnit,
            truck_type: obj.truck_type,
        },
        shipmtDriver: {
            drivName: obj.drivName,
            drivMobile1: obj.drivMobile1,
            drivMobile2: obj.drivMobile2,
            drivLicNo: obj.drivLicNo,
            trackingGPSLink: obj.trackingGPSLink,
        },
        shipmtDocs: {
            billCopy: obj.billCopy,
            delChallan: obj.delChallan,
            ewayA_no: obj.ewayA_no,
            ewayA: obj.ewayA,
            ewayB_no: obj.ewayB_no,
            ewayB: obj.ewayB,
        },
        shipmtPricing: {
            cust_addPrc1: obj.cust_addPrc1,
            cust_addPrc2: obj.cust_addPrc2,
            custPayType: obj.custPayType,
            cust_finalPrc: obj.cust_finalPrc
        },
        // shipmtCustPymts: {
        //     bankName: obj.bankName,
        //     acNo: obj.acNo,
        //     branch: obj.branch,
        //     ifsc: obj.ifsc,
        //     transactionId: obj.transactionId,
        //     paidAmt: obj.paidAmt,
        //     balanceAmt: obj.balanceAmt,
        // },
        // shipmtTrkOpPytms: {
        //     type: obj.type,
        //     issuedBy: obj.issuedBy,
        //     issuedDate: obj.issuedDate,
        //     paidAmt: obj.paidAmt,
        //     balanceAmt: obj.balanceAmt,
        //     bankName: obj.bankName,
        //     acNo: obj.acNo,
        //     branch: obj.branch,
        //     ifsc: obj.ifsc,
        //     transactionId: obj.transactionId,
        //     chqNo: obj.chqNo,
        //     chqDate: obj.chqDate,
        //     chqName: obj.chqName,
        // }
    }
}

module.exports.getShipmtFormattedData = getShipmtFormattedData;
const QueryKey = {
    custList: 'customers',
    trkOpList: "trkOps",
    trkTypes: "trkTypes",
    singleCust: "singleCust",
    matTypes: "matTypes",
    singleTrkOpReq: "singleTrkOpReq",
    singleCustReq: "singleCustReq",
    singleTrkOp: "singleTrkOp",
    custReqList: "custReqList",
    trkOpReqList: "trkOpReqList",
    boUser: "boUser",
    singleBo: "singleBo",
    shipmtList: 'shipmtList',
    reqGrp: 'reqGrp',
    grpList: 'grpList',
    notCombGrpReq: "notCombGrpReq",
    shipmtGrp: 'shipmtGrp',
    notCombGrpShipmt: "notCombGrpShipmt",
    custReqByTprId: "custReqByTprId",
    trkOpReqByCusReqId: "trkOpReqByCusReqId",
    allTrks: "allTrks",
    places: "places",
    singleTrk: "singleTrk",
    singleShipmt: "singleShipmt",
    reportConfigList: "reportConfigList",
    shipmtsRepts: "shipmtsRepts",
    logPage: "logPage",
    singleAdrVfy: "singleAdrVfy",
    getAllPenAdrReqs: "getAllPenAdrReqs",
    trkOpRepts: "trkOpRepts",
    boList: "boList",
    staffList: "staffList",
    custShipmts: "custShipmts",
    trkopShipmts: "trkopShipmts",
    custReqs: "custReqs",
    trkopReqs: "trkopReqs",
    tpReqPymtRepts: "tpReqPymtRepts",
    matTypesByCat: "matTypesByCat",
    penAllCustReqs: "penAllCustReqs",
    rjcAllCustReqs: "rjcAllCustReqs",
    penAllTrkOpReqs: "penAllTrkOpReqs",
    rjcAllTrkOpReqs: "rjcAllTrkOpReqs",
    allPenTrks: "allPenTrks",
    agList: 'agentsList',
    custReqByAg: "custReqByAg",
    custShByAg: "custShByAg",
    singleAg: "singleAg",
    agCustList: 'agCustomers',
    agCustReqList: "agCustReqList",
    agShipmtList: 'agShipmtList',
    agUnsettleShipmts: 'agUnsettleShipmts',
    allAgPymts: 'allAgPymts',
    agAllUnsettleShipmts: 'agAllUnsettleShipmts',
    agPymts: 'agPymts',
    rateDetails: 'rateDetails',
    stateByZone: 'stateByZone',
    unZoneState: "unZoneState"
}

export default QueryKey;
import { Box, Button, IconButton, ListItemIcon, MenuItem, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'
import StyledSwitch from '../../../components/StyledSwitch';
import { MoreVert } from '@mui/icons-material';
import OptionMenu from '../../../components/OptionMenu';
import { useTranslation } from "react-i18next";
import StyledTableContainer from '../../../components/StyledTableContainer';


function UserTable({ staffRows, updateStaffStatus, handleOptionClick, handleRowClick, handleBoRowClick, open, handleClose, anchorEl, optionItems }) {
    const { t } = useTranslation();
    const columns = [
        {
            id: 'username',
            label: t("sa.userLstPg.username"),
            minWidth: 170
        },
        {
            id: 'bo_name',
            label: t("sa.userLstPg.boName"),
            minWidth: 170
        },
        {
            id: 'mobile1',
            label: t("sa.userLstPg.contact"),
            minWidth: 170,
            // format: (value) => value.toLocaleString('en-IN'),
        },
        {
            id: 'role',
            label: t("sa.userLstPg.role"),
            minWidth: 170
        },
        {
            id: 'status',
            label: t("sa.userLstPg.sts"),
            minWidth: 80
        },
        {
            id: 'options',
            label: '',
            // align: 'right',
            minWidth: 80
        },
    ];


    return (
        <StyledTableContainer>
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                            key={column.id}
                        >
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {staffRows.map((stRow) => {
                    return (
                        <TableRow hover tabIndex={-1} key={stRow.staffId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            {columns.map((column) => {
                                const value = stRow[column.id];
                                return (
                                    (column.id === 'status') ?
                                        <TableCell key={column.id} align={column.align}>
                                            <StyledSwitch checked={stRow.status ? true : false} onClick={() => { updateStaffStatus(stRow) }} />
                                        </TableCell> : (column.id === 'options') ?
                                            <TableCell key={column.id} align={column.align}>
                                                <IconButton onClick={(e) => { handleOptionClick(e, stRow.staffId) }}>
                                                    <MoreVert />
                                                </IconButton>
                                            </TableCell> : (column.id === 'username') ?
                                                <TableCell key={column.id} align={column.align} >
                                                    <Button variant="text" sx={{ color: "#000000" }} onClick={() => { handleRowClick(stRow.staffId); }}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </Button>
                                                </TableCell> : (column.id === 'bo_name') ?
                                                    <TableCell key={column.id} align={column.align} >
                                                        <Button variant="text" sx={{ color: "#000000" }} onClick={() => { handleBoRowClick(stRow.backofficeId); }}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </Button>
                                                    </TableCell> :
                                                    <TableCell key={column.id} align={column.align} >
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>

            <OptionMenu open={open} handleClose={handleClose} anchorEl={anchorEl} >
                {optionItems.map((i) => <MenuItem
                    sx={{ pl: 2, pr: 5 }}
                    key={i.title}
                    onClick={i.fn}>
                    <ListItemIcon > {i.icon}</ListItemIcon>
                    {i.title}
                </MenuItem>)}
            </OptionMenu>
        </StyledTableContainer>
    )
}

export default UserTable
import React from 'react'
import { useState } from "react";
import { customAlert, notify } from './notify';
import { getFileUrl } from '../services/s3-service';
import { Box, ButtonBase, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import fileIconSVG from "../assets/svg/fileIcon.svg"

function FileViewLabel({ label, minWidth, maxWidth, bgcolor, icon, fontSize, fileKey, value, onEdit }) {

    const [loading, setLoading] = useState(false)

    async function handleClick() {
        if (!fileKey) {
            notify("info", "No File Found")
            return
        }
        setLoading(true);
        try {
            const fileUrl = await getFileUrl({ fileKey });
            window.open(fileUrl, "_blank")
        } catch (err) {
            console.log(err);
            customAlert(err);
        }
        setLoading(false);
    }

    return (
        <Stack minWidth={minWidth} maxWidth={maxWidth} bgcolor={bgcolor}>
            <Stack direction={"row"} gap={0.3} alignItems={"center"} mb={0.4}>
                <Typography sx={{ fontSize: 14, fontWeight: "550" }}>{label}</Typography>
                {onEdit && <IconButton onClick={onEdit}><Edit sx={{ fontSize: 18, color: "primary.main" }} /></IconButton>}
            </Stack>
            <ButtonBase component="div" onClick={handleClick} sx={{ backgroundColor: "rgba(249, 248, 254, 1)", height: 35, width: 200, borderRadius: 1, justifyContent: "start" }}>
                <Stack direction={"row"} alignItems={"center"} >
                    <Box component={"img"} src={icon || fileIconSVG} alt={icon} mx={1.3} sx={{ height: 18 }} />
                    <Typography sx={{ fontSize: fontSize || 14.5, fontWeight: "600", color: "rgba(5, 49, 227, 1)", mr: 2 }}>{value ? value : label}</Typography>
                    {loading && <CircularProgress size={20} />}
                </Stack>
            </ButtonBase>
        </Stack>
    )
}

export default FileViewLabel
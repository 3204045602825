export default function validate(values) {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.agName)
        errors.agName = "Agent Name is required!";

    if (!values.agMobile)
        errors.agMobile = "Mobile Number is required!";

    if (!values.agMail)
        errors.agMail = "Email is required!";
    else if (!regex.test(values.agMail)) {
        // errors.email = "This is not a valid email format!";
    }

    if (!values.agLoc)
        errors.agLoc = "Location is required!";

    if (!values.agComPer)
        errors.agComPer = "Commission percentage is required!";

    // if (!values.password)
    //     errors.password = "Password is required";
    // else if (values.password.length < 4)
    //     errors.password = "Password must be more than 4 characters";
    // else if (values.password.length > 10)
    //     errors.password = "Password cannot exceed more than 10 characters";

    // if (!values.c_password)
    //     errors.c_password = "Password is required";
    // else if (values.c_password.length < 4)
    //     errors.c_password = "Password must be more than 4 characters";
    // else if (values.c_password.length > 10)
    //     errors.c_password = "Password cannot exceed more than 10 characters";
    // else if (values.c_password !== values.password)
    //     errors.c_password = 'Passwords do not match';

    return errors;
};
import { Add, MoreVert } from '@mui/icons-material';
import { Box, Button, IconButton, MenuItem, Pagination, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BgBox from '../../../components/BgBox';
import { customAlert } from '../../../components/notify';
import OptionMenu from '../../../components/OptionMenu';
import SearchTxtField from '../../../components/SearchTxtField';
import StyledSwitch from '../../../components/StyledSwitch';
import StyledTableContainer from '../../../components/StyledTableContainer';
import { useAuthContext } from '../../../context/AuthContext';
import route from '../../../Routes';
import { capFirstLetter } from '../../../utils/format';
import { useTranslation } from "react-i18next";
import { useQuery } from 'react-query'
import LoadingScreen from '../../../components/loadingScreen';
import QueryKey from '../../../QueryKey';
import { getAllAgWPgSch, putAgentStatus } from '../../../services/agent-service';


function AgentListPage() {
    const { setAsyncLoading } = useAuthContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    // const isLarge = useMediaQuery("(min-width: 600px)");
    const [currMenuAgId, setCurrMenuAgId] = useState(null);
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [searchWord, setSearchWord] = useState("");
    const { t } = useTranslation();
    const [pageNumber, setPageNumber] = useState(1);
    const limit = 10;
    const { isLoading, isError, error, data: agRows, refetch } = useQuery(
        [QueryKey.agList, pageNumber, searchKeyWord],
        () => getAllAgWPgSch({ pageNumber, limit, searchKeyWord }),
        {
            keepPreviousData: true
        }
    )


    if (isError) {
        customAlert(error);
        return <h2>Something went wrong</h2>
    }

    if (isLoading)
        return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>


    const columns = [
        {
            id: 'agName',
            label: t("bo.agent.agName"),
            minWidth: 170
        },
        {
            id: 'agentCode',
            label: t("bo.agent.agCode"),
            minWidth: 170
        },
        {
            id: 'agMobile',
            label: t("bo.agent.contact"),
            minWidth: 170
        },
        {
            id: 'agLoc',
            label: t("bo.agent.loc"),
            minWidth: 170
        },
        {
            id: 'verified',
            label: t("bo.agent.verified"),
            minWidth: 170
        },
        {
            id: 'status',
            label: t("bo.agent.sts"),
            minWidth: 80
        },
        {
            id: 'options',
            label: '',
            // align: 'right',
            minWidth: 80
        },
    ];




    const optionItems = [
        {
            title: t("bo.cusLstPg.edit"),
            fn: handleEdit
        }
    ]


    const handleOptionClick = (event, agentId) => {
        setAnchorEl(event.currentTarget);
        setCurrMenuAgId(agentId);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleEdit() {
        handleClose();
        navigate(route.boEditAgent + currMenuAgId);
    }


    async function handleRowClick(agRow) {
        handleClose();
        navigate(route.boAgentInfo + agRow.agentId);
    }



    async function updateAgentStatus(agRow) {
        setAsyncLoading(true);
        try {
            let status = agRow.status === 1 ? 0 : 1;
            await putAgentStatus(agRow.agentId, status)
            refetch();
        } catch (err) {
            console.log(err);
            customAlert(err)
        }
        setAsyncLoading(false)
    }

    function handleAddAgentClick() {
        navigate(route.boAddAg);
    }

    function onSearchBtnClick() {
        setSearchKeyWord(searchWord);
        setPageNumber(1);
    }


    function verified(agRow) {
        if (agRow.aadhar_verified === 1 || agRow.pan_verified === 1 || agRow.gst_verified === 1)
            return <Typography sx={{ color: "green" }}>Yes</Typography>

        return <Typography sx={{ color: "red" }}>No</Typography>
    }




    return (
        <BgBox>
            <Stack direction={'row'} justifyContent='space-between' m={2} >
                <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("bo.agent.ag")}</Typography>
                <Stack direction={'row'} justifyContent="end">

                    <SearchTxtField searchKeyWord={searchWord} onSearch={(e) => setSearchWord(e.target.value)} onBtnClick={onSearchBtnClick} placeholder={t("bo.agent.srchAg")} />

                    <Button variant='contained' sx={{ ml: 5 }} startIcon={<Add />} color='primary' onClick={handleAddAgentClick}>{t("bo.agent.createAg")}</Button>
                </Stack>
            </Stack>
            <StyledTableContainer height={"83%"}>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                                key={column.id}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {agRows?.data.map((agRow) => {
                        return (
                            <TableRow hover tabIndex={-1} key={agRow.agentId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                {columns.map((column) => {
                                    const value = agRow[column.id];
                                    return (
                                        (column.id === 'status') ?
                                            <TableCell key={column.id} align={column.align}>
                                                <StyledSwitch checked={agRow.status ? true : false} onClick={() => { updateAgentStatus(agRow) }} />
                                            </TableCell> : (column.id === 'options') ?
                                                <TableCell key={column.id} align={column.align}>
                                                    <IconButton onClick={(e) => { handleOptionClick(e, agRow.agentId) }}>
                                                        <MoreVert />
                                                    </IconButton>
                                                </TableCell> : (column.id === 'agName') ?
                                                    <TableCell key={column.id} align={column.align} >
                                                        <Button variant="text" sx={{ color: "#000000" }} onClick={() => { handleRowClick(agRow); }}>
                                                            {capFirstLetter(agRow.agName)}
                                                        </Button>
                                                    </TableCell> : (column.id === 'verified') ?
                                                        <TableCell key={column.id} align={column.align} >
                                                            {verified(agRow)}
                                                        </TableCell> :
                                                        <TableCell key={column.id} align={column.align} >
                                                            {column.format && typeof value === 'number' ? column.format(value) : capFirstLetter(value)}
                                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>

                <OptionMenu open={open} handleClose={handleClose} anchorEl={anchorEl} >
                    {optionItems.map((i) => <MenuItem
                        sx={{ pl: 2, pr: 5 }}
                        key={i.title}
                        onClick={i.fn}>
                        {i.title}
                    </MenuItem>)}
                </OptionMenu>
            </StyledTableContainer>
            <Stack sx={{ alignItems: "center", mt: 1 }}>
                <Pagination count={Math.ceil(agRows?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={(e, v) => setPageNumber(v)} variant="outlined" shape="rounded" />
            </Stack>
        </BgBox>
    )
}

export default AgentListPage
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from "react-i18next";
import { customAlert, notify } from '../../../components/notify';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import { updateBaseRate } from '../../../services/cust-service';
import { useAuthContext } from '../../../context/AuthContext';


function EditBaseRate({ modalOpen, handleModalClose, br }) {

    const [formErrors, setFormErrors] = useState({});
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    let txtFielProps = { fontSize: 14, height: 38, width: 400 };
    const { setLoadingScreen } = useAuthContext();


    async function handleSubmit(e) {
        setLoadingScreen(true);
        try {
            e.preventDefault();
            const formData = new FormData(e.target);
            const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object
            let errorsObj = validate(inputObject);
            setFormErrors(errorsObj);

            if (Object.keys(errorsObj).length > 0)
                return;

            await updateBaseRate({ brId: br.id, rate: inputObject.rate });
            queryClient.invalidateQueries();
            handleModalClose()
            notify("success", "Base rate updated successfully")
        } catch (error) {
            if (error === 409) {
                notify("error", "Base rate exceeds acceptable range.")
            } else {
                customAlert(error);
            }
        }
        setLoadingScreen(false);
    }

  
    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit} >
                <Box sx={modalStyle}>
                    <Typography variant='h5' sx={{ fontWeight: "bold", mb: 2 }}>{t("sa.rateCalc.updateBr")}</Typography>
                    <Stack gap={2} alignItems="center">
                        <FillLabelTxtField name="rate" title={t("sa.rateCalc.zone")} disabled={true} defaultValue={br.fromZone + " -> " + br.toZone} {...txtFielProps} />
                        <FillLabelTxtField name="rate" title={t("sa.rateCalc.range")} disabled={true} defaultValue={br.minRate + " -> " + br.maxRate}  {...txtFielProps} />
                        <FillLabelTxtField type="number" step={0.01} name="rate" title={t("sa.rateCalc.br")} defaultValue={br.rate} errMsg={formErrors.rate}  {...txtFielProps} />

                        <Stack justifyContent={"flex-end"} direction={"row"} sx={{ mt: 2 }} width={400}>
                            <Button variant="text" sx={{ height: 40, width: 120 }} onClick={handleModalClose}>{t("sa.rateCalc.cancel")}</Button>
                            <Button variant="contained" type='submit' sx={{ height: 40, width: 150 }}>{t("sa.rateCalc.save")}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </form>
        </Modal>
    )
}

export default EditBaseRate;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 4,
    py: 3,
    borderRadius: 2,
};

function validate(values) {
    const errors = {};

    // if (!values.grpName)
    //     errors.grpName = "Group Name is required!";

    console.log();
    return errors;
};
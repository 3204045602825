import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { customAlert, notify } from '../../../components/notify';
import { useAuthContext } from '../../../context/AuthContext';
import route from '../../../Routes';
import BgBox from '../../../components/BgBox';
import ScrollBox from '../../../components/ScrollBox';
import { Box, Stack } from '@mui/system';
import { Button, Typography } from '@mui/material';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import PwdFillLabelTxtField from '../../../components/PwdFillLabelTxtField';
import validate from './validate';
import { useTranslation } from "react-i18next"; //rithika
import PlaceAutofill from '../../../components/PlaceAutofill';
import { postAgent } from '../../../services/agent-service';


function AddAgentPage() {
  const [formErrors, setFormErrors] = useState({});
  const { setLoadingScreen } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let txtFielProps = { fontSize: 14, height: 38, width: 500 };


  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object
    let errorsObj = validate(inputObject);
    setFormErrors(errorsObj);

    if (Object.keys(errorsObj).length > 0)
      return;

    setLoadingScreen(true);

    try {
      await postAgent(inputObject);
      notify("success", "Agent Created Successfully");
      navigate(route.saBoLists, { replace: true });
      setLoadingScreen(false);
    } catch (err) {
      console.log(err);
      customAlert(err)
    }
    setLoadingScreen(false);
  };

  async function handleCancel() {
    navigate(-1, { replace: true });
  }



  return (
    <BgBox>
      <form onSubmit={handleSubmit} noValidate id='addBo' style={{ padding: 0, height: "100%" }}>

        <ScrollBox>
          <Stack direction={'row'} justifyContent='space-between' my={2} >
            <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("bo.agent.createAg")}</Typography>
          </Stack>

          <Stack direction={"row"} >
            <FillLabelTxtField name="agName" title={t("bo.agent.name")} errMsg={formErrors.agName} {...txtFielProps} />
            <Box width={100} />
            <FillLabelTxtField name="agMobile" title={t("bo.agent.mobNo")} errMsg={formErrors.agMobile} {...txtFielProps} />
          </Stack>

          <Stack direction={"row"} marginTop={2}>
            <FillLabelTxtField name="agMail" title={t("bo.agent.email")} errMsg={formErrors.agMail} {...txtFielProps} />
            <Box width={100} />
            <FillLabelTxtField name="agPanNo" title={t("bo.agent.panNo")} errMsg={formErrors.agPanNo} {...txtFielProps} />
          </Stack>

          <Stack direction={"row"} marginTop={2}>
            <FillLabelTxtField name="agAddress" title={t("bo.agent.addr")} errMsg={formErrors.agAddress} {...txtFielProps} multiline={true} height={103} />
            <Box width={100} />
            <PlaceAutofill name="agLoc" title={t("bo.agent.loc")} errMsg={formErrors.agLoc} {...txtFielProps} />
          </Stack>
          <Stack direction={"row"} marginTop={2} >
            <FillLabelTxtField name="agComPer" title={t("bo.agent.commPer") + " (%)"} errMsg={formErrors.agComPer} type="number" step={0.01}  {...txtFielProps} />
          </Stack>

          <Stack direction={"row"} marginTop={2}>
            <PwdFillLabelTxtField name="password" title={t("bo.addCusPg.pw")} errMsg={formErrors.password} {...txtFielProps} />
            <Box width={100} />
            <PwdFillLabelTxtField name="c_password" title={t("bo.addCusPg.cpw")} errMsg={formErrors.c_password} {...txtFielProps} />
          </Stack>

          <Typography sx={{ fontWeight: "bold", fontSize: 18, color: "rgba(0, 0, 0, 0.3)", mt: 6 }} >{t("bo.agent.bankDet")}</Typography>

          <Stack direction={"row"} marginTop={2}>
            <FillLabelTxtField name="agAccName" title={t("bo.agent.accName")} errMsg={formErrors.agAccName} {...txtFielProps} />
            <Box width={100} />
            <FillLabelTxtField name="agIfsc" title={t("IFSC")} errMsg={formErrors.agIfsc} {...txtFielProps} />
          </Stack>
          <Stack direction={"row"} marginTop={2}>
            <FillLabelTxtField name="agAccNo" title={t("bo.agent.accNo")} errMsg={formErrors.agAccNo} {...txtFielProps} />
            <Box width={100} />

          </Stack>
        </ScrollBox>


        <Stack justifyContent={"flex-end"} direction={"row"} sx={{ mt: 1.5 }}>
          <Button variant="text" sx={{ height: 40, width: 120, mr: 1 }} onClick={handleCancel}>{t("bo.addCusPg.cancel")}</Button>
          <Button variant="contained" type='submit' sx={{ height: 40, width: 120, mr: 15 }}>{t("bo.addCusPg.create")}</Button>
        </Stack>
      </form>

    </BgBox>
  )
}

export default AddAgentPage
import { Button, colors, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

function AgFillLabelTxtFieldVfy({ name, title, placeholder, type, errMsg, defaultValue, readOnly, disabled, multiline, height, rows, width, step, fontSize, startAdornment, status, onVerifyClick }) {
    const labelStyle = {
        color: errMsg && colors.red[500],
        fontWeight: "600",
        fontSize: fontSize || 16
    }
 
    return (
        <Stack sx={{ width: width ? width : 500 }}>
            <Stack direction={"row"} alignItems={"center"}>
                <Typography htmlFor={title} style={labelStyle} component={'label'} sx={{ mr: 0.5 }}>{title}</Typography>
                {status === 1 ? <Button variant="text"  color='success' sx={{ height: 30,  fontWeight: "bold" }}>Verified</Button> :
                    status === 0 ? <Button variant="text" onClick={onVerifyClick} color='primary' sx={{ height: 30, fontWeight: "bold" }}>Verify Now</Button> :
                        <Button variant="text" color='warning' sx={{ height: 30, fontWeight: "bold" }}>Pending</Button>}
            </Stack>

            <TextField variant="filled"
                inputProps={{
                    step: step,
                }}
                InputProps={{
                    disableUnderline: true, style: { paddingBottom: 15 },
                    readOnly: readOnly,
                    disabled: disabled,
                    multiline: multiline,
                    rows: rows ? rows : 4,
                    startAdornment: startAdornment
                }}
                sx={{
                    "& .MuiInputBase-root": {
                        height: height ? height : 45,
                        backgroundColor: "#F5F6F8",
                        fontSize: fontSize || 16
                    },
                    mt: 0.7
                }}
                fullWidth size="small" placeholder={placeholder ? placeholder : title} name={name} type={type} defaultValue={defaultValue}
                id={title}
                error={errMsg && true}
                helperText={errMsg && errMsg}
            />
        </Stack>
    )
}

export default AgFillLabelTxtFieldVfy
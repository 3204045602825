import { Box, Button, colors, FormHelperText, Modal, Rating, Stack, Typography } from '@mui/material'
import React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQueryClient } from 'react-query';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import { customAlert, notify } from '../../../components/notify';
import { useAuthContext } from '../../../context/AuthContext';
import { postAgPymt } from '../../../services/agent-service';
import { useNavigate } from 'react-router-dom';
import route from '../../../Routes';

function Settle({ modalOpen, handleModalClose, payAmt, revenue, agComPer, agentId, shipmtIds }) {
    const [formErrors, setFormErrors] = useState({});
    const { setLoadingScreen } = useAuthContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object
        let errorsObj = validate(inputObject);
        setFormErrors(errorsObj);

        if (Object.keys(errorsObj).length > 0)
            return;

        setLoadingScreen(true);
        try {
            console.log(inputObject);
            const body = {
                agentId,
                paidAmt: payAmt,
                issuedDate: inputObject.issuedDate,
                remark: inputObject.remark,
                revenue,
                agComPer,
                shipmtIds
            }
            console.log(body)
            await postAgPymt({ ...body });
            notify("success", "Payment Added Successfully");
            handleModalClose();
            queryClient.invalidateQueries();
            window.location.reload();
        } catch (err) {
            console.log(err);
            customAlert(err);
        }
        setLoadingScreen(false)
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {agComPer ? <form onSubmit={handleSubmit} >
                <Box sx={modalStyle}>
                    <Stack direction={'row'} alignItems={"center"} mb={2.5}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 17, color: "rgba(0, 0, 0, 0.6)", mr: 1 }}>{t("bo.agent.settleAgPymt")}</Typography>
                    </Stack>
                    <Stack gap={2} alignItems="center">
                        <FillLabelTxtField readOnly={true} disabled={true} defaultValue={payAmt} fontSize={15} name="paidAmt" title={t("bo.agent.payableAmt")}  width={350} />
                        <FillLabelTxtField errMsg={formErrors.issuedDate} type={"date"} fontSize={15} name="issuedDate" title={t("bo.addTrkOpPymt.issuDate")}  width={350} />

                        <FillLabelTxtField fontSize={15} name="remark" title={t("bo.agent.remarks")} placeholder={"Add your remarks here..."} multiline={true} height={103} width={350} />

                        <Stack justifyContent={"flex-end"} direction={"row"} sx={{ mt: 2 }} width={400}>
                            <Button variant="text" sx={{ height: 40, width: 120 }} onClick={handleModalClose}>{t("bo.feedback.cancel")}</Button>
                            <Button variant="contained" type='submit' sx={{ height: 40, width: 150 }}>{t("bo.feedback.save")}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </form> : <Typography component={'h1'}>Something went wrong</Typography>}
        </Modal>
    )
}

export default Settle;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 4,
    py: 3,
    borderRadius: 2,
};

function validate(values) {
    const errors = {};

    if (!values.issuedDate)
        errors.issuedDate = "Issued Date is required!";

    return errors;
};
import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material'
import React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { customAlert } from '../../../components/notify';
import LoadingScreen from '../../../components/loadingScreen';
import { makeTxtOverFLow } from '../../../utils/format';
import ScrollBox from '../../../components/ScrollBox';
import { getZoneStates } from '../../../services/cust-service';
import AddZoneState from './AddZoneState';
import RmvZoneState from './RmvZoneState';


function ViewStateList({ modalOpen, handleModalClose, zoneId, zone }) {
    const navigate = useNavigate()
    const { isLoading, isError, error, data } = useQuery([QueryKey.stateByZone, zoneId], () => getZoneStates({ zoneId }),{
        enabled: Boolean(zoneId)
    })
    const { t } = useTranslation();
    const [modalRmvLstpOpen, setmodalRmvLstOpen] = useState(false);
    const handleModalRmvLstClose = () => setmodalRmvLstOpen(false);
    const handleModalRmvLstOpen = () => setmodalRmvLstOpen(true);

    const [modalAddLstpOpen, setmodalAddLstOpen] = useState(false);
    const handleModalAddLstClose = () => setmodalAddLstOpen(false);
    const handleModalAddLstOpen = () => setmodalAddLstOpen(true);



    if (isError) {
        customAlert(error);
        console.log(error)
        return <h2>Something went wrong</h2>
    }

    if (isLoading)
        return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={modalStyle}>
                <Stack direction={"row"} justifyContent={"space-between"} mb={1} alignItems={"center"}>
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <Typography variant='h5' sx={{ fontWeight: "bold" }}>{makeTxtOverFLow(zone, 25)}</Typography>
                        <IconButton onClick={handleModalRmvLstOpen}><Delete /></IconButton>
                    </Stack>
                    <Stack direction={"row"} gap={2}>
                        <Button variant='outlined' color='primary' sx={{ height: 35 }} onClick={handleModalClose}>{t("bo.viewGrp.back")}</Button>
                        <Button variant='contained' color='primary' sx={{ height: 35 }} onClick={handleModalAddLstOpen}> Add State</Button>
                    </Stack>

                </Stack>
                <ScrollBox height={500}>
                    {data?.map((i) => <Box key={i?.id}>
                        <Stack alignItems={"start"} mb={1} py={1} pl={1} sx={{ backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, height: 40, border: 1, borderColor: 'rgba(223, 218, 248, 0.9)', width: 580 }}>
                            <Typography sx={{ fontSize: 14 , fontWeight:"bold", ml:3}} >
                                {i?.stateName}
                            </Typography>
                        </Stack>
                    </Box>)}
                </ScrollBox>
             <AddZoneState modalOpen={modalAddLstpOpen} handleModalClose={handleModalAddLstClose} zone={zone} zoneId={zoneId} />
             <RmvZoneState modalOpen={modalRmvLstpOpen} handleModalClose={handleModalRmvLstClose} zone={zone} zoneId={zoneId} />

            </Box>

        </Modal>
    )
}

export default ViewStateList;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 680,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 4,
    py: 3,
    borderRadius: 2,
};


import { styled } from '@mui/material/styles';
import { FormatListBulleted, GridViewRounded } from "@mui/icons-material";
import { ButtonBase, Grid, Pagination, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert } from "../../../components/notify";
import ScrollBox from "../../../components/ScrollBox";
import SearchTxtField from "../../../components/SearchTxtField";
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';
import CustomDropDown from '../../../components/CustomDropDown';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import MissingPage from '../../MissingPage';
import ShipmtCard from '../../../components/ShipmtCard';
import ShipmtTable from '../../../components/ShipmtTable';
import route from '../../../Routes';
import { useAuthContext } from '../../../context/AuthContext';
import { agGetAllShipmts } from '../../../services/agent-service';
import AgShipmtTable from '../../../components/AgShipmtTable';
import AgShipmtCard from '../../../components/AgShipmtCard';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function AgShipmtListPage() {
  const { t } = useTranslation();
  const { time } = useParams();
  const navigate = useNavigate();

  const ddArr = [
    { value: "shipmtId", label: t("bo.shipListPg.shpId") },
    { value: "status", label: t("bo.shipListPg.sts") },
    { value: "custName", label: t("bo.shipListPg.custName") },
    { value: "trkOpName", label: t("bo.shipListPg.opName") },
    { value: "custMobile", label: t("bo.shipListPg.custNum") },
    { value: "trkOpMobile", label: t("bo.shipListPg.opNum") },
    { value: "from", label: t("bo.shipListPg.from") },
    { value: "to", label: t("bo.shipListPg.to") },
    { value: "fromto", label: t("bo.shipListPg.fromTo") },
    { value: "loadType", label: t("bo.shipListPg.loadType") },
    { value: "matType", label: t("bo.shipListPg.mat") },
    { value: "trkNo", label: t("bo.shipListPg.trkNo") }
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [view, setView] = useState('list');
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [searchType, setSearchType] = useState(ddArr[0].value);
  const [searchKeyType, setSearchKeyType] = useState(ddArr[0].value);
  const [order, setOrder] = useState('desc');
  const limit = 6
  const { ctxtUser } = useAuthContext();

  const { isLoading, isError, error, data: shipmtRow } = useQuery(
    [QueryKey.agShipmtList, pageNumber, searchKeyWord, searchKeyType, order, time, ctxtUser.userId],
    () => agGetAllShipmts({ pageNumber, limit, searchKeyWord, searchType: searchKeyType, order, time, agentId: ctxtUser.userId }), {
    keepPreviousData: true
  });

  function onViewChange(e, v) {
    if (v == null)
      return

    setView(v);
  }

  async function onOrderChange(e, or) {
    if (or == null)
      return

    setOrder(or);
    setPageNumber(1);
  }


  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };

  async function onSearchBtnClick(e) {
    setSearchKeyWord(searchWord);
    setSearchKeyType(searchType);
    setPageNumber(1);
  }

  function onSearch(e) {
    setSearchWord(e.target.value)
  }

  function handleDDChange(e) {
    const { value } = e.target;
    setSearchType(value);
  }

  function handleHisClick() {
    navigate(route.agShipmtList + "history")
  }
  function handleCurrClick() {
    navigate(route.agShipmtList + "current")
  }

  function getSrchTypeTxt() {
    let filteredObj = ddArr.filter((i) => i.value === searchType);
    return filteredObj[0]?.label || "";
  }


  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

  if (time !== "current" && time !== "history") {
    return <MissingPage />
  }

  return (
    <Box height={"100%"} width={"100%"}>
      <Stack direction={'row'} justifyContent='space-between' mb={1.5} >
        <Typography variant='h5' sx={{ fontWeight: "bold", mr: 4 }}>{time === "current" ? t("bo.shipListPg.ship") : t("bo.shipListPg.shipHis")}</Typography>
        <Stack mr={5}>
          <Stack direction={'row'} mx={1} gap={4}>
            <ButtonBase component="div" onClick={handleCurrClick}>  <Typography sx={{ fontWeight: "600", color: time === 'current' && "primary.main" }}>{t("bo.filterMdl.current")}</Typography></ButtonBase>
            <ButtonBase component="div" onClick={handleHisClick}> <Typography sx={{ fontWeight: "600", color: time === 'history' && "primary.main" }}>{t("bo.filterMdl.history")}</Typography></ButtonBase>
          </Stack>
          <Box sx={{ width: 180, backgroundColor: "#E3E3E3", borderRadius: 10, height: 5, mt: 0.5 }}>
            <Stack direction={'row'} height={4}>
              <Box sx={{ width: 80, backgroundColor: time === 'current' && "primary.main", borderRadius: 10 }} />
              <Box sx={{ width: 100, borderRadius: 10, backgroundColor: time === 'history' && "primary.main" }} />
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <BgBox height={"94%"} px={4} py={1}>
        <Stack direction={'row'} justifyContent="space-between" alignItems={"center"} mb={0.5} mt={0.5} px={2}>
          <Stack direction={'row'} alignItems={"center"}>
            <StyledToggleButtonGroup color="primary" size="small" value={view} exclusive onChange={onViewChange} sx={{ mb: 0.5, height: 36 }}>
              <ToggleButton value='list'>
                <FormatListBulleted /> <Typography sx={{ ml: 0.3, fontSize: 15 }}>{t("bo.shipListPg.list")}</Typography>
              </ToggleButton>
              <ToggleButton value="grid">
                <GridViewRounded /> <Typography sx={{ ml: 0.3, fontSize: 15 }}>{t("bo.shipListPg.grid")}</Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>

          <Stack direction={'row'} justifyContent="space-between" alignItems={"center"}>
            <Box mb={0.5}><SearchTxtField variant={"outlined"} searchKeyWord={searchWord} onSearch={onSearch} onBtnClick={onSearchBtnClick} placeholder={`Search ${getSrchTypeTxt()} .....`} /></Box>
            <CustomDropDown height={36} width={140} handleDDChange={handleDDChange} ddArr={ddArr} defaultValue={ddArr[0].value} />

            <StyledToggleButtonGroup color="primary" size="small" value={order} exclusive onChange={onOrderChange} sx={{ mb: 0.5, height: 36, mx: 3 }}>
              <ToggleButton value='desc'>
                <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDown /></Stack>
              </ToggleButton>
              <ToggleButton value="asc">
                <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDownAlt /></Stack>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Stack>
        </Stack>

        {view === 'list' ? <AgShipmtTable shipmtRow={shipmtRow?.data} /> :
          <ScrollBox height={"84%"}>
            <Grid container spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {shipmtRow?.data.map((i) => <Grid item xs="auto" key={i.id}> <AgShipmtCard i={i} /> </Grid>)}
            </Grid>
          </ScrollBox>
        }

        <Stack sx={{ alignItems: "center", mt: 1 }}>
          <Pagination count={Math.ceil(shipmtRow?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
        </Stack>

      </BgBox>
    </Box>
  )
}

export default AgShipmtListPage;
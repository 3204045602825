import { postData, putData } from "./rest-api-helper"

export async function loginService(data) {
    const details = {
        urlPath: "/login/staff",
        body: {
            username: data.email,
            password: data.password
        }
    }

    const res = await postData(details);
    console.log(res, "---------------loginService");
    return res;
}

export async function ChangePwdService(data) {
    const details = {
        urlPath: "/password/changeWithOldPwd",
        body: {
            userId: data.userId,
            password: data.password,
            oldPwd: data.old_password
        }
    }

    const res = await putData(details);
    console.log(res, "---------------ChangePwdService");
    return res;
}

export async function forgotPwdService({ username }) {
    const details = {
        urlPath: "/password/forgetpassword",
        body: {
            username
        }
    }
    const res = await postData(details);
    console.log(res, "---------------forgotPwdService");
    return res;
}

export async function verifyOtpPwdService({ username, otp, newPassword }) {
    const details = {
        urlPath: "/password/changepassword",
        body: {
            username, otp, newPassword
        }
    }
    const res = await postData(details);
    console.log(res, "---------------verifyOtpPwdService");
    return res;
}

export async function agentLoginService(data) {
    const details = {
        urlPath: "/login/agent",
        body: {
            username: data.agentcode,
            password: data.password
        }
    }
    console.log("1111111111111111111-------------------")
    console.log(data)
    const res = await postData(details);
    console.log(res, "---------------agentLoginService");
    return res;
}
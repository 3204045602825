import React from 'react'
import { useState } from "react";
import { customAlert } from './notify';
import { Box, ButtonBase, CircularProgress, Stack, Typography } from '@mui/material';
import fileIconSVG from "../assets/svg/fileIcon.svg"
import { getConsignorInv, getDeliveryRecpt, getShipmtPdf, getTrkFreightRecpt } from '../services/inv-service';

function InvViewLabel({ label, minWidth, maxWidth, bgcolor, icon, fontSize, value, invType, shipmtId }) {

    const [loading, setLoading] = useState(false)

    async function handleClick() {
        setLoading(true);
        try {
            if (invType === "consignorInv") {
                const fileUrl = await getConsignorInv({ shipmtId });
                window.open(fileUrl, "_blank")
            }
            else if (invType === "delRecpt") {
                const fileUrl = await getDeliveryRecpt({ shipmtId });
                window.open(fileUrl, "_blank")
            } else if (invType === "shipmtPdf") {
                const fileUrl = await getShipmtPdf({ shipmtId });
                window.open(fileUrl, "_blank")
            }
            else if (invType === "trkfright") {
                const fileUrl = await getTrkFreightRecpt({ trkOpReqId: shipmtId });
                window.open(fileUrl, "_blank")
            }
        } catch (err) {
            console.log(err);
            customAlert(err);
        }
        setLoading(false);
    }

    return (
        <Stack minWidth={minWidth} maxWidth={maxWidth} bgcolor={bgcolor}>
            <Stack direction={"row"} gap={0.3} alignItems={"center"} mb={0.4}>
                <Typography sx={{ fontSize: 14, fontWeight: "550" }}>{label}</Typography>
            </Stack>
            <ButtonBase component="div" onClick={handleClick} sx={{ backgroundColor: "rgba(249, 248, 254, 1)", height: 35, width: 200, borderRadius: 1, justifyContent: "start" }}>
                <Stack direction={"row"} alignItems={"center"} >
                    <Box component={"img"} src={icon || fileIconSVG} alt={icon} mx={1.3} sx={{ height: 18 }} />
                    <Typography sx={{ fontSize: fontSize || 14.5, fontWeight: "600", color: "rgba(5, 49, 227, 1)", mr: 2 }}>{value ? value : label}</Typography>
                    {loading && <CircularProgress size={20} />}
                </Stack>
            </ButtonBase>
        </Stack>
    )
}

export default InvViewLabel
import { getData } from "./rest-api-helper"

export async function getConsignorInv({ shipmtId }) {
    const details = {
        urlPath: `/pdf/consignorInv/${shipmtId}`
    }

    const res = await getData(details);
    console.log(res, "---------------getConsignorInv");
    return res.fileUrl;
}

export async function getDeliveryRecpt({ shipmtId }) {
    const details = {
        urlPath: `/pdf/deliveryRecpt/${shipmtId}`
    }

    const res = await getData(details);
    console.log(res, "---------------getDeliveryRecpt");
    return res.fileUrl;
}

export async function getShipmtPdf({ shipmtId }) {
    const details = {
        urlPath: `/pdf/shipmtPdf/${shipmtId}`
    }

    const res = await getData(details);
    console.log(res, "---------------getShipmtPdf");
    return res.fileUrl;
}

export async function getTrkFreightRecpt({ trkOpReqId }) {
    const details = {
        urlPath: `/pdf/trkFreight/${trkOpReqId}`
    }

    const res = await getData(details);
    console.log(res, "---------------getTrkFreightRecpt");
    return res.fileUrl;
}
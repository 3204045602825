import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQueryClient } from 'react-query';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import { customAlert, notify } from '../../../components/notify';
import ScrollBox from '../../../components/ScrollBox';
import { useAuthContext } from '../../../context/AuthContext';
import { putShipmtCustData } from '../../../services/agent-service';
import Fileupload from '../../../components/Fileupload';

function UpdateDetails({ modalOpen, handleModalClose, shipmtData }) {
    const [formErrors, setFormErrors] = useState({});
    const { setLoadingScreen } = useAuthContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    let txtFieldProps = { fontSize: 14, height: 38, width: 520 };
    const boxStyle = { bgcolor: "rgba(0, 0, 0, 0.05)", borderRadius: 10, fontWeight: "600" }
    let txtFileProps = { fontSize: 14, height: 38, width: 500 };
    const [selection, setSelection] = useState({});

    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object    
        inputObject.billCopy = selection?.billCopy ?? shipmtData?.shipmtDocs?.billCopy;
        inputObject.ewayA = selection?.ewayA ?? shipmtData?.shipmtDocs?.ewayA;
        inputObject.ewayB = selection?.ewayB ?? shipmtData?.shipmtDocs?.ewayB;
        console.log(inputObject);
        let errorsObj = validate(inputObject);
        setFormErrors(errorsObj);

        if (Object.keys(errorsObj).length > 0)
            return;

        setLoadingScreen(true);
        try {
            await putShipmtCustData({ shipmtId: shipmtData?.shipment?.shipmtId, body: inputObject });
            notify("success", "Details Updated Successfully");
            handleModalClose();
            queryClient.invalidateQueries();
        } catch (err) {
            console.log(err);
            customAlert(err);
        }
        setLoadingScreen(false)
    }

    function handleOnUpload({ fieldName, fileKey }) {
        setSelection(prevSel => { return { ...prevSel, [fieldName]: fileKey } })
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit} >
                <Box sx={modalStyle}>
                    <Stack direction={'row'} alignItems={"center"} mb={3}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 17, color: "rgba(0, 0, 0, 0.6)", mr: 1 }}>{t("bo.agent.updateShipmtDet")}</Typography>
                    </Stack>
                    <ScrollBox height={"83%"}>
                        <Stack gap={2} >
                            <Stack direction={'row'} alignItems={"center"} gap={3}>
                                <FillLabelTxtField defaultValue={shipmtData?.shipmtInch?.picIncName} name="picIncName" title={t("bo.agent.PicInchName")} errMsg={formErrors.picIncName} {...txtFieldProps} width={250} />
                            </Stack>
                            <Stack direction={"row"} gap={3}>
                                <FillLabelTxtField defaultValue={shipmtData?.shipmtInch?.picInchMobile1} name="picInchMobile1" title={t("bo.agent.PicInchMob1")} errMsg={formErrors.picInchMobile1} {...txtFieldProps} width={250} />
                                <FillLabelTxtField defaultValue={shipmtData?.shipmtInch?.picInchMobile2} name="picInchMobile2" title={t("bo.agent.PicInchMob2")} errMsg={formErrors.picInchMobile2} {...txtFieldProps} width={250} />
                            </Stack>
                            <Box sx={boxStyle} height={1.5} mt={1} width={"100%"} />
                            <Stack direction={'row'} alignItems={"center"} gap={3}>
                                <FillLabelTxtField defaultValue={shipmtData?.shipmtInch?.delInchName} name="delInchName" title={t("bo.agent.DelInchName")} errMsg={formErrors.delInchName} {...txtFieldProps} width={250} />
                            </Stack>
                            <Stack direction={"row"} gap={3}>
                                <FillLabelTxtField defaultValue={shipmtData?.shipmtInch?.delInchMobile1} name="delInchMobile1" title={t("bo.agent.DelInchMob1")} errMsg={formErrors.delInchMobile1} {...txtFieldProps} width={250} />
                                <FillLabelTxtField defaultValue={shipmtData?.shipmtInch?.delInchMobile2} name="delInchMobile2" title={t("bo.agent.DelInchMob2")} errMsg={formErrors.delInchMobile2} {...txtFieldProps} width={250} />
                            </Stack>
                            <Box sx={boxStyle} height={1.5} mt={1} width={"100%"} />
                            <Fileupload name="billCopy" title={t("bo.addShipmtPg.billCopy")} txtFieldProps={txtFileProps} errMsg={formErrors.billCopy} onUpload={handleOnUpload} />
                            <Box sx={boxStyle} height={1.5} mt={1} width={"100%"} />
                            <FillLabelTxtField defaultValue={shipmtData?.shipmtDocs?.ewayA_no}  name="ewayA_no" title={t("bo.addShipmtPg.ewayBillNum")} errMsg={formErrors.ewayA_no} {...txtFileProps} />
                            <Fileupload name="ewayA" title={t("bo.addShipmtPg.ewayBill")} txtFieldProps={txtFileProps} errMsg={formErrors.ewayA} onUpload={handleOnUpload} />
                            <Box sx={boxStyle} height={1.5} mt={1} width={"100%"} />
                            <FillLabelTxtField defaultValue={shipmtData?.shipmtDocs?.ewayB_no}  name="ewayB_no" title={t("bo.agent.ewayBillBNum")} errMsg={formErrors.ewayB_no} {...txtFileProps} />
                            <Fileupload name="ewayB" title={t("bo.agent.ewayBillB")} txtFieldProps={txtFileProps} errMsg={formErrors.ewayB} onUpload={handleOnUpload} />
                        </Stack>
                    </ScrollBox>
                    <Stack justifyContent={"flex-end"} direction={"row"} sx={{ mt: 2 }} width={500}>
                        <Button variant="text" sx={{ height: 40, width: 120 }} onClick={handleModalClose}>{t("bo.picDet.cancel")}</Button>
                        <Button variant="contained" type='submit' sx={{ height: 40, width: 150 }}>{t("bo.picDet.save")}</Button>
                    </Stack>
                </Box>
            </form>
        </Modal>
    )
}

export default UpdateDetails;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 590,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 3,
    py: 2,
    borderRadius: 2,
    height: "90%"
};

function validate(values) {
    const errors = {};
    // {
    //     "picIncName": "pic incharge",
    //     "picInchMobile1": "9999",
    //     "picInchMobile2": "8888",
    //   .del": "del incharge",
    //     "delInchMobile1": "7777",
    //     "delInchMobile2": "66666",
    //     "billCopy": "billCopy",
    //     "delChallan": "delChallan",
    //     "ewayA_no": "ewayA_no",
    //     "ewayA": "ewayA",
    //     "ewayB_no": "bbbbbbbbbbbbb",
    //     "ewayB": "b00001"
    // }

    if (!values.picIncName)
        errors.picIncName = "Incharge Name is required!";

    if (!values.picInchMobile1)
        errors.picInchMobile1 = "Mobile 1 is required!";

    if (!values.picInchMobile2)
        errors.picInchMobile2 = "Mobile 2 is required!";

    if (!values.delInchName)
        errors.delInchName = "Incharge Name is required!";

    if (!values.delInchMobile1)
        errors.delInchMobile1 = "Mobile 1 is required!";

    if (!values.delInchMobile2)
        errors.delInchMobile2 = "Mobile 2 is required!";

    if (!values.billCopy)
        errors.billCopy = "Bill Copy is required!";

    if (!values.ewayA_no)
        errors.ewayA_no = "Eway A No is required!";

    if (!values.ewayA)
        errors.ewayA = "Eway A is required!";

    if (!values.ewayB_no)
        errors.ewayB_no = "Eway B No is required!";

    if (!values.ewayB)
        errors.ewayB = "Eway B is required!";


    return errors;
};
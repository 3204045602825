import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from 'react-query';
import CustomDropDown from '../../../components/CustomDropDown';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import LoadingScreen from '../../../components/loadingScreen';
import { customAlert, notify } from '../../../components/notify';
import PlaceAutofill from '../../../components/PlaceAutofill';
import ScrollBox from '../../../components/ScrollBox';
import { useAuthContext } from '../../../context/AuthContext';
import QueryKey from '../../../QueryKey';

import { datePickerformat } from '../../../utils/format';

function FilterMdl({ modalOpen, handleModalClose, filterObj, handleFilterObj }) {
    const { setLoadingScreen } = useAuthContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    let txtFieldProps = { fontSize: 14, height: 38, width: 520 };

    const orderArr = [
        { value: "asc", label: t("bo.filterMdl.asc") },
        { value: "desc", label: t("bo.filterMdl.desc") }];



    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object


        setLoadingScreen(true);
        try {

            handleFilterObj(inputObject)
            handleModalClose();
            queryClient.invalidateQueries();
        } catch (err) {
            console.log(err);
            customAlert(err);
        }
        setLoadingScreen(false)
    }

    const clearFormFields = () => {
        handleFilterObj({
            trkOp: '',
            order: ''
        });
        handleModalClose();
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit} >
                <Box sx={modalStyle}>
                    <Stack direction={'row'} alignItems={"center"} mb={3}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 17, color: "rgba(0, 0, 0, 0.6)", mr: 1 }}>{t("bo.filterMdl.reptFilter")}</Typography>
                    </Stack>
                    <ScrollBox height={"55%"}>
                        <Stack gap={2} alignItems="center">

                            <Stack direction={"row"} gap={3}>
                                <FillLabelTxtField defaultValue={filterObj.trkOp} name="trkOp" title={t("bo.filterMdl.trkOp")} placeholder={"Mobile No-1 / First Name / Last Name"}  {...txtFieldProps} width={250} />
                                <CustomDropDown defaultValue={filterObj.order} title={t("bo.trkOpReptsPg.penAmtOrder")} name={"order"} ddArr={orderArr} {...txtFieldProps} width={250} />
                            </Stack>


                            {/* <Stack direction={"row"} gap={3}>

                            </Stack> */}
                        </Stack>
                    </ScrollBox>
                    <Stack direction={"row"} justifyContent={"space-between"} sx={{ mt: 2, mb: 2 }}>
                        <Button variant="outlined" onClick={clearFormFields} sx={{ height: 40, width: 150 }}>{t("bo.filterMdl.clear")}</Button>
                        <Stack justifyContent={"flex-end"} direction={"row"} width={500}>
                            <Button variant="text" sx={{ height: 40, width: 120 }} onClick={handleModalClose}>{t("bo.filterMdl.cancel")}</Button>
                            <Button variant="contained" type='submit' sx={{ height: 40, width: 150 }}>{t("bo.filterMdl.save")}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </form>
        </Modal>
    )
}

export default FilterMdl;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 590,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 3,
    py: 2,
    borderRadius: 2,
    height: "40%"
};


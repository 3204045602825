import { Button, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import CtrlFillLabelTxtField from '../../components/CtrlFillLabelTxtField';
import FillLabelTxtField from '../../components/FillLabelTxtField';
import PlaceAutofill from '../../components/PlaceAutofill';
import ScrollBox from '../../components/ScrollBox';
import { getDistance } from '../../services/serv_services';
import CustomDropDown from '../../components/CustomDropDown';
import { Edit } from '@mui/icons-material';
import { useAuthContext } from '../../context/AuthContext';
import { customAlert, notify } from '../../components/notify';
import { getBasePrice2, putBasePrice2 } from '../../services/pricing-service';


function SamplePricCalc2() {
  const { setLoadingScreen } = useAuthContext();
  const [formErrors, setFormErrors] = useState({});
  const isLarge = useMediaQuery("(min-width: 600px)");
  let txtFielProps = { fontSize: 14, height: 38, width: 200 };
  const [isRpkReadOnly, setIsRpkReadOnly] = useState(true);
  const [loc, setLoc] = useState({
    fromId: null,
    toId: null,
    dis: "0",
    direction: null,
    weight: null,
    isDistance: null,
    ratePerKm: 0,
  })
  const directionArr = [
    { value: "LP", label: "Lower -> Plain" },
    { value: "LU", label: "Lower -> Upper" },
    { value: "LL", label: "Lower -> Lower" },
    { value: "PU", label: "Plain -> Upper" },
    { value: "PL", label: "Plain -> Lower" },
    { value: "PP", label: "Plain -> Plain" },
    { value: "UL", label: "Upper -> Lower" },
    { value: "UP", label: "Upper -> Plain" },
    { value: "UU", label: "Upper -> Upper" }
  ];

  const weightArr = [
    { value: "7-16", label: "7-16 T" },
    { value: "17-21", label: "17-21 T" },
    { value: "22-25", label: "22-25 T" },
    { value: "25-35", label: "25-35 T" }
  ];

  const isDisArr = [
    { value: "499", label: "<500" },
    { value: "501", label: ">500" }
  ];

  const [ans, setAns] = useState({
    final: 0,
  })

  async function handlePicChange(value) {
    setLoc(prevSel => { return { ...prevSel, fromId: value?.placeId } })

    if (loc.toId) {
      await calDistance(value?.placeId, loc.toId)
    }
  }

  async function handleDelChange(value) {
    setLoc(prevSel => { return { ...prevSel, toId: value?.placeId } })

    if (loc.fromId) {
      await calDistance(loc.fromId, value?.placeId)
    }
  }

  async function calDistance(fromId, toId) {
    let res = await getDistance({ fromId, toId })
    setLoc(prevSel => { return { ...prevSel, dis: res?.distance } })
  }

  function handleTxtFieldChange(e) {
    const { name, value } = e.target;
    setLoc(prevSel => { return { ...prevSel, [name]: value } })
  }



  async function handleOnCalc() {
    if (parseFloat(loc.dis) === 0)
      return;
    try {
      let disFin = (loc.dis).replace(/[, ]|km/g, '');
      console.log("33333333333")
      console.log(disFin)
      console.log("4444444444444")
      setAns(prevSel => { return { ...prevSel, final: `${parseFloat(loc.ratePerKm) * parseFloat(disFin)}`, } })

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoadingScreen(true);
    getBasePrice().then((e) => setLoadingScreen(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.direction, loc.isDistance, loc.weight])

  async function getBasePrice(e) {
    try {
      const data = await getBasePrice2(`${loc.direction},${loc.isDistance},${loc.weight}`);
      console.log(data);
     
      if (data)
        setLoc(prevSel => { return { ...prevSel, ratePerKm: parseFloat(data.basePrice) } })
    } catch (err) {
      console.log(err);
      customAlert(err);
    }
  };

  async function updateBasePrice(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object
    let errorsObj = validate(inputObject);
    setFormErrors(errorsObj);

    if (Object.keys(errorsObj).length > 0)
      return;
    setLoadingScreen(true);
    try {
      await putBasePrice2({ combination: `${loc.direction},${loc.isDistance},${loc.weight}`, basePrice: loc.ratePerKm });
      setIsRpkReadOnly(true)
    } catch (err) {
      console.log(err);
      customAlert(err)
    }
    setLoadingScreen(false)
  }

  return (
    <form onSubmit={updateBasePrice} noValidate id='addBo' style={{ padding: 0, backgroundColor: "rgba(0, 0, 0, 0.08)", height: "100vh", width: isLarge ? "100vw" : "70rem" }}>
      <ScrollBox height={"100%"} >
        <Stack gap={4} pt={5} pl={isLarge ? 10 : 3} width={"100%"}>
          <Typography fontSize={25} fontWeight={"bold"}>Sample Price Calculation</Typography>
          <Stack direction={isLarge ? "row" : "column"} gap={2} >
            <PlaceAutofill name="picLocation" title={"Pickup Location"} onSelect={handlePicChange} {...txtFielProps} width={300} />
            <PlaceAutofill name="delLocation" title={"Delivery Location"} onSelect={handleDelChange} {...txtFielProps} width={300} />
            <Stack justifyItems={"center"} ml={2}>
              <Typography fontSize={14} fontWeight={600} mb={0}> Distance </Typography>
              <Typography fontSize={25} fontWeight={"bold"} >{loc.dis}</Typography>
            </Stack>
          </Stack>
          <Stack direction={isLarge ? "row" : "column"} gap={2} >
            <CustomDropDown title={"Direction"} name={"direction"} errMsg={formErrors.direction} handleDDChange={handleTxtFieldChange} ddArr={directionArr} {...txtFielProps} width={300} />
            <CustomDropDown title={"Is Distance"} name={"isDistance"} errMsg={formErrors.isDistance} handleDDChange={handleTxtFieldChange} ddArr={isDisArr} {...txtFielProps} width={300} />
            <CustomDropDown title={"Weight"} name={"weight"} errMsg={formErrors.weight} handleDDChange={handleTxtFieldChange} ddArr={weightArr} {...txtFielProps} width={300} />
          </Stack>

          <Stack direction={isLarge ? "row" : "column"} gap={isLarge ? 30 : 5}>
            <Stack direction={"column"} gap={5} alignItems={"start"}>
              <Stack direction={"row"} alignItems={"end"}>
                <CtrlFillLabelTxtField name="ratePerKm" type="number" step={0.5} title={"Rate Per KM"} {...txtFielProps} value={loc.ratePerKm} onChange={handleTxtFieldChange} readOnly={isRpkReadOnly} disabled={isRpkReadOnly} />
                {isRpkReadOnly === true && <IconButton color="primary" onClick={() => setIsRpkReadOnly(false)}>
                  <Edit />
                </IconButton>}
                {isRpkReadOnly === false && <Button variant='contained' sx={{ width: 200, ml: 4 }} type='submit'>Update Rate Per KM Price</Button>}
              </Stack>
              <Button variant='contained' sx={{ width: 200 }} onClick={handleOnCalc}>Calculate</Button>
            </Stack>
            <Stack sx={{
              bgcolor: "rgba(223, 218, 248, 0.4)", borderRadius: 2, width: 400, minHeight: 285, px: 4, py: 2, border: 1, borderColor: 'rgba(94, 71, 221, 0.5)'
            }}>
              <Typography fontSize={20} fontWeight="bold" color={"primary.main"}>Final Calculation</Typography>
              <Stack gap={2} mt={2}>
                <Ans amt={loc.ratePerKm} title={"Rate per Km"} />
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <Typography fontSize={16} fontWeight={500}>Final Amount -  </Typography>
                  <Typography fontSize={20} color={"primary"} fontWeight={"bold"}><span name="Rs">&#8377;</span>{ans.final} </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

        </Stack>
      </ScrollBox>
    </form>



  )
}

function Ans({ amt, calc, title }) {
  return <Stack direction={"row"} alignItems={"center"} gap={1}>
    <Typography fontSize={16} fontWeight={500}>{title} - <span name="Rs">&#8377;</span>{amt} </Typography>
    {calc && <Typography fontSize={13} >{calc} </Typography>}
  </Stack>
}


function validate(values) {
  const errors = {};

  if (!values.direction)
    errors.direction = "Direction is required";

  if (!values.isDistance)
    errors.isDistance = "isDistance is required";

  if (!values.weight)
    errors.weight = "Weight is required";


  return errors;
};

export default SamplePricCalc2;
import React, { useEffect } from 'react'
import './index.css';
import imgLoginPgBg1 from '../../assets/svg/loginPageBg_1.svg';
import imgLoginPgBg2 from '../../assets/svg/loginPageBg_2.svg';
import imgLoginPgBg3 from '../../assets/svg/loginPageBg_3.svg';
import agentlogin from '../../assets/svg/agentlogin.svg';
import office from '../../assets/svg/office.svg';
import logo from "../../assets/svg/logo.svg";
import { Stack, Button, Typography, Divider } from "@mui/material"
import { useState } from 'react';
import PwdTxtField from '../../components/PwdTxtField';
import LabelTxtField from '../../components/LabelTxtField';
import validateLogin from './validateLogin';
import { customAlert, notify } from '../../components/notify';
import { agentLoginService } from '../../services/login-service';
import { useAuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom';
import route from '../../Routes';



function AgentLoginPage() {
    const [formErrors, setFormErrors] = useState({});
    const { setLoadingScreen } = useAuthContext();

    const { ctxtlogin, ctxtUser } = useAuthContext();
    const navigate = useNavigate();

    const cancelCourse = () => {
        document.getElementById("formId").reset();
    }
  
    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);

        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object

        let errorsObj = validateLogin(inputObject);
        setFormErrors(errorsObj);

        if (Object.keys(errorsObj).length > 0)
            return;

        setLoadingScreen(true);

        try {
            const res = await agentLoginService(inputObject);
            ctxtlogin(res);
            notify("success", "Login successfully");
         
            navigate(route.agDashboard, { replace: true });
            setLoadingScreen(false);
            cancelCourse();
        } catch (err) {
            console.log(err);
            if (err === 401)
                notify("error", "Invalid Username / Password");
            else if (err === 409)
                notify("error", "Agent disabled Contact backoffice to reactivate");
            else
                customAlert(err);
        }
        setLoadingScreen(false);
    };

    async function handleClick() {
        console.log("Onclick")
        //navigate(route.agentInfo, { replace: true });
        navigate(route.boDashboard, { replace: true });
    }

    useEffect(() => {
        console.log("call use effect ---------------------");
        setLoadingScreen(true);
        console.log(ctxtUser?.token);
        if (ctxtUser?.token && ctxtUser?.type === 'sa')
            navigate(route.saBoLists, { replace: true });
        else if (ctxtUser?.token && ctxtUser?.type === 'staff')
            navigate(route.boDashboard, { replace: true });
        else if (ctxtUser?.token && ctxtUser?.type === 'agent') {
            navigate(route.agDashboard, { replace: true });
        }
        setLoadingScreen(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <div className='bg'>
            <img src={imgLoginPgBg1} alt="loginPageBg_1" className='bg-img-1' />
            <img src={imgLoginPgBg2} alt="loginPageBg_2" className='bg-img-2' />
            <img src={imgLoginPgBg3} alt="loginPageBg_3" className='bg-img-3' />
            <img src={agentlogin} alt="loginPageBg_1" className='agentlogin' />
            <img src={logo} alt="logo" className='logo' />

            <form onSubmit={handleSubmit} noValidate id='formId'>
                <br /><br /><br />
                <Typography sx={{ mb: 0.8, fontWeight: "700", fontSize: 23 }}>Welcome</Typography>
                <Typography className='subTxt' sx={{ mb: 4, fontWeight: "500" }}>You are one Step away from Login</Typography>
                <Stack gap={"1.5rem"} className='input-grp'>
                    <LabelTxtField type='text' name='agentcode'
                        placeholder="Enter your Agent Code" title="Agent Code" errMsg={formErrors.agentcode} />

                    <PwdTxtField errMsg={formErrors.password} />

                    <Stack>
                        <Button variant="contained" type='submit'
                            style={{ marginTop: "1rem", height: "3rem" }} sx={{ mb: 2, width: "100%" }}>Login</Button>
                    </Stack>

                    <Button variant="outlined" onClick={handleClick} sx={{ height: 60, color: "#202020", }} style={{ justifyContent: "flex-start", }}>
                        <img src={office} alt="office" width={"40px"} />

                        <Divider orientation="horizontal" sx={{ "&::before, &::after": { border: 'none' } }}>
                            <Typography sx={{ fontWeight: "400", fontSize: 16, marginTop: 4 }}>&emsp;Back Office Login</Typography>
                            <Typography sx={{ mb: 4, fontWeight: "200", fontSize: 13, float: "left" }}>&emsp;&nbsp;&nbsp;Manage Shipment</Typography>

                        </Divider>
                    </Button>
                </Stack>
            </form>
        </div>
    </>
    )
}


export default AgentLoginPage;
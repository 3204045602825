let shipmtReptFieldsArr = [
    {
        id: "shipmtId",
        label: "Shipment Id",
        minWidth: 170
    },
    {
        id: "custFName",
        label: "Customer First Name",
        minWidth: 170
    },
    {
        id: "custLName",
        label: "Customer Last Name",
        minWidth: 170
    },
    {
        id: "trkOpFName",
        label: "TrkOp First Name",
        minWidth: 170
    },
    {
        id: "trkOpLName",
        label: "TrkOp Last Name",
        minWidth: 170
    },
    {
        id: "custReqId",
        label: "Customer Request Id",
        minWidth: 170
    },
    {
        id: "truckOpReqId",
        label: "Truck Operator Request Id",
        minWidth: 170
    },
    {
        id: "currStatus",
        label: "Status",
        minWidth: 170
    },
    {
        id: "trkOpDataFilledBy",
        label: "TrkOp Data Filled By",
        minWidth: 170
    },
    {
        id: "custDataFilledBy",
        label: "Cust Data Filled BY",
        minWidth: 170
    },
    {
        id: "c_by",
        label: "Created By",
        minWidth: 170
    },
    {
        id: "c_at",
        label: "Created At",
        minWidth: 170
    },
    {
        id: "shipmtPicDate",
        label: "Pickup Date",
        minWidth: 170
    },
    {
        id: "shipmtDelDate",
        label: "Delivery Date",
        minWidth: 170
    },
    {
        id: "shipmtPicLoc",
        label: "Pickup Location",
        minWidth: 170
    },
    {
        id: "shipmtDelLoc",
        label: "Delivery Location",
        minWidth: 170
    },
    {
        id: "truckRegNo",
        label: "Truck No",
        minWidth: 170
    },
    {
        id: "matType",
        label: "Material Type",
        minWidth: 170
    },
    {
        id: "weight",
        label: "Weight",
        minWidth: 170
    },
    {
        id: "loadType",
        label: "Load Type",
        minWidth: 170
    },
    {
        id: "custMobile1",
        label: "Customer Mobile1",
        minWidth: 170
    },
    {
        id: "trkOpMobile1",
        label: "Truck Operator Mobile1",
        minWidth: 170
    },
    {
        id: "custPayType",
        label: "Cust Pay Type",
        minWidth: 170
    },
    {
        id: "cust_finalPrc",
        label: "Cust Pricing",
        minWidth: 170
    },
    {
        id: "pendingAmount",
        label: "Cust Pending Amt",
        minWidth: 170
    }
]

export default shipmtReptFieldsArr;
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQueryClient } from 'react-query';
import { useAuthContext } from '../../context/AuthContext';
import { postPanVfy } from '../../services/agent-service';
import { customAlert, notify } from '../notify';
import ScrollBox from '../ScrollBox';
import FillLabelTxtField from '../FillLabelTxtField';
import Fileupload from '../Fileupload';


function PanVerify({ modalOpen, handleModalClose, userId }) {
    const [formErrors, setFormErrors] = useState({});
    const { setLoadingScreen } = useAuthContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    let txtFileProps = { fontSize: 14, height: 38, width: 500 };
    const [selection, setSelection] = useState({});

    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object    
        inputObject.userId = userId;
        inputObject.panFile = selection?.panFile;
        console.log(inputObject);
        let errorsObj = validate(inputObject);
        setFormErrors(errorsObj);

        if (Object.keys(errorsObj).length > 0)
            return;

        setLoadingScreen(true);
        try {
            await postPanVfy(inputObject);
            notify("success", "PAN Verified Successfully");
            handleModalClose();
            queryClient.invalidateQueries();
        } catch (err) {
            console.log(err);
            if (err === 401)
                notify("error", "Not Authorized");
            else if (err === 402)
                notify("error", "Backoffice disabled Contact admin to reactivate");
            else if (err === 403)
                notify("error", "Account Disabled, Please Contact Back office");
            else if (err === 409)
                notify("error", "Invalid PAN Details");
            else
                notify("error", "Something went wrong");
        }
        setLoadingScreen(false)
    }

    function handleOnUpload({ fieldName, fileKey }) {
        setSelection(prevSel => { return { ...prevSel, [fieldName]: fileKey } })
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit} >
                <Box sx={modalStyle}>
                    <Stack direction={'row'} alignItems={"center"} mb={3}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 17, color: "rgba(0, 0, 0, 0.6)", mr: 1 }}>{t("bo.agent.vfyPan")}</Typography>
                    </Stack>
                    <ScrollBox height={"75%"}>
                        <Stack gap={2} >
                            <FillLabelTxtField name="panNo" title={t("bo.agent.panNum")} errMsg={formErrors.panNo} {...txtFileProps} />
                            <FillLabelTxtField name="panName" title={t("bo.agent.panName")} errMsg={formErrors.panName} {...txtFileProps} />
                            <Fileupload name="panFile" title={t("bo.agent.panFile")} txtFieldProps={txtFileProps} errMsg={formErrors.panFile} onUpload={handleOnUpload} />
                        </Stack>
                        <Typography sx={{ fontSize: 14, fontWeight: "600", mt: 2, color: "red" }}>*** {t("bo.agent.note")} {t("bo.agent.panNote")}</Typography>
                    </ScrollBox>
                    <Stack justifyContent={"flex-end"} direction={"row"} sx={{ my: 2 }} width={500}>
                        <Button variant="text" sx={{ height: 40, width: 120 }} onClick={handleModalClose}>{t("bo.picDet.cancel")}</Button>
                        <Button variant="contained" type='submit' sx={{ height: 40, width: 150 }}>{t("bo.agent.vfy")}</Button>
                    </Stack>
                </Box>
            </form>
        </Modal>
    )
}

export default PanVerify;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 590,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 3,
    py: 2,
    borderRadius: 2,
    height: "70%"
};

function validate(values) {
    const errors = {};

    if (!values.panName)
        errors.panName = "PAN Name is required!";

    if (!values.panNo)
        errors.panNo = "PAN Number is required!";

    if (!values.panFile)
        errors.panFile = "PAN File is required!";

    return errors;
};
import { getData, postData, putData, deleteData } from "./rest-api-helper"

export async function trkOpReptPdf({ filterObj, columns }) {
    const details = {
        urlPath: "/pdf/trkOpRept",
        body: {
            filterObj: { ...filterObj }, columns: [...columns]
        }
    }
    console.log(details)
    const res = await postData(details);
    return res
}

export async function shipmtReptPdf({ filterObj, columns }) {
    const details = {
        urlPath: "/pdf/shipmtRept",
        body: {
            filterObj: { ...filterObj }, columns: [...columns]
        }
    }
    console.log(details)
    const res = await postData(details);
    return res
}

export async function tpReqPymtReptPdf({ filterObj, columns }) {
    const details = {
        urlPath: "/pdf/tpReqPymtRept",
        body: {
            filterObj: { ...filterObj }, columns: [...columns]
        }
    }
    console.log(details)
    const res = await postData(details);
    return res
}

export async function adminAgentPdf({ filterObj, columns }) {
    const details = {
        urlPath: "/pdf/adminAgentRept",
        body: {
            filterObj: { ...filterObj }, columns: [...columns]
        }
    }
    console.log(details)
    const res = await postData(details);
    return res
}
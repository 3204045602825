import { Button, ListItemIcon, MenuItem, Pagination, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert } from "../../../components/notify";
import { useTranslation } from "react-i18next";

import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import TrkOpReportsTable from '../../../components/TrkOpReportsTable';
import { ArrowDropDown, ArrowDropUp, Description, FilterAlt, PictureAsPdf } from '@mui/icons-material';
import OptionMenu from '../../../components/OptionMenu';
import FilterMdl from "./FilterMdl";
import { getAllTrkOpRepts } from "../../../services/trkOp-service";
import { trkOpReptExcel } from "../../../services/excel-service";
import { trkOpReptPdf } from "../../../services/pdf-service";
import { useAuthContext } from "../../../context/AuthContext";


function TrpOpReportsListPage() {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const limit = 12
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [filterObj, setFilterObj] = useState({});
  console.log({ ...filterObj })
  const { isLoading, isError, error, data: trkOpData } = useQuery(
    [QueryKey.trkOpRepts, pageNumber, filterObj],
    () => getAllTrkOpRepts({ limit, pageNumber, ...filterObj }), {
    keepPreviousData: true
  });

  const [filterMdl, setFilterMdl] = useState(false);
  const handleFilterMdlClose = () => setFilterMdl(false);
  const handleFilterMdlOpen = () => setFilterMdl(true);
  const { setLoadingScreen } = useAuthContext();


  const columns = [
    { id: "trkOp", label: "Truck Operator", minWidth: 170 },
    { id: "trkRegNo", label: "Truck", minWidth: 50 },
    // { id: "truckType", label: "Truck Type", minWidth: 170 },
    { id: "totalAmount", label: "Total Amount", minWidth: 170 },
    { id: "pendingAmount", label: "Pending Amount", minWidth: 170 }
  ];


  const optionItems = [
    {
      title: t("bo.reptListPg.pdf"),
      icon: <PictureAsPdf fontSize="small" />,
      fn: async () => {
        setLoadingScreen(true);
        try {
          const res = await trkOpReptPdf({ filterObj, columns });
          window.open(res.fileUrl, "_blank")
        } catch (err) {
          console.log(err);
          customAlert(err)
        }
        setLoadingScreen(false);
      }
    },
    {
      title: t("bo.reptListPg.excel"),
      icon: <Description fontSize="small" />,
      fn: async () => {
        try {
          const res = await trkOpReptExcel({ filterObj, columns });
          window.open(res.fileUrl, "_blank")
        } catch (err) {
          console.log(err);
          customAlert(err)
        }
      }
    }
  ]


  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };

  function handleFilterObj(obj) {
    setPageNumber(1);
    setFilterObj({ ...obj })
  }



  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>


  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (

    <BgBox px={4} py={1}>
      <Stack direction={'row'} justifyContent="space-between" alignItems={"center"} mb={0.8} mt={2} px={2}>
        <Stack direction={'row'} alignItems={"center"}>
          <Typography variant='h5' sx={{ fontWeight: "bold", mr: 4, mb: 1 }}>{t("bo.trkOpReptsPg.trkOpRept")}</Typography>
        </Stack>

        <Stack direction={'row'} justifyContent="space-between" alignItems={"center"}>
          <Button variant='outlined' sx={{ ml: 2, mb: 0.4 }} startIcon={<FilterAlt />} color='primary' onClick={handleFilterMdlOpen} >{t("bo.reptListPg.filter")}</Button>
          <Button variant='contained' sx={{ ml: 2, mb: 0.4 }} endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />} color='primary' onClick={handleExportClick} >{t("bo.reptListPg.export")}</Button>

        </Stack>
      </Stack>

      <TrkOpReportsTable trkOpData={trkOpData?.data} columns={columns} />


      <Stack sx={{ alignItems: "center", mt: 1 }}>
        <Pagination count={Math.ceil(trkOpData?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
      </Stack>
      <OptionMenu open={open} handleClose={handleExportClose} anchorEl={anchorEl} >
        {optionItems.map((i) => <MenuItem sx={{ pl: 2, pr: 5 }} key={i.title} onClick={i.fn}>
          <ListItemIcon > {i.icon}</ListItemIcon>
          {i.title}
        </MenuItem>)}
      </OptionMenu>

      <FilterMdl modalOpen={filterMdl} handleModalClose={handleFilterMdlClose} filterObj={filterObj} handleFilterObj={handleFilterObj} />
    </BgBox>

  )
}

export default TrpOpReportsListPage;
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useAuthContext } from '../../../context/AuthContext';
import { putCustBankDetails } from '../../../services/cust-service';
import { customAlert, notify } from '../../../components/notify';
import FillLabelTxtField from '../../../components/FillLabelTxtField';
import { useQueryClient } from 'react-query';



function UpdateCustBankDetails({ modalOpen, handleModalClose, custId, custData }) {

    const [formErrors, setFormErrors] = useState({});
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { setLoadingScreen } = useAuthContext();


    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);

        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object


        let errorsObj = validate(inputObject);
        setFormErrors(errorsObj);
        console.log(errorsObj);

        if (Object.keys(errorsObj).length > 0)
            return;


        setLoadingScreen(true);


        try {
            await putCustBankDetails(custId, inputObject);
            notify("success", "Bank Details Updated Successfully");
            setLoadingScreen(false);
            handleModalClose();
            queryClient.invalidateQueries();
        } catch (err) {
            customAlert(err);
        }
        setLoadingScreen(false);
    };


    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit} >
                <Box sx={modalStyle}>
                    <Typography variant='h5' sx={{ fontWeight: "bold", mb: 2 }}>{ t("bo.custInfoPg.updateBankDetails") }</Typography>
                    <Stack gap={2} alignItems="center">
                        <FillLabelTxtField name="accName" title={t("bo.custInfoPg.accName")} defaultValue={custData?.accName} width={400} errMsg={formErrors.accName} />
                        <FillLabelTxtField name="accNo" title={t("bo.custInfoPg.accNo")} defaultValue={custData?.accNo} width={400} errMsg={formErrors.accNo} />
                        <FillLabelTxtField name="ifsc" title={"IFSC"} defaultValue={custData?.ifsc} width={400} errMsg={formErrors.ifsc} />
                        <Stack justifyContent={"flex-end"} direction={"row"} sx={{ mt: 2 }} width={400}>
                            <Button variant="text" sx={{ height: 40, width: 120 }} onClick={handleModalClose}>{t("bo.createGrp.cancel")}</Button>
                            <Button variant="contained" type='submit' sx={{ height: 40, width: 150 }}>{t("bo.createGrp.create")}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </form>
        </Modal>
    )
}

export default UpdateCustBankDetails;

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    bgcolor: 'background.paper',
    border: '2px solid #ffff',
    boxShadow: 24,
    px: 4,
    py: 3,
    borderRadius: 2,
};

function validate(values) {
    const errors = {};

    // if (!values.grpName)
    //     errors.grpName = "Group Name is required!";

    // console.log();
    return errors;
};
import { Button, ButtonBase, Pagination, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState, useEffect } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert, notify } from "../../../components/notify";
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import { useNavigate } from 'react-router-dom';
import CtrlOutTxtField from "../../../components/CtrlOutTxtField";
import { Search } from "@mui/icons-material";
import { getLast30Days, getLocalStrg, makeTxtOverFLow } from "../../../utils/format";
import { getAllUnsettleShipmts, getSingleAgent, getUnsettleShipmts } from "../../../services/agent-service";
import SelectAgent from "../../../components/SelectAgent";
import UnsettleShipmtTable from "../../../components/UnsettleShipmtTable";
import Settle from "./Settle";


function AgSettlePymtPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [payAmt, setPayAmt] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const limit = 6;
  const defaultDates = getLast30Days()
  const [filterObj, setFilterObj] = useState({
    agentId: "",
    fromDate: defaultDates.priorDate,
    toDate: defaultDates.today,
    agentName: "",
    agentCode: ""
  });
  const { isLoading, isError, error, data: shipmtRows, refetch } = useQuery(
    [QueryKey.agUnsettleShipmts, limit, pageNumber, filterObj.agentId],
    () => getUnsettleShipmts({ limit, pageNumber, ...filterObj }), {
    keepPreviousData: true
  });

  const { isLoading: isAllLoading, isError: isAllErr, error: allErr, data: allShipmtRows, refetch: allrefetch } = useQuery(
    [QueryKey.agAllUnsettleShipmts, filterObj.agentId],
    () => getAllUnsettleShipmts({ ...filterObj }), {
    keepPreviousData: true
  });

  const [selectAgOpen, setSelectAgOpen] = useState(filterObj.agentId ? false : true);
  const handleSelectAgClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      handleCancel()
    else
      setSelectAgOpen(false);
  }
  const handleSelectAgOpen = (event, reason) => {
    setSelectAgOpen(true);
  }

  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };

  const { isLoading: isAgLoading, isError: isAgErr, error: agErr, data: agentData } = useQuery([QueryKey.singleAg, filterObj.agentId], () => getSingleAgent(filterObj.agentId), { enabled: Boolean(filterObj.agentId) });

  const [settleOpen, setSettleOpen] = useState(false);
  const handleSettleClose = () => {
    setSettleOpen(false);
 
  }
  const handleSettleOpen = () => {
    if (payAmt <= 0) {
      notify("info", t("bo.agent.payNotify"))
    } else {
      setSettleOpen(true);
    }
  }


  function AgCountCard({ title, amount, value }) {
    return <Box sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: 60, border: 1, borderColor: '#E3E3E3', width: 310, px: 3, pt: 0.5 }}>
      {amount && <Typography sx={{ fontWeight: "600", fontSize: 20 }}><span name="Rs">&#8377;</span> {getLocalStrg(amount)}</Typography>}
      {value && <Typography sx={{ fontWeight: "600", fontSize: 20 }}>{value}</Typography>}
      <Typography sx={{ color: "secondary.main", fontSize: 13 }}>{title}</Typography>
    </Box>
  }

  function handleSetAgent(agentObj) {
    setFilterObj((e) => {
      return {
        ...e,
        agentId: agentObj.agentId,
        agName: agentObj.agName,
        agentCode: agentObj.agentCode
      }
    });
  }


  function handleTxtFieldChange(e) {
    const { name, value } = e.target;
    console.log({ name, value })
    setFilterObj(prevSel => { return { ...prevSel, [name]: value } })
  }

  async function handleCancel() {
    navigate(-1, { replace: true });
  }

  function handleSerarch() {
    refetch();
    allrefetch();
  }

  useEffect(() => {
    if (agentData)
      setPayAmt(revenue * ((agentData?.agComPer ?? 0) / 100))
  }, [revenue, agentData])

  if (isError || isAllErr || isAgErr) {
    customAlert(error);
    customAlert(allErr);
    customAlert(agErr);
    return <h2>Something went wrong</h2>
  }

  if (isLoading || isAllLoading || isAgLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>


  return (
    <Box height={"100%"} width={"100%"}>
      <Stack direction={'row'} justifyContent='space-between' mb={1}>
        <Stack direction={'row'} alignItems={"center"} gap={0.5} mr={4}>
          <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("bo.agent.settleAgPymt")}  </Typography>
          <Typography sx={{ fontWeight: "bold", fontSize: 17, color: "rgba(0, 0, 0, 0.3)" }} mr={1}>({makeTxtOverFLow(filterObj.agName, 10) + " - " + filterObj.agentCode})</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={"center"} gap={1} >
          <ButtonBase component="div" onClick={handleSelectAgOpen}>
            <CtrlOutTxtField value={`${filterObj.agName} - ${filterObj.agentCode}`} title={t("bo.agent.agent")} placeholder={t("bo.agent.srchAg")} fontSize={13} height={35} width={200} />
          </ButtonBase>
          <CtrlOutTxtField value={filterObj.fromDate} name={"fromDate"} onChange={handleTxtFieldChange} title={t("bo.filterMdl.fromDate")} fontSize={13} height={35} width={140} type={"date"} />
          <CtrlOutTxtField value={filterObj.toDate} name={"toDate"} onChange={handleTxtFieldChange} title={t("bo.filterMdl.toDate")} fontSize={13} height={35} width={140} type={"date"} />
          <Button variant='contained' sx={{ height: 35, fontSize: 14 }} startIcon={<Search />} color='primary' onClick={handleSerarch}>{t("bo.cusReqLstPg.search")}</Button>
        </Stack>
      </Stack>
      <Stack direction={'row'} alignItems={"end"} gap={1} mb={1}>
        <AgCountCard title={t("bo.agent.totalRevenue")} amount={revenue.toString()} />
        <AgCountCard title={t("bo.agent.commPer")} value={`${agentData?.agComPer ?? 0}%`} />
        <AgCountCard title={t("bo.agent.payableAmt")} amount={payAmt.toString()} />
        <Button variant='contained' sx={{ width: 100 }} color='primary' onClick={handleSettleOpen}>{t("bo.agent.settle")}</Button>
      </Stack>

      {(filterObj.agentId) ? <BgBox height={"82%"} px={4} py={1}>
        <UnsettleShipmtTable shipmtRows={shipmtRows?.data} height={"90%"} allShipmtRows={allShipmtRows?.data} selectedRows={selectedRows} setSelectedRows={setSelectedRows} revenue={revenue} setRevenue={setRevenue} />
        <Stack sx={{ alignItems: "center", mt: 1 }}>
          <Pagination count={Math.ceil(shipmtRows?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
        </Stack>
      </BgBox> : <BgBox height={"82%"} px={4} py={1}>
        <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>**{t("bo.agent.plSelAg")}</Typography>
        </Stack>
      </BgBox>}
      <SelectAgent handleClose={handleSelectAgClose} open={selectAgOpen} handleSetAgent={handleSetAgent} />
      {agentData && <Settle handleModalClose={handleSettleClose} modalOpen={settleOpen} payAmt={payAmt} revenue={revenue} agComPer={agentData?.agComPer} agentId={filterObj.agentId} shipmtIds={selectedRows}/>}
    </Box>
  )
}

export default AgSettlePymtPage;
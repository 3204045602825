import { getData, postData, putData } from "./rest-api-helper"

export async function postAgent(data) {
    const details = {
        urlPath: "/agent/create",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------postAgent");
    return res;
}

export async function getAllAgWPgSch({ limit, pageNumber, searchKeyWord }) {
    const details = {
        urlPath: "/agent",
        queryParams: {
            limit,
            offset: (pageNumber * limit) - limit,
            ...(searchKeyWord && { searchKeyWord })
        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/agent",
        queryParams: {
            ...(searchKeyWord && { searchKeyWord })
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getAllAgWPgSch");

    return {
        totalLength: res2?.length,
        data: res ? res : []
    }
}

export async function putAgentStatus(agentId, status) {
    const details = {
        urlPath: "/agent/status",
        queryParams: { agentId: agentId, status }
    }

    const res = await putData(details);
    console.log(res + "---------------putAgentStatus");
    return res;
}

export async function getSingleAgent(agentId) {
    const details = {
        urlPath: `/agent/getSingleAgent/${agentId}`
    }

    const res = await getData(details);
    console.log(res, "---------------getSingleAgent");
    return res;
}

export async function putAgent(agentId, data) {
    const details = {
        urlPath: `/agent/edit/${agentId}`,
        body: {
            ...data
        }
    }
    const res = await putData(details);
    console.log(res + "---------------putAgent");
    return res;
}

export async function getCustReqsByAgent({ limit, pageNumber, agentId, order, time, reqStatus }) {
    let queryParamsObj = {
        agentId,
        order,
        time,
        ...(reqStatus && { reqStatus }),
    }

    const details = {
        urlPath: "/request/byAgent",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,
        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/request/byAgent",
        queryParams: {
            ...queryParamsObj,
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getCustReqsByAgent");
    return {
        totalLength: res2 ? res2?.length : 0,
        data: res ? res : []
    }
}

export async function getCustShipmtsByAgent({ limit, pageNumber, order, time, agentId }) {
    let queryParamsObj = {
        agentId,
        time: time,
        order
    }

    const details = {
        urlPath: "/shipment/byAgent",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,

        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/shipment/byAgent",
        queryParams: {
            ...queryParamsObj
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getCustShipmtsByAgent");

    return {
        totalLength: res2 ? res2?.length : 0,
        data: res ? res : []
    }
}

export async function putAgentBoRating({ agentId, boAgRating, boAgFeedback }) {
    const details = {
        urlPath: `/agent/agentRatingByBo/${agentId}`,
        body: {
            boAgRating, boAgFeedback
        }
    }

    const res = await putData(details);
    console.log(res + "---------------putAgentBoRating");
    return res;
}

export async function agGetAllCustWPgSch({ limit, pageNumber, searchKeyWord, agentId }) {
    const details = {
        urlPath: "/agent/customers",
        queryParams: {
            agentId,
            limit,
            offset: (pageNumber * limit) - limit,
            ...(searchKeyWord && { searchKeyWord })
        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/agent/customers",
        queryParams: {
            agentId,
            ...(searchKeyWord && { searchKeyWord })
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------agGetAllCustWPgSch");

    return {
        totalLength: res2?.length,
        data: res ? res : []
    }
}

export async function agPostCust(data) {
    const details = {
        urlPath: "/agent/createCust",
        body: {
            ...data
        }
    }

    const res = await postData(details);
    console.log(res, "---------------agPostCust");
    return res;
}

export async function agGetAllCustReqWPagSrch({ limit, pageNumber, searchKeyWord, searchType, order, time, agentId }) {
    let queryParamsObj = {
        time: time,
        ...(searchKeyWord && { searchKeyWord }),
        ...(searchType && { searchType }),
        order,
        agentId
    }

    const details = {
        urlPath: "/agent/getAllCustReqs",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,
        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/agent/getAllCustReqs",
        queryParams: {
            ...queryParamsObj,
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------agGetAllCustReqWPagSrch");
    console.log({ limit, pageNumber, searchKeyWord, searchType, order, time, agentId })
    return {
        totalLength: res2 ? res2?.length : 0,
        data: res ? res : []
    }
}

export async function agGetAllShipmts({ limit, pageNumber, searchKeyWord, searchType, order, time, agentId }) {
    let queryParamsObj = {
        time: time,
        ...(searchKeyWord && { searchKeyWord }),
        ...(searchType && { searchType }),
        order,
        agentId
    }

    const details = {
        urlPath: "/agent/getAllShipmts",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,

        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/agent/getAllShipmts",
        queryParams: {
            ...queryParamsObj,
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------agGetAllShipmts");
    console.log({ limit, pageNumber, searchKeyWord, searchType, order })
    return {
        totalLength: res2 ? res2?.length : 0,
        data: res ? res : []
    }
}

export async function agPostCustReq(data) {
    const details = {
        urlPath: "/agent/createCustReq",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------agPostCustReq");
    return res;
}

export async function putshipmtCustRating({ shipmtId, body }) {
    const details = {
        urlPath: `/shipment/shipmtCustRating/${shipmtId}`,
        body: {
            ...body
        }
    }

    const res = await putData(details);
    console.log(res + "---------------putshipmtCustRating");
    return res;
}

export async function putShipmtCustData({ shipmtId, body }) {
    const details = {
        urlPath: `/shipment/custData/${shipmtId}`,
        body: {
            ...body
        }
    }

    const res = await putData(details);
    console.log(res + "---------------putShipmtCustData");
    return res;
}

export async function postPanVfy(data) {
    const details = {
        urlPath: "/services/verifyPAN",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------postPanVfy");
    return res;
}

export async function postGSTVfy(data) {
    const details = {
        urlPath: "/services/verifyGSTNew",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------postGSTVfy");
    return res;
}

export async function postAadhaarVfy(data) {
    const details = {
        urlPath: "/services/reqAadhaarVerify",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------postAadhaarVfy");
    return res;
}

export async function getUnsettleShipmts({ agentId, fromDate, toDate, limit, pageNumber }) {
    let queryParamsObj = {
        fromDate,
        toDate,
        agentId
    }

    const details = {
        urlPath: "/agent/getUnSettledShipmts",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,

        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/agent/getUnSettledShipmts",
        queryParams: {
            ...queryParamsObj,
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getUnsettleShipmts");
    console.log({ agentId, fromDate, toDate, limit, pageNumber })
    return {
        totalLength: res2 ? res2?.length : 0,
        data: res ? res : []
    }
}

export async function getAllUnsettleShipmts({ agentId, fromDate, toDate }) {
    let queryParamsObj = {
        fromDate,
        toDate,
        agentId
    }

    const details2 = {
        urlPath: "/agent/getUnSettledShipmts",
        queryParams: {
            ...queryParamsObj,
        }
    }
    let res2 = await getData(details2);

    console.log(res2, "---------------getAllUnsettleShipmts");
    console.log({ agentId, fromDate, toDate })
    return {
        totalLength: res2 ? res2?.length : 0,
        data: res2 ? res2 : []
    }
}

export async function getAgentPymts({ agentId, fromDate, toDate, order, limit, pageNumber }) {
    let queryParamsObj = {
        fromDate,
        toDate,
        order,
        ...(agentId && { agentId }),
    }

    const details = {
        urlPath: "/agent/getAgPymts",
        queryParams: {
            ...queryParamsObj,
            limit,
            offset: (pageNumber * limit) - limit,
        }
    }
    let res = await getData(details);

    const details2 = {
        urlPath: "/agent/getAgPymts",
        queryParams: {
            ...queryParamsObj,
        }
    }
    let res2 = await getData(details2);

    console.log(res, "---------------getAgentPymts");
    console.log({ agentId, fromDate, toDate, order, limit, pageNumber })
    return {
        totalLength: res2.data ? res2?.data.length : 0,
        data: res
    }
}

export async function postAgPymt(data) {
    const details = {
        urlPath: "/agent/addAgentPymt",
        body: {
            ...data
        }
    }
    const res = await postData(details);
    console.log(res, "---------------postAgPymt");
    return res;
}
import { styled } from '@mui/material/styles';
import { FormatListBulleted, GridViewRounded, History, Update } from "@mui/icons-material";
import { Grid, Pagination, Typography, ToggleButton, ToggleButtonGroup, Button, } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert } from "../../../components/notify";
import ScrollBox from "../../../components/ScrollBox";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import MissingPage from '../../MissingPage';
import ShipmtCard from '../../../components/ShipmtCard';
import { getAllShipmts, getTrkOpShipmts } from '../../../services/shipmt-service';
import ShipmtTable from '../../../components/ShipmtTable';
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';
import { capFirstLetter, capFirstLetterOnly } from '../../../utils/format';
import route from '../../../Routes';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function TrkOpShipmtListPage() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { trkOpId, trkOpName, time } = Object.fromEntries([...searchParams]);

  const [pageNumber, setPageNumber] = useState(1);
  const [view, setView] = useState('list');
  const [order, setOrder] = useState('desc');
  const limit = 6
  const navigate = useNavigate();

  const { isLoading, isError, error, data: shipmtRow } = useQuery(
    [QueryKey.trkopShipmts, pageNumber, order, time, trkOpId],
    () => getTrkOpShipmts({ limit, pageNumber, order, time, trkOpId }), {
    keepPreviousData: true
  });


  function onViewChange(e, v) {
    if (v == null)
      return

    setView(v);
  }

  async function onOrderChange(e, or) {
    if (or == null)
      return

    setOrder(or);
    setPageNumber(1);
  }


  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };


  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

  if (time !== "current" && time !== "history") {
    return <MissingPage />
  }

  return (

    <BgBox px={4} py={1}>
      <Stack direction={'row'} justifyContent="space-between" alignItems={"center"} mb={0.8} mt={2} px={2}>
        <Stack direction={'row'} alignItems={"center"}>


          <Stack direction={'row'} alignItems={"center"} gap={0.5} mr={4}>
            <Typography variant='h5' sx={{ fontWeight: "bold" }}>{time === "current" ? t("bo.shipListPg.ship") : t("bo.shipListPg.shipHis")} </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: 18, color: "rgba(0, 0, 0, 0.5)" }}>({capFirstLetterOnly(trkOpName)})</Typography>
          </Stack>

          <StyledToggleButtonGroup color="primary" size="small" value={view} exclusive onChange={onViewChange} sx={{ mb: 0.5, height: 36 }}>
            <ToggleButton value='list'>
              <FormatListBulleted /> <Typography sx={{ ml: 0.3, fontSize: 15 }}>{t("bo.shipListPg.list")}</Typography>
            </ToggleButton>
            <ToggleButton value="grid">
              <GridViewRounded /> <Typography sx={{ ml: 0.3, fontSize: 15 }}>{t("bo.shipListPg.grid")}</Typography>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Stack>

        <Stack direction={'row'} justifyContent="space-between" alignItems={"center"}>
          <StyledToggleButtonGroup color="primary" size="small" value={order} exclusive onChange={onOrderChange} sx={{ mb: 0.5, height: 36, mx: 3 }}>
            <ToggleButton value='desc'>
              <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDown /></Stack>
            </ToggleButton>
            <ToggleButton value="asc">
              <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDownAlt /></Stack>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Button variant='outlined' startIcon={time == "current" ? <History /> : <Update />}
            sx={{ fontSize: 15, fontWeight: "w900" }} onClick={() => navigate(route.boTrkOpShipments + "?trkOpId=" + trkOpId + "&trkOpName=" + trkOpName + "&time=" + (time == "current" ? "history" : "current"))}
          >{time == "current" ? t("bo.filterMdl.history") : t("bo.filterMdl.current")}</Button>
        </Stack>

      </Stack>

      {view === 'list' ? <ShipmtTable shipmtRow={shipmtRow?.data} /> :
        <ScrollBox height={"84%"}>
          <Grid container spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {shipmtRow?.data.map((i) => <Grid item xs="auto" key={i.id}> <ShipmtCard i={i} /> </Grid>)}
          </Grid>
        </ScrollBox>
      }

      <Stack sx={{ alignItems: "center", mt: 1 }}>
        <Pagination count={Math.ceil(shipmtRow?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
      </Stack>

    </BgBox>

  )
}

export default TrkOpShipmtListPage;
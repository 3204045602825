import { Add, Edit, PersonAdd } from '@mui/icons-material'
import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BgBox from '../../../components/BgBox';
import { customAlert } from '../../../components/notify';
import { useAuthContext } from '../../../context/AuthContext';
import route from '../../../Routes';
import {  getAllBoByStatus, putBoStatus } from '../../../services/bo-service';
import { useTranslation } from "react-i18next";
import BoTable from './BoTable';
import { useQuery } from 'react-query';
import QueryKey from '../../../QueryKey';
import LoadingScreen from '../../../components/loadingScreen';


function BoListPage() {
    const [listStatus, setListStatus] = useState('1')
    const { setAsyncLoading } = useAuthContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    // const isLarge = useMediaQuery("(min-width: 600px)");
    const [currMenuBoId, setCurrMenuBoId] = useState(null);
    const { t } = useTranslation();
    const { isLoading, isError, error, data: boRows, refetch } = useQuery(
        [QueryKey.boList, listStatus], () => getAllBoByStatus(listStatus)
    )

    const optionItems = [
        {
            title: t("sa.boLstPg.edit"),
            icon: <Edit fontSize="small" />,
            fn: handleEdit
        },
        {
            title: t("sa.boLstPg.addUser"),
            icon: <PersonAdd fontSize="small" />,
            fn: handleAddUser
        }
    ]


    const handleOptionClick = (event, boId) => {
        setAnchorEl(event.currentTarget);
        setCurrMenuBoId(boId);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleEdit() {
        const selectedBo = boRows.filter((e) => e.id === currMenuBoId);
        handleClose();
        navigate(route.editBo + selectedBo[0].id, { state: { boData: selectedBo[0] } });
    }
    async function handleAddUser() {
        const selectedBo = boRows.filter((e) => e.id === currMenuBoId);
        handleClose();
        navigate(route.addUser + "?boId=" + selectedBo[0].id, { state: { boData: selectedBo[0] } });
    }

    async function handleRowClick(rowBoId) {
        const selectedBo = boRows.filter((e) => e.id === rowBoId);
        handleClose();
        navigate(route.boInfo + selectedBo[0].id);
    }



    async function updateBoStatus(boRows) {
        setAsyncLoading(true);
        try {
            let status = boRows.status === 1 ? 0 : 1;
            await putBoStatus(boRows.id, status)
            refetch();
        } catch (err) {
            console.log(err);
            customAlert(err);
        }
        setAsyncLoading(false)
    }

    function handleAddBoClick() {
        navigate(route.saAddBo);
    }

    async function handleTabChange(event, newValue) {
        setListStatus(newValue);
        refetch();
    }

    if (isError) {
        customAlert(error);
        return <h2>Something went wrong</h2>
    }

    if (isLoading)
        return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

    return (
        <BgBox>
            <Stack direction={'row'} justifyContent='space-between' mx={2} mb={2} alignItems={"center"} >
                <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("sa.boLstPg.bo")}</Typography>

                <Stack direction={'row'} gap={5} alignItems={"center"}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={listStatus} onChange={handleTabChange} aria-label="lab API tabs example" >
                            <Tab label={<span style={{ fontWeight: '700', fontSize: 16 }}>{t("sa.boLstPg.active")}</span>} value="1" />
                            <Tab label={<span style={{ fontWeight: '700', fontSize: 16 }}>{t("sa.boLstPg.inactive")}</span>} value="0" />
                        </Tabs>
                    </Box>

                    <Button sx={{ height: 40 }} variant='contained' startIcon={<Add />} color='primary' onClick={handleAddBoClick}>{t("sa.boLstPg.addBo")}</Button>
                </Stack>

            </Stack>
            <BoTable boRows={boRows}
                updateBoStatus={updateBoStatus}
                handleOptionClick={handleOptionClick}
                handleRowClick={handleRowClick}
                open={open} handleClose={handleClose}
                anchorEl={anchorEl} optionItems={optionItems} />
        </BgBox>
    )
}

export default BoListPage;
import React from 'react'
import { Stack, TextField } from "@mui/material";

function CtrlOutTxtField({ name, title,width, placeholder, type, errMsg, defaultValue, value, onChange, readOnly, disabled, height, fontSize }) {
    return (    <Stack sx={{ width: width ? width : 500 }}>
        <TextField
            variant="outlined"
            type={type} name={name} id={title}
            label={title}
            placeholder={placeholder}
            fullWidth
            error={errMsg && true}
            helperText={errMsg && errMsg}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            InputProps={{
                readOnly: readOnly,
                disabled: disabled,
            }}
            sx={{
                "& .MuiInputBase-root": {
                    height: height ? height : 45,
                    backgroundColor: "#F5F6F8",
                    fontSize: fontSize || 16,

                },
            }}
            InputLabelProps={{ shrink: true }}
        /></Stack>
    )
}

export default CtrlOutTxtField
import { Stack } from '@mui/system'
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import QueryKey from '../../../QueryKey';
import { getRateDetails } from '../../../services/cust-service';
import { Box, IconButton, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import StyledTableContainer from '../../../components/StyledTableContainer';
import { capFirstLetter, getLocalStrg } from '../../../utils/format';
import { customAlert } from '../../../components/notify';
import LoadingScreen from '../../../components/loadingScreen';
import { useTranslation } from 'react-i18next';
import ScrollBox from '../../../components/ScrollBox';
import { EditTwoTone, KeyboardArrowRight } from '@mui/icons-material';
import EditBaseRate from './EditBaseRate';
import ViewStateList from './ViewStateList';


function PricFacInfoPage() {
    const isLarge = useMediaQuery("(min-width: 600px)");
    const { t } = useTranslation();
    const { isLoading, isError, error, data } = useQuery([QueryKey.rateDetails], getRateDetails);
    const [currBr, setCurrBr] = useState({});
    const [modalEditBrOpen, setModalEditBrOpen] = useState(false);
    const handleModalEditBrClose = () => setModalEditBrOpen(false);
    const handleModalEditBrOpen = (brRow) =>{
        setCurrBr(brRow);
        setModalEditBrOpen(true);
    } 
    const [currZone, setCurrZone] = useState({});
    const [modalStateOpen, setModalStateOpen] = useState(false);
    const handleModalStateClose = () => setModalStateOpen(false);
    const handleModalStateOpen = (zoneRow) =>{
        setCurrZone(zoneRow);
        setModalStateOpen(true);
    } 

    const columns = [
        {
            id: 'zone',
            label: t("sa.rateCalc.zone"),
            align: 'left',
            minWidth: 100
        },
        {
            id: 'stateCount',
            label: t("sa.rateCalc.noStates"),
            // align: 'right',
            minWidth: 80,
            format: (value) => value.toLocaleString('en-IN'),
        },
        {
            id: 'click',
            align: 'left',
            minWidth: 80,
        }
    ];

    const brCols = [
        {
            id: 'fromZone',
            label: t("sa.rateCalc.from"),
            align: 'left',
            minWidth: 20
        },
        {
            id: 'toZone',
            label: t("sa.rateCalc.to"),
            align: 'left',
            minWidth: 100
        },
        {
            id: 'rate',
            label: t("sa.rateCalc.br"),
            align: 'left',
            minWidth: 100
        },
        {
            id: 'range',
            label: t("sa.rateCalc.range"),
            align: 'left',
            minWidth: 100
        },
        {
            id: 'edit',
            align: 'left',
            minWidth: 100
        }
    ];


    async function handleZoneClick(row) {
        handleModalStateOpen(row);
    }

    async function handleBrEditClick(row) {
        handleModalEditBrOpen(row);
    }

    if (isError) {
        customAlert(error);
        return <h2>Something went wrong</h2>
    }

    if (isLoading)
        return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

    return (<>
        {data ? <ScrollBox height={"100%"}>

            <Stack direction={'row'} justifyContent={"space-between"} alignItems={"center"} mb={1}>
                <Typography variant='h5' sx={{ fontWeight: "bold", mr: 1 }}>{t("sa.rateCalc.prcCalDet")}</Typography>
                <Typography sx={{ color: "red", fontSize: 12, mr: 5 }}>*{t("sa.rateCalc.rateNote")}</Typography>

            </Stack>
            <Stack direction={'row'} alignItems={''} sx={{ height: "100%" }} >
                <Stack sx={{ width: isLarge ? "49%" : "52%" }} >
                    <Box py={2} px={3} mr={2} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: 310, border: 1, borderColor: '#E3E3E3' }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 18 }}>{t("sa.rateCalc.zones")}</Typography>
                        </Stack>
                        <StyledTableContainer height={"90%"}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                                            key={column.id}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(data?.zones || []).map((reqRow) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={reqRow.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => { handleZoneClick(reqRow); }}>
                                            {columns.map((column) => {
                                                const value = reqRow[column.id];
                                                return ((column.id === 'click') ?
                                                    <TableCell key={column.id} align={column.align}>
                                                        <IconButton >
                                                            <KeyboardArrowRight color='primary' />
                                                        </IconButton>
                                                    </TableCell> : <TableCell key={column.id} align={column.align} sx={{ textAlign: "left", py: 1.4 }}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : capFirstLetter(value)}
                                                    </TableCell>);
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </StyledTableContainer>
                    </Box>
                    <Box py={2} mt={1} pl={3} pr={1} mr={2} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: 1000, border: 1, borderColor: '#E3E3E3' }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={1}>
                            <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 18 }}>{t("sa.rateCalc.formula")}</Typography>
                        </Stack>
                        <ScrollBox height={"90%"}>
                            <FormulaCard desc={"Final Base Rate (FBR)"} formula={"Base Rate * Distance"} />
                            <FormulaCard desc={"If 6 Wheel Full Load (Weight > 50% Capcity) (6WF)"} formula={"70% * FBR"} />
                            <FormulaCard desc={"If 10 Wheel Full Load (Weight > 50% Capcity) (10WF)"} formula={"FBR"} />
                            <FormulaCard desc={"If 12 Wheel Full Load (Weight > 50% Capcity) (12WF)"} formula={"130% * FBR"} />
                            <FormulaCard desc={"If 6 Wheel Part Load (Weight < 50% Capcity) (6WP)"} formula={"80% * 6WF"} />
                            <FormulaCard desc={"If 10 Wheel Part Load (Weight < 50% Capcity) (10WP)"} formula={"6WF"} />
                            <FormulaCard desc={"If 12 Wheel Part Load (Weight < 50% Capcity) (12WP)"} formula={"130% * 6WF"} />
                            <FormulaCard desc={"If Distance <500 Km"} formula={"130% * Final Rate"} />
                            <FormulaCard desc={"If Distance >500 Km"} formula={"Final Rate"} />
                        </ScrollBox>
                    </Box>
                </Stack>
                <Stack sx={{ width: isLarge ? "49%" : "52%" }} >
                    <Box py={2} px={3} mr={2} sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: "93%", border: 1, borderColor: '#E3E3E3' }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography sx={{ fontWeight: "bold", color: "rgba(131, 146, 171, 0.6)", fontSize: 18 }}>{t("sa.rateCalc.brs")}</Typography>
                        </Stack>
                        <StyledTableContainer height={"90%"}>
                            <TableHead>
                                <TableRow>
                                    {brCols.map((brCol) => (
                                        <TableCell
                                            align={brCol.align}
                                            style={{ minWidth: brCol.minWidth }}
                                            sx={{ color: "#8392AB", fontWeight: "bold", py: 1 }}
                                            key={brCol.id}
                                        >
                                            {brCol.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(data?.baseRates || []).map((brRow) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={brRow.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                            {brCols.map((brCol) => {
                                                const value = brRow[brCol.id];
                                                return ((brCol.id === 'edit') ?
                                                    <TableCell key={brCol.id} align={brCol.align}>
                                                        <IconButton onClick={(e) => { handleBrEditClick(brRow) }}>
                                                            <EditTwoTone color='primary' />
                                                        </IconButton>
                                                    </TableCell> : (brCol.id === 'rate') ?
                                                        <TableCell key={brCol.id} align={brCol.align} sx={{ textAlign: "left" }}>
                                                            <span name="Rs">&#8377;</span>  {getLocalStrg(brRow?.rate)}
                                                        </TableCell> : (brCol.id === 'range') ?
                                                            <TableCell key={brCol.id} align={brCol.align} sx={{ textAlign: "left" }}>
                                                                <span name="Rs">&#8377;</span>  {getLocalStrg(brRow?.minRate)} - <span name="Rs">&#8377;</span>  {getLocalStrg(brRow?.maxRate)}
                                                            </TableCell> : <TableCell key={brCol.id} align={brCol.align} sx={{ textAlign: "left", py: 1.4 }}>
                                                                {brCol.format && typeof value === 'number' ? brCol.format(value) : capFirstLetter(value)}
                                                            </TableCell>);
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </StyledTableContainer>
                    </Box>
                </Stack>
            </Stack>
            <EditBaseRate modalOpen={modalEditBrOpen} handleModalClose={handleModalEditBrClose} br={currBr} />
            <ViewStateList modalOpen={modalStateOpen} handleModalClose={handleModalStateClose} zoneId={currZone.id} zone={currZone.zone}/>

        </ScrollBox> : <Typography component={'h1'}>Something went wrong</Typography>}
    </>
    )
}

function FormulaCard({ desc, formula }) {
    return <Stack alignItems={"center"} mb={1} py={1} pl={1} sx={{ backgroundColor: "rgba(223, 218, 248, 0.2)", borderRadius: 1.4, height: 50, border: 1, borderColor: 'rgba(223, 218, 248, 0.9)', width: 580 }}>
        <Stack direction={"row"} alignItems={"center"}>
            <Typography sx={{ fontSize: 14 }} width={330} >
                {desc}
            </Typography>
            <Box height={35} width={2} mx={2} backgroundColor={'rgba(223, 218, 248, 0.9)'} />
            <Typography sx={{ fontSize: 14 }} width={187}>
                {formula}
            </Typography>
        </Stack>
    </Stack>
}

export default PricFacInfoPage
import { Cancel, Close, Error, Verified, Warning } from '@mui/icons-material';
import { Stack, Typography, colors } from '@mui/material';
import moment from 'moment';

export function formatDateTime(dateTime) {
   return moment(dateTime).format('lll');
}

export function capFirstLetter(string) {
   if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
   }
   return ""
}

export function capFirstLetterOnly(string) {
   if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
   }
   return ""
}

export function convPayType(string) {
   if (!string)
      return ""
   else if (string === 'full')
      return "Full Payment"
   else if (string === 'advance')
      return "Advance"
   else if (string === 'topay')
      return "To Pay"
   else
      return string
}

export function formatDate(dateTime) {
   try {
      // return moment(dateTime).format('ll');
      // const monthNames = ["January", "February", "March", "April", "May", "June",
      //    "July", "August", "September", "October", "November", "December"
      // ];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      const date = new Date(dateTime);
      return monthNames[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
   } catch (error) {
      console.log(error)
      return 'NA'
   }

}

export function formatRoutes(arr) {
   let str = "";
   for (let i = 0; i < arr.length; i++) {
      const e = arr[i];

      if (i === 0 || i === arr.length - 1) {
         continue;
      } else {
         if (str)
            str = str + " | " + e.place
         else
            str = e.place
      }

   }
   return str;
}

export function makeTxtOverFLow(str, len) {

   if (str?.length <= len)
      return str;

   return str?.slice(0, len) + "....";
}

export function shipmtStClrs(status, len) {
   const statusArr = [
      {
         status: "pending",
         label: "Pending",
         txtColor: "rgba(227, 156, 2, 1)",
         bgColor: "rgba(227, 156, 2, 0.1)"
      }, {
         status: "cancel",
         label: "Cancel",
         txtColor: "rgba(255, 60, 0, 1)",
         bgColor: "rgba(255, 60, 0, 0.1)"
      }, {
         status: "confirm",
         label: "Confirm",
         txtColor: "rgba(0, 102, 255, 1)",
         bgColor: "rgba(0, 102, 255, 0.1)"
      }, {
         status: "transportation",
         label: "On Transit",
         txtColor: "rgba(162, 0, 255, 1)",
         bgColor: "rgba(162, 0, 255, 0.1)"
      },
      {
         status: "awaitingUnloading",
         label: `Awaiting Unloading`,
         txtColor: "rgba(0, 175, 184, 1)",
         bgColor: "rgba(0, 175, 184, 0.1)"
      },
      {
         status: "unloaded",
         label: "Unloaded",
         txtColor: "rgba(150, 184, 0, 1)",
         bgColor: "rgba(150, 184, 0, 0.1)"
      }, {
         status: "completed",
         label: "Completed",
         txtColor: "rgba(2, 158, 25, 1)",
         bgColor: "rgba(2, 158, 25, 0.1)"
      }]

   let res = statusArr.filter((i) => i.status === status)

   return res[0];
}

export function formatDateWithJs(dateTime) {
   if (!dateTime)
      return 'NA'

   //18/9/2022
   var date = new Date(dateTime);
   let month = date.getMonth() + 1;

   return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + '-'
      + (month < 10 ? "0" + month : month) + "-"
      + date.getFullYear()
}

export function datePickerformat(dateTime) {
   if (!dateTime)
      return null

   //18/9/2022  yyyy-MM-dd
   var date = new Date(dateTime);
   let month = date.getMonth() + 1;

   return date.getFullYear() + "-" +
      (month < 10 ? "0" + month : month) + "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
}

export function getFormatedUnit(unit) {
   if (unit === "feet")
      return " Feet"
   else if (unit === "m")
      return " meter"
   else if (unit === "cm")
      return " cm"
   else if (unit === "ton")
      return " Ton(s)"
   else if (unit === "kilolitre")
      return " KL"
   else if (unit === "full")
      return "Full Payment"
   else if (unit === "advance")
      return "Advance"
   else if (unit === "topay")
      return "To Pay"
   else if (unit === "partLoad")
      return "Part Load"
   else if (unit === "fullLoad")
      return "Full Load"
}

export function getTruckName(obj) {
   // obj = JSON.parse(obj);
   let strg = "";
   for (var key in obj) {
      if (key.startsWith("c_") || key.startsWith("b_"))
         continue;
      strg += obj[key] + ",  "
   }
   return strg;
}

export function formatSess(sess) {
   if (sess === "morning")
      return "6am-10am"
   else if (sess === "noon")
      return "10am-2pm"
   else if (sess === "evening")
      return "2pm-6pm"
   else if (sess === "night")
      return "after 6pm"
   else
      return ""
}

export function getLocalStrg(value) {
   try {
      value = parseFloat(value)
      return value.toLocaleString('en-IN')
   } catch (error) {
      return ""
   }

}
export function getBalance({ val1, val2 }) {
   try {
      val1 = parseFloat(val1)
      val2 = parseFloat(val2)
      return (val1 - val2).toLocaleString('en-IN')
   } catch (error) {
      return ""
   }

}

export function formatReptConfigJson(jsonArr) {
   let jsArr = JSON.parse(jsonArr);

   let strg = ""
   for (let i = 0; i < jsArr.length; i++) {
      const e = jsArr[i];

      if (i === 0)
         strg = e.label
      else
         strg = strg + ", " + e.label
   }
   return strg
}

export function genFileName({ strg, ext }) {

   var date = new Date();
   let month = date.getMonth() + 1;

   return strg + "_" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + '_'
      + (month < 10 ? "0" + month : month) + "_"
      + date.getFullYear() + ext
}

export function formatPymtType(type) {
   switch (type) {
      case "cheque": return "Cheque"
      case "cash": return "Cash";
      case "upi": return "UPI";
      case "neft": return "NEFT";
      case "portal": return "Portal";
      case "advance": return "Advance";
      case "balance": return "Balance";
      default: return ""
   }
}

// ENUM('pending', 'cancel', 'confirm', 'transportation', 'unloaded', 'completed')

export function formatShipmtStatus(status) {
   switch (status) {
      case "pending": return "Pending"
      case "cancel": return "Cancel";
      case "confirm": return "Confirm";
      case "transportation": return "On Transit";
      case "awaitingUnloading": return "Awaiting Unloading";
      case "unloaded": return "Unloaded";
      case "completed": return "Completed";
      default: return ""
   }
}

export function formatWeight({ val, unit }) {
   try {
      if (unit == "ton") {
         val = parseFloat(val)
         if (val < 1) {
            return `${val * 1000} Kg`
         } else {
            return `${val} Ton(s)`
         }
      } else if (unit == "kilolitre") {
         return `${val} KL`
      }
      return ""
   } catch (error) {
      return ""
   }
}

export function reqInfoVfyStyle(status) {
   switch (status) {
      case "pending": return { fontWeight: "600", fontSize: 16, color: "rgba(232, 124, 23, 1)", ml: 1 };
      case "approved": return { fontWeight: "600", fontSize: 16, color: "rgba(40, 201, 4, 1)", ml: 1 };
      case "rejected": return { fontWeight: "600", fontSize: 16, color: "rgba(230, 54, 18, 1)", ml: 1 };
      default: return {}
   }
}

export function formatReqVfyType(type) {
   switch (type) {
      case "pending": return "Pending"
      case "approved": return "Approved";
      case "rejected": return "Rejected";;
      default: return ""
   }
}

export function getReqVfyIcon({ type, fontSize }) {
   console.log("13-------------------------");
   console.log(type);
   switch (type) {
      case "pending": return <Error color='warning' sx={{ fontSize: fontSize }} />;
      case "approved": return <Verified color='success' sx={{ fontSize: fontSize }} />;
      case "rejected": return <Cancel color='error' sx={{ fontSize: fontSize }} />
      default: return <></>
   }
}

export function getVerifyIcon({ type, fontSize }) {
   switch (type) {
      case "pending": return <Error color='warning' sx={{ fontSize: fontSize }} />;
      case "verified": return <Verified color='success' sx={{ fontSize: fontSize }} />;
      case "rejected": return <Cancel color='error' sx={{ fontSize: fontSize }} />
      default: return <></>
   }
}

export function getVerify(type) {
   switch (type) {
      case "pending": return <Stack direction={"row"} alignItems={"center"} gap={0.5} color={colors.orange[700]}>
         <Error />
         <Typography>Pending</Typography>
      </Stack>;
      case "verified": return <Stack direction={"row"} alignItems={"center"} gap={0.5} color={colors.green[700]}>
         <Verified />
         <Typography>Verified</Typography>
      </Stack>;
      case "rejected": return <Stack direction={"row"} alignItems={"center"} gap={0.5} color={colors.red[700]}>
         <Cancel />
         <Typography>Rejected</Typography>
      </Stack>;
      default: return <></>
   }
}

export function getDatePikCurrDate() {
   var date = new Date();
   let month = date.getMonth() + 1;
   return `${date.getFullYear()}-${month < 10 ? "0" + month : month}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`
}

export function getLast30Days() {
   var tDate = new Date();
   let tMonth = tDate.getMonth() + 1;
   let today = `${tDate.getFullYear()}-${tMonth < 10 ? "0" + tMonth : tMonth}-${tDate.getDate() < 10 ? "0" + tDate.getDate() : tDate.getDate()}`

   var pDate = new Date(new Date().setDate(tDate.getDate() - 30))
   let pMonth = pDate.getMonth() + 1;
   let priorDate = `${pDate.getFullYear()}-${pMonth < 10 ? "0" + pMonth : pMonth}-${pDate.getDate() < 10 ? "0" + pDate.getDate() : pDate.getDate()}`

   return { today, priorDate }
}
import React from 'react'
import logo from "../../assets/svg/logo.svg";
import { Stack, Button, Typography, Box } from "@mui/material"
import { useState } from 'react';
import PwdTxtField from '../../components/PwdTxtField';
import LabelTxtField from '../../components/LabelTxtField';
import { customAlert, notify } from '../../components/notify';
import { forgotPwdService } from '../../services/login-service';
import { useAuthContext } from '../../context/AuthContext'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import route from '../../Routes';
import AddClick from '../../components/AddClick';


function ForgotPwdPage() {
    const [formErrors, setFormErrors] = useState({});
    const { setLoadingScreen } = useAuthContext();

    const { ctxtlogin, ctxtUser } = useAuthContext();
    const navigate = useNavigate();



    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);

        const inputObject = Object.fromEntries(formData); // convert the FormData object to a JSON object

        let errorsObj = validate(inputObject);
        setFormErrors(errorsObj);

        if (Object.keys(errorsObj).length > 0)
            return;

        setLoadingScreen(true);

        try {
            await forgotPwdService({ username: inputObject.email });

            notify("success", "OTP sent successfully");

            navigate(route.verifyOTP + `?email=${inputObject.email}`, { replace: true });
            setLoadingScreen(false);
        } catch (err) {
            console.log(err);
            if (err === 404)
                notify("error", "Invalid Email Address");
            else if (err === 409)
                notify("error", "Staff disabled Contact backoffice to reactivate");
            else if (err === 410)
                notify("error", "Backoffice disabled Contact admin to reactivate");
            else
                customAlert(err);

        }
        setLoadingScreen(false);
    };


    useEffect(() => {
        setLoadingScreen(true);
        console.log(ctxtUser?.token);
        if (ctxtUser?.token && ctxtUser?.type === 'sa')
            navigate(route.saBoLists, { replace: true });
        else if (ctxtUser?.token && ctxtUser?.type === 'staff')
            navigate(route.boDashboard, { replace: true });
        else if (ctxtUser?.token && ctxtUser?.type === 'agent') {
            navigate(route.agDashboard, { replace: true });
        }

        setLoadingScreen(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <form onSubmit={handleSubmit} noValidate style={{ width: "100%", height: "100%", position: "absolute" }}>
            <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100%" }}>

                <img src={logo} alt="logo" className='logo' />
                <Stack alignItems={"center"} width={360}>
                    <Typography sx={{ mb: 2, fontWeight: "700", fontSize: 26 }}>Forgot Password</Typography>
                    <Typography className='subTxt' sx={{ mb: 2, fontWeight: "500", fontSize: 14 }}>Enter the email address associated with your account and we'll send you an OTP to reset your password.</Typography>
                    <LabelTxtField type='email' name='email' placeholder="Enter your Registered Email Address" title="Email Address" errMsg={formErrors.email} />

                    <Button variant="contained" type='submit' style={{ marginTop: "1rem", height: "2.5rem" }} sx={{ width: "85%" }}>Send</Button>

                    <Button variant="text" onClick={() => navigate(-1)} style={{ marginTop: "1rem", height: "2.5rem" }} sx={{ width: "85%" }}>Back</Button>
                </Stack>
            </Stack>
        </form>
    )
}

function validate(values) {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // if (!values.username) {
    //     errors.username = "Username is required!";
    // }
    if (!values.email) {
        errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
        // errors.email = "This is not a valid email format!";
    }
    return errors;
};

export default ForgotPwdPage;
import { styled } from '@mui/material/styles';
import { ButtonBase, Pagination, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import BgBox from "../../../components/BgBox";
import { customAlert } from "../../../components/notify";
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import QueryKey from '../../../QueryKey';
import { useQuery } from 'react-query';
import LoadingScreen from '../../../components/loadingScreen';
import { getAllPenTrks } from '../../../services/trkOp-service';
import SinglePenTrkTable from '../../../components/SinglePenTrkTable';
import { useNavigate } from 'react-router-dom';
import route from '../../../Routes';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function PentrkListPage() {
  const { t } = useTranslation();
  const vfyStatus = "pending"
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(1);


  const [order, setOrder] = useState('desc');

  const limit = 8

  const { isLoading, isError, error, data: trkRows, refetch } = useQuery(
    [QueryKey.allPenTrks, limit, pageNumber, order, vfyStatus],
    () => getAllPenTrks({ limit, pageNumber, order, vfyStatus }), {
    keepPreviousData: true
  });

  let title = t("bo.custInfoPg.pentrks");

  if (vfyStatus) {
    if (vfyStatus == "pending") {
      title = t("bo.custInfoPg.pentrks");
    } else if (vfyStatus == "rejected") {
      title = t("bo.custInfoPg.rjctrks");
    }
  }



  async function onOrderChange(e, or) {
    if (or == null)
      return

    setOrder(or);
    setPageNumber(1);
  }


  const handlePageChange = async (event, value) => {
    setPageNumber(value);
  };



  function rjcTrkClick() {
    navigate(route.boRjcTrks)
  }


  if (isError) {
    customAlert(error);
    return <h2>Something went wrong</h2>
  }

  if (isLoading)
    return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

  // if (time !== "current" && time !== "history") {
  //   return <MissingPage />
  // }

  return (
    <Box height={"100%"} width={"100%"}>
      <Stack direction={'row'} justifyContent='space-between' mb={1.5} >
        <Stack direction={'row'} alignItems={"center"} gap={0.5} mr={4}>
          <Typography variant='h5' sx={{ fontWeight: "bold" }}>{title} </Typography>
          <StyledToggleButtonGroup color="primary" size="small" value={order} exclusive onChange={onOrderChange} sx={{ mb: 0.5, height: 36, mx: 3 }}>
            <ToggleButton value='desc'>
              <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDown /></Stack>
            </ToggleButton>
            <ToggleButton value="asc">
              <Stack sx={{ fontSize: 18, justifyContent: "center", alignItems: "center" }}>  <FaSortAmountDownAlt /></Stack>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Stack>
        <Stack mr={5}>
          <Stack direction={'row'} mx={1} gap={2}>
            <ButtonBase component="div">  <Typography sx={{ fontWeight: "600", color: "primary.main" }}>{t("bo.custInfoPg.pen")}</Typography></ButtonBase>
            <ButtonBase component="div" onClick={rjcTrkClick}> <Typography sx={{ fontWeight: "600", }}>{t("bo.custInfoPg.rjc")}</Typography></ButtonBase>
          </Stack>
          <Box sx={{ width: 160, backgroundColor: "#E3E3E3", borderRadius: 10, height: 5, mt: 0.5 }}>
            <Stack direction={'row'} height={4}>
              <Box sx={{ width: 80, backgroundColor: "primary.main", borderRadius: 10 }} />
              <Box sx={{ width: 80, borderRadius: 10 }} />
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <BgBox height={"93%"} px={4} py={1}>
        <Stack direction={'row'} justifyContent="space-between" alignItems={"center"} mb={1.2} mt={0.4} px={2}>


          <Stack direction={'row'} justifyContent="space-between" alignItems={"center"}>

          </Stack>
        </Stack>
        <SinglePenTrkTable trkRows={trkRows?.data} vfyStatusType={vfyStatus} refetch={refetch} />

        <Stack sx={{ alignItems: "center", mt: 1 }}>
          <Pagination count={Math.ceil(trkRows?.totalLength / limit) || 0} color="primary" page={pageNumber} onChange={handlePageChange} variant="outlined" shape="rounded" />
        </Stack>

      </BgBox>
    </Box>
  )
}

export default PentrkListPage;
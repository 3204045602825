import { Edit, MoreVert } from '@mui/icons-material'
import { Typography, Box, Tabs, Tab } from '@mui/material'
import { Stack } from '@mui/system'
import { t } from 'i18next';
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BgBox from '../../../components/BgBox';
import { customAlert } from '../../../components/notify';
import { useAuthContext } from '../../../context/AuthContext';
import route from '../../../Routes';
import { getAllBoStaffsByStatus, putBoStaffStatus } from '../../../services/bo-service';
import { useTranslation } from "react-i18next";
import LoadingScreen from '../../../components/loadingScreen';
import { useQuery } from 'react-query';
import QueryKey from '../../../QueryKey';
import UserTable from './UserTable';

function UserListPage() {
    const [listStatus, setListStatus] = useState('1')
    const { setAsyncLoading } = useAuthContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    // const isLarge = useMediaQuery("(min-width: 600px)");
    const [currMenuStId, setCurrMenuStId] = useState(null);
    const { t } = useTranslation();
    const { isLoading, isError, error, data: staffRows, refetch } = useQuery(
        [QueryKey.staffList, listStatus], () => getAllBoStaffsByStatus(listStatus)
    )


    const optionItems = [
        {
            title: t("sa.userLstPg.edit"),
            icon: <Edit fontSize="small" />,
            fn: handleEdit
        }
    ]


    const handleOptionClick = (event, stId) => {
        setAnchorEl(event.currentTarget);
        setCurrMenuStId(stId);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleEdit() {
        const selectedStaff = staffRows.filter((e) => e.staffId === currMenuStId);
        handleClose();
        navigate(route.editUser + selectedStaff[0].staffId, { state: { staffData: selectedStaff[0] } });
    }


    async function handleRowClick(rowStId) {
        const selectedStaff = staffRows.filter((e) => e.staffId === rowStId);
        handleClose();
        navigate(route.userInfo + selectedStaff[0].staffId);
    }


    async function updateStaffStatus(stRows) {
        setAsyncLoading(true);
        try {
            let status = stRows.status === 1 ? 0 : 1;
            await putBoStaffStatus(stRows.staffId, status)
            refetch();
        } catch (err) {
            console.log(err);
            customAlert(err);
        }
        setAsyncLoading(false)
    }

    async function handleBoRowClick(boId) {
        navigate(route.boInfo + boId);
    }

    async function handleTabChange(event, newValue) {
        setListStatus(newValue);
        refetch();
    }

    if (isError) {
        customAlert(error);
        return <h2>Something went wrong</h2>
    }

    if (isLoading)
        return <Box sx={{ position: "absolute", top: 0, left: 0 }}><LoadingScreen /></Box>

    return (
        <BgBox>
            <Stack direction={'row'} justifyContent='space-between' mx={2} mb={2} alignItems={"center"}>
                <Typography variant='h5' sx={{ fontWeight: "bold" }}>{t("sa.userLstPg.boUser")}</Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={listStatus} onChange={handleTabChange} aria-label="lab API tabs example" >
                        <Tab label={<span style={{ fontWeight: '700', fontSize: 16 }}>{t("sa.boLstPg.active")}</span>} value="1" />
                        <Tab label={<span style={{ fontWeight: '700', fontSize: 16 }}>{t("sa.boLstPg.inactive")}</span>} value="0" />
                    </Tabs>
                </Box>
            </Stack>


            <UserTable staffRows={staffRows}
                updateStaffStatus={updateStaffStatus}
                handleOptionClick={handleOptionClick}
                handleRowClick={handleRowClick}
                handleBoRowClick={handleBoRowClick}
                open={open} handleClose={handleClose}
                anchorEl={anchorEl} optionItems={optionItems} />
        </BgBox>
    )
}

export default UserListPage;